
// make private key
import {toast} from "react-toastify";

class CommonToast {
    constructor() {
        this.toastBody = (type = "success", message = "message") => {
            return toast[type](message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    // success toast
    static success(message = "type a message") {
        return new CommonToast().toastBody("success", message)
    }

    // error toast
    static error(message = "type a message") {
        return new CommonToast().toastBody("error", message)
    }

    // warning toast
    static warning(message = "type a message") {
        return new CommonToast().toastBody("warning", message)
    }
    

}

export default CommonToast