import React from 'react';
import OurStudentSection from "../Component/OurStudentSection/OurStudentSection";

function OurStudentPage(props) {
    return (
        <>
           <OurStudentSection/>
        </>
    );
}

export default OurStudentPage;
