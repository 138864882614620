
import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {addNotice, getAllNotice, noticeDelete, updateNotice} from "../../AllRoutes/ApiRoute";
import CommonToast from "../../Utilities/CommonToast";
import {NoticeSlice} from "../Slice/noticeSlice";

// get Notice Action
export const getAllNoticeAction = payload => dispatch =>{
    // getting data from payload
    const {allNoticeLoaderRef} = payload
    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allNoticeLoaderRef)
        } catch (e) {
        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.get(getAllNotice).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(NoticeSlice.actions.getAllNoticeReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })
}




// Add Notice Action
export const addNoticeAction = payload => dispatch =>{
    const {
        btnRef,
        setNoticeAddModelShow,
        setAllNoticeReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addNotice, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setNoticeAddModelShow(false)
            //all Teacher table reload
            setAllNoticeReload(prev => !prev)
            // toast
            CommonToast.success("Notice Create Successfully!")
        } else {
            CommonToast.error("Notice Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// Update Notice Action
export const UpdateNoticeAction = payload => dispatch =>{
    const {
        btnRef,
        setNoticeEditModelShow,
        setAllNoticeReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateNotice, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setNoticeEditModelShow(false)
            //all Agent table reload
            setAllNoticeReload(prev => !prev)
            // toast
            CommonToast.success("Notice updated Successfully!")
        } else {
            CommonToast.warning("Notice updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Delete Notice Action
export const DeleteNoticeAction = payload => dispatch =>{
    const {
        btnRef,
        setNoticeDeleteModelShow,
        setAllNoticeReload
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(noticeDelete, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setNoticeDeleteModelShow(false)
            //all Agent table reload
            setAllNoticeReload(prev => !prev)
            // toast
            CommonToast.success("Notice Delete Successfully!")
        } else {
            CommonToast.warning("Notice Delete failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}
