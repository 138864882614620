import React from 'react';
import Auth from "../../Utilities/Auth";
import {Link, useNavigate} from "react-router-dom";
import {FaUserCircle} from "@react-icons/all-files/fa/FaUserCircle";
import {Button} from "react-bootstrap";

function TopNav(props) {
    // hooks
    const navigate = useNavigate()
    // logout on click
    const logoutOnClick = () => {
        Auth.clear()
    }
    return (
        <>
            <nav className="sb-topnav navbar navbar-expand navbar-dark">


                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>
                {/* Navbar*/}
                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button"
                           data-bs-toggle="dropdown" aria-expanded="false"><FaUserCircle className="userIcon"/></a>

                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li className="text-center overflow-hidden text-muted text-center">{JSON.parse(Auth.header().userdetail || "{}").full_name}</li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li><Link to={"/setting"} className="dropdown-item" href="#!">Settings</Link></li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            {/*logout button*/}
                            <li className="px-2"><Button
                                className="dropdown-item bg-danger text-white fw-bold text-center" onClick={e => {
                                logoutOnClick()
                            }} style={{cursor: "pointer"}}>Logout</Button></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default TopNav;