import {createSlice} from "@reduxjs/toolkit";

export const StudentSlice = createSlice({
    name:"studentSlice",
    initialState:{
        classWiseStudents:[]
    },
    reducers:{
        getClassWiseStudentsReducers: (state, action) => {
            state.classWiseStudents = action.payload
        }
    }
})