import React, {useContext, useEffect} from 'react';
import {Form} from "react-bootstrap";
import styles from '../CommonFrontendPaginationTable.module.css'
import {HiOutlineSearchCircle} from "react-icons/hi";
import {CommonTableContext} from "../CommonFrontendPaginationTableComponent";

function CommonSearchField(props) {
    // getting data from context
    const {table_name, setOffset, setCurrentPage, loadingTable, setForceToFetchData, searchConfig} = useContext(CommonTableContext)
    // extract data from search configuration
    const {setSearchKey, searchKey, propertyForMatch, preFilterData, setFilteredDataState} = searchConfig

    // set auto focus the search field when field disability off
    useEffect(() => {
        if (loadingTable == false) {
            document.getElementById(`searchField${table_name}`).focus()
        }
    }, [loadingTable])


    useEffect(() => {
        searchOnSubmit()
    }, [searchKey, preFilterData])

    // search key on change
    const searchKeyOnChange = (e) => {
        let typedData = e.target.value || ""
        setSearchKey(typedData)

    }

    // search on submit
    const searchOnSubmit = (e) => {
        e?.preventDefault()
        try {
            // filter data
            let searchedData = []
            searchKey.length != 0 && propertyForMatch.length != 0 && preFilterData.length != 0 && preFilterData.filter(singleData => {
                for (let prop of propertyForMatch) {
                    let stringPropToArr = prop.split(".")
                    if (stringPropToArr.length == 1) {
                        if ((singleData[stringPropToArr[0]] || "").toString().toLowerCase().includes(searchKey.toLowerCase())) {
                            return singleData
                        }
                    } else if (stringPropToArr.length == 2) {
                        if (singleData[stringPropToArr[0]] != null ? singleData[stringPropToArr[0]][stringPropToArr[1]].toString().toLowerCase().includes(searchKey.toLowerCase()) : true == false) {
                            return singleData
                        }
                    } else if (stringPropToArr.length == 3) {
                        if (singleData[stringPropToArr[0]] != null ? singleData[stringPropToArr[0]][stringPropToArr[1]][stringPropToArr[2]].toString().toLowerCase().includes(searchKey.toLowerCase()) : true == false) {
                            return singleData
                        }
                    }

                }

            }).filter(singleData => {
                searchedData.push(singleData)
            })

            setFilteredDataState(searchKey.length != 0 ? searchedData : preFilterData)
            // set offset 0 when search form submit. Cz, we get data from 0 depends on search key
            setOffset(0)
        } catch (e) {

        }
        // set current page 0 (means 1) on pagination.
        setCurrentPage(0)
        // re render the data fetch lifecycle
        setForceToFetchData(prev => !prev)
    }
    return (
        <>
            <Form onSubmit={searchOnSubmit}>
                <Form.Label className="mb-0">{searchConfig.title}</Form.Label>
                <Form.Group className={`mb-2 col-4 ${styles.singleCommonSearchFieldContainer} d-flex`}
                            controlId="formBasicEmail">
                    <Form.Control
                        id={`searchField${table_name}`}
                        disabled={loadingTable}
                        autocomplete="off"
                        type="text"
                        onChange={searchKeyOnChange}
                        placeholder={searchConfig.placeholder}
                    />
                    <button disabled={loadingTable} type={"submit"} className="px-2">
                        <HiOutlineSearchCircle style={{fontSize: "25px"}} className="text-white"/>
                    </button>
                </Form.Group>
            </Form>
        </>
    );
}

export default CommonSearchField;