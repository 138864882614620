import React, {useContext} from 'react';
import {MarksheetContext} from "../../MarkSheetComponent/MarkSheetComponent";
import Table from 'react-bootstrap/Table';
import GradeIdentifier from "../../../Utilities/GradeIdentifier";
import {Button} from "react-bootstrap";
import Parse from "../../../Utilities/parse";

function PopUpMarkSheetComponent(props) {
    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId,
        classWiseSteteReloder,
        setClassWiseSteteReloder,
        markSheetViewModel,
        setMarkSheetViewModel,
        singleStudentMarkSheet,
        setSingleStudentMarkSheet
    } = useContext(MarksheetContext)

    let totalMarks = 0
    let fullMarks = 0
    let gradePoint = Parse.toNumber(0)
    return (

        <>
            <Table striped bordered hover>
                <thead>
                <tr className="bg-blue">
                    <th>#</th>
                    <th>Subject Name</th>
                    <th className="text-end">Full Marks</th>
                    <th className="text-end">Written</th>
                    <th className="text-end">Viva</th>
                    <th className="text-end">Total Marks</th>
                    <th className="text-end">Grade</th>
                </tr>
                </thead>
                <tbody>
                {singleStudentMarkSheet.mark_sheets_for_print.map((subject, index)=>(

                    <tr>
                        <td>{index + 1}</td>
                        <td>{subject.subject_name}</td>
                        <td className="text-end">{subject.exam_detail.exam_full_mark}</td>
                        <td className="text-end">{subject.exam_detail.written_marks}</td>
                        <td className="text-end">{subject.exam_detail.viva_marks}</td>
                        <td className="text-end">{subject.exam_detail.total_marks}</td>
                        <td className="text-end">
                            {GradeIdentifier.getGrade(subject.exam_detail.grade_point)}
                        </td>
                    </tr>
                ))}

                {singleStudentMarkSheet.mark_sheets_for_print.map((subject, index)=>{
                    {fullMarks+= subject.exam_detail.exam_full_mark}
                    {totalMarks+= subject.exam_detail.total_marks}
                    {gradePoint+= (Parse.toNumber(subject.exam_detail.grade_point)) / singleStudentMarkSheet.mark_sheets_for_print.length }

                })
                }
                    <tr>
                        <td colSpan={3} className="text-end">{fullMarks}</td>
                        <td colSpan={3} className="text-end">{totalMarks}</td>
                        <td className="text-end">{GradeIdentifier.getGrade(gradePoint.toFixed(2))}</td>
                    </tr>

                <tr>

                </tr>
                </tbody>
            </Table>

        </>
    );
}

export default PopUpMarkSheetComponent;