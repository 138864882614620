import React, {Suspense} from 'react';

const ServicesChargeComponent = React.lazy(() => import("../Component/UtilitiesComponent/UtilitiesSubComponent/ServicesChargeComponent"))

function ServiceChargePage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ServicesChargeComponent/>
            </Suspense>
        </>
    );
}

export default ServiceChargePage;