import React, {useContext, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {getClassWiseStudentsWithMarkSheetAction} from "../../../Redux/Action/MarksheetAction";
import {MarksheetContext} from "../MarkSheetComponent";

function ExamAndSubjectWiseFilter(props) {
    //Hook
    const btnRef = useRef()
    //Context
    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examAndSubjectWiseStudent,
        setExamAndSubjectWiseStudent,
        studentSubjectWiseMarks,
        setStudentSubjectWiseMarks,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId
    } = useContext(MarksheetContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();


    // form submit
    const onSubmit = async data => {

        // modifying data
        let filterData = {
            ...data,
            sessional_exam_id: JSON.parse(data.sessional_exam_id || "{}").id,
            sessional_book_id: JSON.parse(data.sessional_book_id || "{}").id
        }
        setExamAndSubjectId(filterData)

        // filtering subject form student
        let filteredSubjectWithStudentArr = []


        // get single student from all class wise student map
        classWiseStudent.map(singleStudent => {
            // get a single book from all single student's sesional books
            singleStudent?.stu_h_student_sessional_books?.map(singleBook => {
                if (filterData.sessional_book_id == singleBook.subject_id) {
                    let updated_student_obj = {
                        ...singleStudent,
                        book_detail: {
                            ...singleBook,
                            this_exam_result: {},
                        }
                    }
                    // get single book's maks from all sessional exams
                    singleBook.stu_i_student_sessional_marksheets.map(singleMarksheet => {
                        if (singleMarksheet.sessional_exam_id == filterData.sessional_exam_id) {
                            updated_student_obj = {
                                ...singleStudent,
                                book_detail: {
                                    ...singleBook,
                                    this_exam_result: singleMarksheet,
                                },
                                trigger_time: new Date().getTime()
                            }
                        }
                    })
                    filteredSubjectWithStudentArr.push(updated_student_obj)

                }
            })

        })
        setExamMarksTable([])
        setExamMarksTable(filteredSubjectWithStudentArr)



    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Select
                                {...register("sessional_exam_id", {
                                    required: true,

                                })}
                            >
                                <option value="">Select Exam</option>
                                {examListForMarksheet.map((singleExam, key) => (
                                    <option value={JSON.stringify(singleExam)}>{singleExam.exam_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Select
                                {...register("sessional_book_id", {
                                    required: true,

                                })}
                            >
                                <option value="">Select Subject</option>
                                {subjectListForMarksheet.map((singleSubject, key) => (
                                    <option value={JSON.stringify(singleSubject)}>{singleSubject.subject_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Button ref={btnRef}
                                type="submit"
                                className="submit btn-sm">
                            Submit
                            <ButtonLoader/>
                        </Button>
                    </Col>
                </Row>

            </form>
        </>
    );
}

export default ExamAndSubjectWiseFilter;