import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {NoticeContext} from "../NoticeComponent";
import {addNoticeAction, UpdateNoticeAction} from "../../../Redux/Action/NoticeAction";
import {useDispatch} from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function NoticeForm({default_data = {}}) {
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        allNoticeReload,
        setAllNoticeReload,
        noticeAddModelShow,
        setNoticeAddModelShow,
        noticeEditModelShow,
        setNoticeEditModelShow,
        noticeSingleData,
        setNoticeSingleData,
        noticeDeleteModelShow,
        setNoticeDeleteModelShow
    } = useContext(NoticeContext)

    // hook form
    const {register, handleSubmit, getValues, reset, setValue,clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, default_data[fieldProp])
            })
        }
    }, [default_data])


    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                notice_id: default_data.id
            },
            reset,
            btnRef,
            setNoticeAddModelShow,
            setNoticeEditModelShow,
            setAllNoticeReload

        }
        Object.keys(default_data).length == 0 ? dispatch(addNoticeAction(payload)) : dispatch(UpdateNoticeAction(payload))


    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>

                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Notice Title</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Notice Title"
                                {...register("title", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.title && <span className="text-danger">
                        {errors.title.type === "required" && "Please Give Notice Title"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Short Description</strong></Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Short Description"
                                {...register("short_description", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.short_description && <span className="text-danger">
                        {errors.short_description.type === "required" && "Please Give Short Description."}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Notice Content</strong></Form.Label>

                            <ReactQuill
                                theme="snow"
                                defaultValue={default_data.notice_content ||""}
                                style={{height:"250px"}}
                                className="clearfix"
                                {...register("notice_content", {
                                    required: true
                                })}
                                onChange={(content, delta, source, editor)=>{
                                    // handle hook form
                                    editor.getText().length == 1 ? setError("notice_content"):clearErrors("notice_content")
                                    setValue("notice_content",editor.getText().length > 1 ?content:"")

                                }}
                            />

                            {/* validation message*/}
                            {errors.notice_content && <span className="text-danger">
                        {errors.notice_content.type === "required" && "Please Give Notice Content"}
                    </span>}
                        </Form.Group>

                    </Col>
<div style={{height:"50px"}}></div>
                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default NoticeForm;