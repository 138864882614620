import CommonDatePicker from "../../Component/CommonComponent/DatePickerComponent/CommonDatePicker";
import CommonAxios from "../../Utilities/CommonAxios";
import {getAllStudentType} from "../../AllRoutes/ApiRoute";
import {ConfigurationSlice} from "../Slice/ConfigurationSlice";

// get all student type action
export const getAllStudentTypeAction = payload => dispatch => {
    // db operation
    CommonAxios.get(getAllStudentType).then(res => {
        if (res.data.status == true) {
            dispatch(ConfigurationSlice.actions.getAllStudentTypeReducers(res.data.data))
        }
    }).catch(err => {

    })
}