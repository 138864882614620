import React, {useContext, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {UpdateEventImageAction} from "../../../Redux/Action/EventAction";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {updateBirthCertificateAction} from "../../../Redux/Action/StudentAction";

function BirthCertificateImageChange({infoData = {}}) {
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()
    // Context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        birthCertificateEditModel,
        setBirthCertificateEditModel,
        aadhaarCardEditModel,
        SetAadhaarCardEditModel
    } = useContext(StudentSingleDateContext)

    // console.log(infoData)
    // hook form
    const {register, handleSubmit, getValues, reset, setValue,clearErrors, setError, formState: {errors}} = useForm();


    //Submit
    const onSubmit = async data => {
        const formData = new FormData();
        formData.append("birth_certificate_image", data.image?.length !=0?data.image[0]:"");
        formData.append("sessional_info_id", singleStudentDetail.id);

        const payload = {
            data: formData,
            reset,
            btnRef,
            setBirthCertificateEditModel,
            setReloadStudentDetail
        }

         dispatch(updateBirthCertificateAction(payload))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image"
                                {...register("image", {
                                    required: false
                                })}

                            />

                        </Form.Group>
                    </Col>
                </Row>
                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default BirthCertificateImageChange;