import React, {useContext, useEffect, useRef, useState} from 'react';
import {Form, Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {getValue} from "@testing-library/user-event/dist/utils";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch} from "react-redux";
import {ClassContext} from "../ClassComponent";
import {configureDepartmentAction, deleteDepartmentAction} from "../../../Redux/Action/ClassAction";
import OnScreenDeletePermission from "../../CommonComponent/OnScreenDeletePermission/OnScreenDeletePermission";

function DepartmentConfigureForm({exist_data = {}}) {
    // hooks
    const dispatch = useDispatch()
    const submitRef = useRef()
    const deleteButtonRef = useRef()
    // hook form props
    const {register, handleSubmit, getValues, setValue, reset, formState: {errors}} = useForm();
    // getting data from context
    const {setAllClassReload, singleClassDetail} = useContext(ClassContext)
    // states
    const [deleteAlertShow, setDeleteAlertShow] = useState(false)

    // set default data
    useEffect(() => {
        Object.keys(exist_data).length != 0 && Object.keys(getValues()).map(fieldProp => {
            setValue(fieldProp, exist_data[fieldProp])
        })
    }, [exist_data])

    // close del=ete alert lifecycle
    useEffect(() => {
        setDeleteAlertShow(false)
    }, [exist_data])

    // delete confirm on click
    const deleteConfirmOnClick = () => {
        // making payload
        const payload = {
            data: {
                department_id: exist_data.id,
            },
            deleteButtonRef,
            setAllClassReload,
            setDeleteAlertShow
        }
        // action dispatch
        dispatch(deleteDepartmentAction(payload))
    }

    // on submit
    const onSubmit = data => {
        // making payload for action
        const payload = {
            data: {
                ...data,
                class_id: singleClassDetail.id,
            },
            action_type: "add",
            submitRef,
            reset,
            setAllClassReload
        }

        // set property for update
        if (Object.keys(exist_data).length != 0) {
            payload.action_type = "update"
            payload.data = {
                id: exist_data.id,
                ...payload.data
            }
        }

        // formatting payload data as required as backend
        payload.data = {departments: [payload.data]}

        // action dispatch
        dispatch(configureDepartmentAction(payload))
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                {/*Department name field*/}
                <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Department name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter department name"
                        {...register("department_name", {required: true})}
                    />
                    {/*validation message*/}
                    {errors.department_name && <span className="text-danger">
                        {errors.department_name?.type == "required" && "This field is required."}
                    </span>}
                </Form.Group>

                {/*Department code field*/}
                <Form.Group className="mb-2" controlId="formBasicEmail">
                    <Form.Label>Department code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter department code"
                        {...register("department_code", {required: false})}
                    />
                </Form.Group>

                <Button variant="" ref={submitRef} disabled={false}
                        className="mt-2 btn-sm btn-primary" type="submit">
                    {Object.keys(exist_data)?.length == 0 ? "Add" : "Update"}
                    <ButtonLoader/>
                </Button>
                {Object.keys(exist_data)?.length != 0 &&
                <Button variant="danger" onClick={e => setDeleteAlertShow(true)} className="mt-2 ms-2 btn-sm">
                    Delete
                </Button>}
                <br/>
                {/*delete on screen alert*/}
                <OnScreenDeletePermission
                    deleteButtonRef={deleteButtonRef}
                    deleteAlertShow={deleteAlertShow}
                    setDeleteAlertShow={setDeleteAlertShow}
                    deleteConfirmOnClickHandle={deleteConfirmOnClick}
                />
                <hr/>
            </Form>
        </>
    );
}

export default DepartmentConfigureForm;