import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch} from "react-redux";
import {StudentContext} from "../OurStudentSection";
import {useForm} from "react-hook-form";
import {deleteSessionalBookAction} from "../../../Redux/Action/SessionalBook";

function RemoveBookForm(props) {
    // hook
    const bookSubmitRef = useRef()
    const dispatch = useDispatch()


    const {
        classWiseStudent,
        setClassWiseStudent,
        sessionalBookModel,
        setSessionalBookModel,
        allSecionalBook,
        setAllSecionalBook,
        filterData,
        setFilterData
    }=useContext(StudentContext)

    const [departmentFilteredBook, setDepartmentFilteredBook] = useState([])

    useEffect(()=>{
        allSecionalBook.map((value)=>{
            if (value.department_id == filterData.department_id && value.class_segment_id == filterData.class_segment_id ){
                setDepartmentFilteredBook(prev=>{
                    let updatedData = [...prev]
                    updatedData.push(value)
                    return updatedData
                })
            }
        })
    },[allSecionalBook])


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states





    // class on change
    const subjectOnChange = () => {
        const selectedSubject = JSON.parse(getValues("subject_name") || "{}")


    }


    // form submit
    const onSubmit = async data => {

        // modifying data
        let updatedData = {
            ...data,
            subject_id: JSON.parse(data.subject_name ||"{}").id,
            class_id: JSON.parse(data.subject_name ||"{}").class_id,
            department_id: JSON.parse(data.subject_name ||"{}").department_id,
            class_segment_id: JSON.parse(data.subject_name ||"{}").class_segment_id,
            academic_year: filterData.academic_year,
        }
        const payload = {
            reset,
            bookSubmitRef,
            setSessionalBookModel
        }
        payload.data = updatedData

        console.log(payload.data)

        dispatch(deleteSessionalBookAction(payload))
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Subject <span>*</span></Form.Label>
                            <Form.Select
                                {...register("subject_name", {
                                    required: true,

                                })}
                            >
                                <option value="">Select...</option>
                                {departmentFilteredBook.map((value, key) => (
                                    <option value={JSON.stringify(value)}>{value.subject_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>


                </Row>


                <div>
                    <Button ref={bookSubmitRef}
                            type="submit"
                            className="submit btn-sm">
                        Submit
                        <ButtonLoader/>
                    </Button>


                </div>

            </form>
        </>
    );
}

export default RemoveBookForm;