import React from 'react';
import loader from '../../../assets/images/sectionLoader.svg'
import './sectionLoaderCss.css'

function SectionLoader({loading_handle_ref}) {
    return (<div ref={loading_handle_ref} className="sectionLoaderContainer d-none">
        <img src={loader}/>
    </div>);
}

export default SectionLoader;