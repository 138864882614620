import React from 'react';
import FounderComponent from "../Component/FounderComponent/FounderComponent";

function FounderAboutPage(props) {
    return (
        <>
            <FounderComponent/>
        </>
    );
}

export default FounderAboutPage;