import React, {createContext, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {Card} from "react-bootstrap";
import HeaderComponent from "../../CommonComponent/ComponentHeader/HeaderComponent";
import ServiceTable from "./ServiceTable";
import SectionLoader from "../../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import ServiceChargeForm from "./ServiceChargeForm";
import SectionForm from "./SectionForm";
import {getAllSectionAction} from "../../../Redux/Action/SectionAction";
import SectionTable from "./SectionTable";

export const SectionContext = createContext();
function SectionComponent(props) {
    //Ref
    const allSectionTableLoaderRef = useRef()
    const dispatch = useDispatch()

    //state
    const [allSectionReload, setAllSectionReload] = useState(false)
    const [sectionAddModelShow, setSectionAddModelShow] = useState(false)
    const [sectionEditModelShow, setSectionEditModelShow] = useState(false)
    const [getSectionSingleData, setGetSectionSingleData] = useState({})


    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allSectionTableLoaderRef,
        }
        dispatch(getAllSectionAction(Payload))
    }, [allSectionReload])
    return (
        <>
            <SectionContext.Provider value={{
                allSectionReload,
                setAllSectionReload,
                sectionAddModelShow,
                setSectionAddModelShow,
                sectionEditModelShow,
                setSectionEditModelShow,
                getSectionSingleData,
                setGetSectionSingleData
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3 >All Section</h3>
                            {HeaderComponent.refresh([setAllSectionReload])}
                            {HeaderComponent.add(setSectionAddModelShow) }
                        </div>
                        <SectionTable/>
                        <SectionLoader loading_handle_ref={allSectionTableLoaderRef}/>
                        {/*add modal show*/}
                        <CommonModal
                            show={sectionAddModelShow}
                            setShow={setSectionAddModelShow}
                            title={"Add Section"}
                            size="md"
                        >
                            <SectionForm/>
                        </CommonModal>
                        {/*Edit modal show*/}
                        <CommonModal
                            show={sectionEditModelShow}
                            setShow={setSectionEditModelShow}
                            title={"Edit Class"}
                            size="md"
                        >
                            <SectionForm formType={'Update'} default_data={getSectionSingleData}/>
                        </CommonModal>


                    </Card.Body>
                </Card>

            </SectionContext.Provider>
        </>
    );
}

export default SectionComponent;