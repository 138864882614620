import React from 'react';
import ExamComponent from "../Component/ExamComponent/ExamComponent";

function ExamPage(props) {
    return (
        <>
            <ExamComponent/>
        </>
    );
}

export default ExamPage;