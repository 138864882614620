import {createSlice} from "@reduxjs/toolkit";

export const TeacherSlice = createSlice({
    name:"teacherSlice",
    initialState:{
        allTeachers:[]
    },
    reducers:{
        getAllTeacherReducers:(state, action)=>{
            state.allTeachers = action.payload
        }
    }
})