import React, {createContext, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import {useDispatch} from "react-redux";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import NoticeForm from "./NoticeSubComponent/NoticeForm";
import NoticeArea from "./NoticeSubComponent/NoticeArea";
import {getAllNoticeAction} from "../../Redux/Action/NoticeAction";
import StaffForm from "../StaffComponent/staffSubComponent/StaffForm";
import DeleteNotice from "./NoticeSubComponent/DeleteNotice";
export const NoticeContext = createContext()
function NoticeComponent(props) {
    //Ref
    const allNoticeLoaderRef = useRef()
    const dispatch = useDispatch()
    //state
    const [allNoticeReload, setAllNoticeReload] = useState(false)
    const [noticeAddModelShow, setNoticeAddModelShow] = useState(false)
    const [noticeEditModelShow, setNoticeEditModelShow] = useState(false)
    const [noticeSingleData, setNoticeSingleData] = useState({})
    const [noticeDeleteModelShow, setNoticeDeleteModelShow] = useState(false)


    // get all Notice lifecycle
    useEffect(() => {
        const Payload = {
            allNoticeLoaderRef,
        }
        dispatch(getAllNoticeAction(Payload))
    }, [allNoticeReload])

    return (
        <>
            <NoticeContext.Provider value={{
                allNoticeReload,
                setAllNoticeReload,
                noticeAddModelShow,
                setNoticeAddModelShow,
                noticeEditModelShow,
                setNoticeEditModelShow,
                noticeSingleData,
                setNoticeSingleData,
                noticeDeleteModelShow,
                setNoticeDeleteModelShow
            }}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h3>All Notice</h3>
                    {HeaderComponent.refresh([setAllNoticeReload])}
                    {HeaderComponent.add(setNoticeAddModelShow) }
                </div>
                {/*All notice Show*/}
                <NoticeArea/>

                {/*section Loader Component*/}
                <SectionLoader loading_handle_ref={allNoticeLoaderRef}/>
                {/*Notice Add Model Show*/}
                <CommonModal
                    show={noticeAddModelShow}
                    setShow={setNoticeAddModelShow}
                    title={"Add Notice"}
                    size="xl"
                >
                    <NoticeForm/>
                </CommonModal>

                {/*Notice edit Model Show*/}
                <CommonModal
                    show={noticeEditModelShow}
                    setShow={setNoticeEditModelShow}
                    title={"Edit Notice"}
                    size="xl"
                >
                    <NoticeForm formType={'Update'} default_data={noticeSingleData} />
                </CommonModal>

                {/*Notice Delete Model Show*/}
                <CommonModal
                    show={noticeDeleteModelShow}
                    setShow={setNoticeDeleteModelShow}
                    title={"Delete Notice"}
                    size="md"
                >
                   <DeleteNotice/>
                </CommonModal>


            </NoticeContext.Provider>


        </>
    );
}

export default NoticeComponent;