import React, {createContext, useEffect, useRef, useState} from 'react';
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import NoticeArea from "../NoticeComponent/NoticeSubComponent/NoticeArea";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import {NoticeContext} from "../NoticeComponent/NoticeComponent";
import {useDispatch} from "react-redux";
import {Card, Tab, Tabs} from "react-bootstrap";
import ExamList from "./examSubComponent/ExamList";
import {getAllExamAction} from "../../Redux/Action/ExamAction";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import NoticeForm from "../NoticeComponent/NoticeSubComponent/NoticeForm";
import ExamFormJsx from "./examSubComponent/ExamForm";
import ExamForm from "./examSubComponent/ExamForm";
import DeleteNotice from "../NoticeComponent/NoticeSubComponent/DeleteNotice";
import DeleteExam from "./examSubComponent/DeleteExam";
import ExamFilter from "./examSubComponent/ExamFilter";
import {getAllClassAction} from "../../Redux/Action/ClassAction";
import SessionalExamTab from "./examSubComponent/SessionalExamTab";
import SessionalExamAddForm from "./examSubComponent/SessionalExamAddForm";
import AllExam from "./examSubComponent/AllExam";
import SessionalExam from "./examSubComponent/SessionalExam";

export const ExamContext = createContext();

function ExamComponent(props) {
    const [key, setKey] = useState('exam');

    return (
        <>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="exam" title="All Exam">
                        <AllExam/>
                    </Tab>
                    <Tab eventKey="profile" title="Sessional Exam">
                        <SessionalExam/>
                    </Tab>
                </Tabs>

        </>
    );
}

export default ExamComponent;