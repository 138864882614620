import React from 'react';
import {Modal} from "react-bootstrap";

function CommonModal(props) {
    // getting parameter from prps
    const {
        show = false,
        setShow,
        title = "title",
        size = "md",
        fullscreen = "md-down"
    } = props
    // modal close
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Modal size={size} fullscreen={fullscreen} show={show} centered={true} onHide={handleClose}>
                <Modal.Header className="py-3" style={{background: "#4646461c none repeat scroll 0% 0%"}} closeButton>
                    <h5 style={{fontSize: "18px", color: "#535353"}} className="mb-1 fw-bold">{title}</h5>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CommonModal;