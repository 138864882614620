import React, {Suspense} from 'react';

const ShiftComponent = React.lazy(() => import("../Component/UtilitiesComponent/UtilitiesSubComponent/ShiftComponent"))

function ShiftPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ShiftComponent/>
            </Suspense>
        </>
    );
}

export default ShiftPage;