import React, {useContext, useEffect, useRef} from 'react';
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {ClassContext} from "../ClassComponent";
import {addClassAction, updateClassAction} from "../../../Redux/Action/ClassAction";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function ClassForm({default_data = {}}) {
    // ref
    const btnRef = useRef()

    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        allClassReload,
        setAllClassReload,
        classAddModelShow,
        setClassAddModelShow,
        classEditModelShow,
        setClassEditModelShow,
    } = useContext(ClassContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    // default data set when update
    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, default_data[fieldProp])
            })
        }

    }, [default_data])

    const onSubmit = async data => {
        // making payload for action
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                class_id: default_data.id
            },
            reset,
            btnRef,
            setAllClassReload,
            setClassAddModelShow,
            setClassEditModelShow
        }

        // action dispatch by checking condition. ex: add and update
        Object.keys(default_data).length == 0 ? dispatch(addClassAction(payload)) : dispatch(updateClassAction(payload))

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Class name"
                                {...register("class_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.class_name && <span className="text-danger">
                        {errors.class_name.type === "required" && "Please Give Class Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Government School Class Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Government School Class name"
                                {...register("equal_of_general_edu", {
                                    required: false
                                })}
                            />

                            {/* validation message*/}
                            {errors.equal_of_general_edu && <span className="text-danger">
                        {errors.equal_of_general_edu.type === "required" && "Please Give Government School Class name"}
                    </span>}
                        </Form.Group>
                    </Col>


                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default ClassForm;