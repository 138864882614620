import React from 'react';
import StudentDetailsComponent from "../Component/SingleStudentDettailComponent/StudentDetailsComponent";

function SingleStudentDetailsPage(props) {
    return (
        <>
            <StudentDetailsComponent/>
        </>
    );
}

export default SingleStudentDetailsPage;