import React from 'react';

function AnnualEvent(props) {
    return (
        <>
            <h5>This is Annual Event</h5>
        </>
    );
}

export default AnnualEvent;