    import React from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import DashboardPage from "../Pages/DashboardPage";
import LoginPage from "../Pages/LoginPage";
import {Navigate} from "react-router";
import ProtectedRoute from "./Utilities/ProtectedRoute";
import UserSettingPage from "../Pages/UserSettingPage";
import UserPage from "../Pages/UserPage";
import ClassPage from "../Pages/ClassPage";
import SectionPage from "../Pages/SectionPage";
import ServiceChargePage from "../Pages/ServiceChargePage";
import ShiftPage from "../Pages/ShiftPage";
import SubjectPage from "../Pages/SubjectPage";
import StudentAdmissionPage from "../Pages/StudentAdmissionPage";
import OurStudentPage from "../Pages/OurStudentPage";
import OurTeacherPage from "../Pages/OurTeacherPage";
import OurStaffPage from "../Pages/OurStaffPage";
import SingleStudentDetailsPage from "../Pages/SingleStudentDetailsPage";
    import NoticeBoardPage from "../Pages/NoticeBoardPage";
    import CulturalEventPage from "../Pages/CulturalEventPage";
    import AnnualEvent from "../Pages/AnnualEvent";
    import AisjAboutPage from "../Pages/AisjAboutPage";
    import FounderAboutPage from "../Pages/FounderAboutPage";
    import DirectorAboutPage from "../Pages/DirectorAboutPage";
    import ExamPage from "../Pages/ExamPage";
    import MarkSheetPage from "../Pages/MarkSheetPage";
    import AllMarkSheetPage from "../Pages/AllMarkSheetPage";

function AppRoute(props) {
    return (
        <>
            <Routes>
                <Route path='/' element={<Navigate to={'/dashboard'}/>}/>
                {/*login route*/}
                <Route path='/login' element={<LoginPage/>}/>
                {/*dashboard route*/}
                <Route path='/dashboard' element={<ProtectedRoute permissions={["*"]}>
                    <DashboardPage/>
                </ProtectedRoute>}/>

                {/*Class*/}
                <Route path='/all-class' element={<ProtectedRoute
                    permissions={["config_c", "config_r", "config_u", "config_d"]}>
                    <ClassPage/>
                </ProtectedRoute>}/>

                {/*Section*/}
                <Route path='/Section' element={<ProtectedRoute
                    permissions={["config_c", "config_r", "config_u", "config_d"]}>
                    <SectionPage/>
                </ProtectedRoute>}/>

                {/*Service Charge*/}
                <Route path='/ServiceCharge' element={<ProtectedRoute
                    permissions={["config_c", "config_r", "config_u", "config_d"]}>
                    <ServiceChargePage/>
                </ProtectedRoute>}/>

                {/*Shift*/}
                <Route path='/Shift' element={<ProtectedRoute
                    permissions={["config_c", "config_r", "config_u", "config_d"]}>
                    <ShiftPage/>
                </ProtectedRoute>}/>

                {/*Subject*/}
                <Route path='/Subjects/:class_id' element={<ProtectedRoute
                    permissions={["config_c", "config_r", "config_u", "config_d"]}>
                    <SubjectPage/>
                </ProtectedRoute>}/>

                {/*admission*/}
                <Route path='/Admission' element={<ProtectedRoute
                    permissions={["*"]}>
                    <StudentAdmissionPage/>
                </ProtectedRoute>}/>

                {/*admission*/}
                <Route path='/OurStudent' element={<ProtectedRoute
                    permissions={["*"]}>
                    <OurStudentPage/>
                </ProtectedRoute>}/>

                {/*student details*/}
                <Route path='/studentDetail/:sessional_info_id' element={<ProtectedRoute
                    permissions={["*"]}>
                    <SingleStudentDetailsPage/>
                </ProtectedRoute>}/>


                <Route path='/setting' element={<ProtectedRoute
                    permissions={["*"]}>
                    <UserSettingPage/>
                </ProtectedRoute>}/>
                {/*Users*/}
                <Route path='/user' element={<ProtectedRoute
                    permissions={["*"]}>
                    <UserPage/>
                </ProtectedRoute>}/>

                {/*Our Teacher*/}
                <Route path='/teacher' element={<ProtectedRoute
                    permissions={["*"]}>
                    <OurTeacherPage/>
                </ProtectedRoute>}/>

                {/*Our Staff*/}
                <Route path='/staff' element={<ProtectedRoute
                    permissions={["*"]}>
                    <OurStaffPage/>
                </ProtectedRoute>}/>

                {/*Notice Board*/}
                <Route path='/noticeBoard' element={<ProtectedRoute
                    permissions={["*"]}>
                    <NoticeBoardPage/>
                </ProtectedRoute>}/>

                {/*Exam*/}
                <Route path='/Exam' element={<ProtectedRoute
                    permissions={["*"]}>
                    <ExamPage/>
                </ProtectedRoute>}/>

                {/*Exam*/}
                <Route path='/add_Marks' element={<ProtectedRoute
                    permissions={["*"]}>
                    <MarkSheetPage/>
                </ProtectedRoute>}/>

                {/*All MarkSheet*/}
                <Route path='/All_MarkSheet' element={<ProtectedRoute
                    permissions={["*"]}>
                    <AllMarkSheetPage/>
                </ProtectedRoute>}/>




                {/*Event*/}
                <Route path='/culturalEvent' element={<ProtectedRoute
                    permissions={["*"]}>
                    <CulturalEventPage/>
                </ProtectedRoute>}/>

                <Route path='/annualEvent' element={<ProtectedRoute
                    permissions={["*"]}>
                    <AnnualEvent/>
                </ProtectedRoute>}/>
                {/*End-Event*/}

                {/*About*/}
                <Route path='/AISJ' element={<ProtectedRoute
                    permissions={["*"]}>
                    <AisjAboutPage/>
                </ProtectedRoute>}/>

                <Route path='/Founder' element={<ProtectedRoute
                    permissions={["*"]}>
                    <FounderAboutPage/>
                </ProtectedRoute>}/>

                <Route path='/Director' element={<ProtectedRoute
                    permissions={["*"]}>
                    <DirectorAboutPage/>
                </ProtectedRoute>}/>

            </Routes>
        </>
    );
}

export default AppRoute;