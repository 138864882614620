import React, {useRef} from 'react';
import {Alert, Button} from "react-bootstrap";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

function OnScreenDeletePermission(
    {
        variant = "danger",
        deleteConfirmOnClickHandle = () => false,
        deleteButtonRef,
        deleteAlertShow = false,
        setDeleteAlertShow
    }
) {
    return (
        <>
            {deleteAlertShow == true && <Alert variant={variant} className="mt-2">
                Are you sure you want to delete it ?
                <div className="mt-2 text-end">
                    <Button onClick={e => setDeleteAlertShow(false)} className="btn-sm" variant="primary">Deny</Button>
                    <Button onClick={e => deleteConfirmOnClickHandle()} ref={deleteButtonRef} className="btn-sm ms-2"
                            variant="danger">
                        Yes
                        <ButtonLoader/>
                    </Button>
                </div>
            </Alert>}
        </>
    );
}

export default OnScreenDeletePermission;