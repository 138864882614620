import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import Regex from "../../../Utilities/Regex";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {updateStudentInfoAction} from "../../../Redux/Action/StudentAction";
import CommonDatePicker from "../../CommonComponent/DatePickerComponent/CommonDatePicker";
import CommonToast from "../../../Utilities/CommonToast";

function StudentBasicInformationUpdateForm({infoData = {}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()
    // states
    const [dob, setDob] = useState(null)

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData[fieldProp])
            })
            setDob(new Date(infoData.DOB))
        }
    }, [infoData])

    console.log()
    const onSubmit = async data => {
        // making payload for action
        const payload = {
            data: {
                // for edit and update
                // for edit and update
                ...data,
                DOB: dob,
                // for update
                student_info_id: singleStudentDetail.student_info_id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setEditModelShow

        }
        dob ? dispatch(updateStudentInfoAction(payload)) : CommonToast.warning("Please type valid dob.")
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Student Name <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("student_full_name", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.student_full_name && <span className="text-danger">
                        {errors.student_full_name.type === "required" && "Please Give Student Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className=" mb-2" controlId="formBasicEmail">
                            <Form.Label>Gender <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.gender ? "is-invalid" : ""}`}
                                {...register("gender", {
                                    required: true
                                })}
                            >
                                <option value="">Select Gender</option>
                                {ApplicationUtilities.allGenders().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}

                            </Form.Select>

                            {errors.gender && <span className="text-danger">
                                {errors.gender.type == "required" && "This field is required"}
                                {errors.gender.type == "pattern" && "Please Select Genger"}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {/*        <Form.Label>Date of Birth <span>*</span></Form.Label>*/}
                            {/*        <Form.Control*/}
                            {/*            type="date"*/}
                            {/*            id="DOB"*/}
                            {/*            {...register("DOB", {*/}
                            {/*                required: true,*/}
                            {/*            })}*/}
                            {/*        />*/}
                            {/*        /!* validation message*!/*/}
                            {/*        {errors.DOB && <span className="text-danger">*/}
                            {/*    {errors.DOB.type === "required" && "Please Give Date of Birth"}*/}
                            {/*</span>}*/}
                            {CommonDatePicker.dateField(dob, setDob, "Date of birth")}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nationality <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("nationality", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.nationality && <span className="text-danger">
                        {errors.nationality.type === "required" && "Please Give Nationality"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Birth certificate Reg No. <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("birth_reg_no", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.birth_reg_no && <span className="text-danger">
                        {errors.birth_reg_no.type === "required" && "Please Give Birth certificate Reg No."}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Aadhaar Card No. </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("adhaar_card_no", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Religion <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.religion ? "is-invalid" : ""}`}
                                {...register("religion", {
                                    required: true
                                })}
                            >
                                <option value="">Select Religion</option>
                                {ApplicationUtilities.religions().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}

                            </Form.Select>
                            {/* validation message*/}
                            {errors.religion && <span className="text-danger">
                        {errors.religion.type === "required" && "Please Select Religion"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Social Category </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("social_category", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Mother Tongue </Form.Label>
                            <Form.Select
                                className={`${errors.mother_tongue ? "is-invalid" : ""}`}
                                {...register("mother_tongue", {
                                    required: false
                                })}
                            >
                                <option value="">Select Language</option>
                                {ApplicationUtilities.languages().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Blood Group </Form.Label>
                            <Form.Select
                                className={`${errors.blood_group ? "is-invalid" : ""}`}
                                {...register("blood_group", {
                                    required: false
                                })}
                            >
                                <option value="">Select Blood Group</option>
                                {ApplicationUtilities.bloodGroups().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Contact Number <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("contact_number", {
                                    required: true,
                                    pattern: Regex.numeric()
                                })}
                            />
                            {/* validation message*/}
                            {errors.contact_number && <span className="text-danger">
                        {errors.contact_number.type === "required" && "Please Give contact number"}
                                {errors.contact_number.type === "pattern" && "Please Give valid contact number"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Security Key <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("security_key", {
                                    required: true

                                })}
                            />
                            {/* validation message*/}
                            {errors.security_key && <span className="text-danger">
                        {errors.security_key.type === "required" && "Please Give contact number"}

                    </span>}
                        </Form.Group>
                    </Col>

                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default StudentBasicInformationUpdateForm;