import React, {useContext, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {StaffContext} from "../StaffComponent";
import {addStaffImageAction} from "../../../Redux/Action/ShiftAction";

function StaffImageForm({default_data = {}}) {
    // ref
    const btnRef = useRef()

    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        allStaffReload,
        setAllStaffReload,
        staffAddModelShow,
        setStaffAddModelShow,
        staffEditModelShow,
        setStaffEditModelShow,
        getStaffSingleData,
        setGetStaffSingleData,
        addImageModelShow,
        setAddImageModelShow
    } = useContext(StaffContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    const onSubmit = async data => {
        const formData = new FormData();
        formData.append('staff_avatar', data.staff_avatar[0]);
        formData.append('staff_id', default_data.id);


        const payload = {
            data: formData,
                ...data,
                staff_id: default_data.id,
            reset,
            btnRef,
            setAddImageModelShow,
            allStaffReload,
            setAllStaffReload

        }
        dispatch(addStaffImageAction(payload))


    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Teacher Image </Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                {...register("staff_avatar", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                   Submit
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default StaffImageForm;