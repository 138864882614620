import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import AisjForm from "./AisjSubComponent/AisjForm";

import {AisjAction} from "../../Redux/Action/AboutAction";
import {useDispatch} from "react-redux";

function AisjComponent(props) {
    const dispatch = useDispatch()
    // get all Notice lifecycle
    useEffect(() => {
        dispatch(AisjAction())
    }, [])

    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <h3>AISJ</h3>
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            <Card>
                                <Card.Body>
                                    <AisjForm/>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
}

export default AisjComponent;