import {createSlice} from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
    name: "AuthSlice",
    initialState: {
        isLogged: true,
        sessionIsChanged: false
    },
    reducers: {
        setIsLoggedReducer: (state, action) => {
            state.isLogged = action.payload
        },
        setSessionIsChangedReducer: (state, action) => {
            state.sessionIsChanged = action.payload
        }
    }
})