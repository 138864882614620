import React, {useContext, useRef} from 'react';
import {Button} from "react-bootstrap";
import {TiTick} from "@react-icons/all-files/ti/TiTick";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {TiDelete} from "@react-icons/all-files/ti/TiDelete";
import {DeleteNoticeAction} from "../../../Redux/Action/NoticeAction";
import {ExamContext} from "../ExamComponent";
import {useDispatch} from "react-redux";
import {DeleteExamAction} from "../../../Redux/Action/ExamAction";

function DeleteExam(props) {
    const btnRef = useRef()
    const dispatch = useDispatch()
    const {
        allExamReload,
        setAllExamReload,
        addExamModelShow,
        setAddExamModelShow,
        editExamModelShow,
        setEditExamModelShow,
        deleteExamModelShow,
        setDeleteExamModelShow,
        examSingleData,
        setExamSingleData
    } = useContext(ExamContext)

    const deleteClick = () =>{
        const examId = examSingleData.id

        const Payload ={
            data:{
                exam_id :examId
            },
            btnRef,
            setDeleteExamModelShow,
            setAllExamReload


        }
        dispatch(DeleteExamAction(Payload))


    }
    return (
        <>
            <div className="text-center">
                <h3 className="mt-3 mb-3">Are You Sure Delete This Exam</h3>
                <Button className="mx-2" ref={btnRef} variant="success" onClick={deleteClick}>YES <TiTick/>
                    <ButtonLoader/>
                </Button>
                <Button
                    variant="danger"
                    onClick={e =>{
                        setDeleteExamModelShow(false)
                    }}>NO <TiDelete/></Button>
            </div>
        </>
    );
}

export default DeleteExam;