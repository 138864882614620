import React from 'react';
import AisjComponent from "../Component/AisjComponent/AisjComponent";

function AisjAboutPage(props) {
    return (
        <>
            <AisjComponent/>
        </>
    );
}

export default AisjAboutPage;