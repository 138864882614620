class Regex {
    constructor(props) {
    }

    // numeric regex
    static numeric() {
        return /^-?\d*\.?\d*$/
    }
}

// export
export default Regex