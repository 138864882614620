import React from 'react';
import NoticeComponent from "../Component/NoticeComponent/NoticeComponent";

function NoticeBoardPage(props) {
    return (
        <>
            <NoticeComponent/>
        </>
    );
}

export default NoticeBoardPage;