import React from 'react';
import {Table} from "react-bootstrap";

function StudentInfoDetails({table_ref}) {
    return (
        <>
            <Table ref={table_ref}>
                <thead>
                <tr>
                    <td>#</td>
                    <td>user name</td>
                    <td>password</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Shanto</td>
                    <td>123456</td>
                </tr>
                </tbody>
            </Table>
        </>
    );
}

export default StudentInfoDetails;