import React from 'react';
import DirectorComponent from "../Component/DirectorComponent/DirectorComponent";

function DirectorAboutPage(props) {
    return (
        <>
            <DirectorComponent/>
        </>
    );
}

export default DirectorAboutPage;