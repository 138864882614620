import React, {useContext} from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {useSelector} from "react-redux";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {ClassContext} from "../ClassComponent";
import {AiFillSetting} from "@react-icons/all-files/ai/AiFillSetting";
import {BiBookBookmark} from "@react-icons/all-files/bi/BiBookBookmark";
import {Link} from "react-router-dom";

function ClassTable({allClass = []}) {


    //Context
    const {
        allClassReload,
        setAllClassReload,
        classEditModelShow,
        setClassEditModelShow,
        getSingleClassData,
        setGetSingleClassData,
        configureModal,
        setConfigureModal,
        setSingleClassDetail
    } = useContext(ClassContext)


    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'class_name',
            text: 'Class name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'equal_of_general_edu',
            text: 'Government School Class Name',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '250px',
                    textAlign: "center",

                };
            },
            style: {textAlign: "center"},
            formatter: (cell, row) => {
                return <>{cell || "N/A"}</>
            }
        }, {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px', textAlign: "center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Edit Button*/}
                    <button title="Edit"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetSingleClassData(row)
                                // update modal show
                                setClassEditModelShow(true)
                            }
                            }>
                        <FiEdit/>
                    </button>
                    {/*department and segment configure Button*/}
                    <button title="Configure"
                            className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // set data
                                setSingleClassDetail(row)
                                // modal show
                                setConfigureModal(true)
                            }
                            }>
                        <AiFillSetting/>
                    </button>
                    {/*subject configure Button*/}
                    <Link target={"_blank"} to={`/Subjects/${row.id}`}>
                        <button title="Subjects"
                                className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                onClick={e => {
                                    localStorage.setItem("class_detail", JSON.stringify(row || {}))
                                }
                                }>
                            <BiBookBookmark/>
                        </button>
                    </Link>
                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={allClass}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default ClassTable;