"use client"
import React from 'react';
import {Placeholder} from "react-bootstrap";

function CommonTableLoader(props) {
    return (
        <>
            <Placeholder as="h2" className="mt-0" animation="glow">
                <Placeholder xs={12}/>
                <Placeholder xs={12}/>
                <Placeholder xs={12}/>
                <Placeholder xs={12}/>
                <Placeholder xs={12}/>
            </Placeholder>
        </>
    );
}

export default CommonTableLoader;