class DateFormat {
    constructor(props) {
    }

    // format
    static format(date) {
        let d = new Date(date)
        let formattedDate = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()
        return formattedDate
    }

    // month format
    static monthFormat(date) {
        let monthNameArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        let d = new Date(date)
        let monthName = monthNameArr[d.getMonth()]
        let y = d.getFullYear()
        let formattedMonth = monthName + " " + y
        return formattedMonth
    }

    // formatting for date field
    static forDateField(date = null) {
        console.log(date)
        let formattedDate = date
        if (date != null) {
            let d = new Date(date)
            let y = d.getFullYear()
            let m = d.getMonth() + 1
            let dy = d.getDate()
            formattedDate = y + "-" + m + "-" + dy
        }

        return formattedDate
    }

}

export default DateFormat