import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {
    addEvent,
    deleteEvent,
    getAllEvent,
    noticeDelete,
    updateEvent,
    updateEventImage,
    updateNotice
} from "../../AllRoutes/ApiRoute";
import {EventSlice} from "../Slice/EventSlice";
import CommonToast from "../../Utilities/CommonToast";


export const getAllEventAction = payload => dispatch =>{
    // getting data from payload
    const {
        allEventLoaderRef,
        data
    } = payload


    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allEventLoaderRef)
        } catch (e) {
        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.silentPost(getAllEvent, payload).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(EventSlice.actions.getAllEventReducers(res.data.data || []))

        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })

}

// Add Event Action

export const addEventAction = payload => dispatch =>{
    const {
        btnRef,
        setEventAddModelShow,
        setAllEventReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addEvent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEventAddModelShow(false)
            //all Teacher table reload
            setAllEventReload(prev => !prev)
            // toast
            CommonToast.success("Event Create Successfully!")
        } else {
            CommonToast.error("Event Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// Update Event Action
export const UpdateEventAction = payload => dispatch =>{
    const {
        btnRef,
        setEventEditModelShow,
        setAllEventReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateEvent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEventEditModelShow(false)
            //all Agent table reload
            setAllEventReload(prev => !prev)
            // toast
            CommonToast.success("Event updated Successfully!")
        } else {
            CommonToast.warning("Event updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Delete Notice Action
export const DeleteEventAction = payload => dispatch =>{
    const {
        btnRef,
        setEventDeleteModelShow,
        setEventSingleData,
        setAllEventReload
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    CommonAxios.post(deleteEvent, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEventDeleteModelShow(false)
            //all Agent table reload
            setAllEventReload(prev => !prev)
            // toast
            CommonToast.success("Event Delete Successfully!")
        } else {
            CommonToast.warning("Event Delete failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Update Event Image Action
export const UpdateEventImageAction = payload => dispatch =>{
    const {
        btnRef,
        setEventImageEditModelShow,
        setAllEventReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateEventImage, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEventImageEditModelShow(false)

            //all Agent table reload
            setAllEventReload(prev => !prev)

            // toast
            CommonToast.success("Update Event Image Successfully!")
        } else {
            CommonToast.warning("Update Event Image failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}