import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {
    addExamController,
    addSessionalExam,
    deleteExam,
    getAllExam, getSessionalExams,
    updateExamController, deleteSessionalExam
} from "../../AllRoutes/ApiRoute";
import {ExamSlice} from "../Slice/ExamSlice";
import CommonToast from "../../Utilities/CommonToast";

export const getAllExamAction = payload => dispatch =>{
    // getting data from payload
    const {
        allExamLoaderRef,
        data
    } = payload

    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allExamLoaderRef)
        } catch (e) {
        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.get(getAllExam, payload).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(ExamSlice.actions.getAllExamReducers(res.data.data || []))

        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })
}

// Add Exam Action
export const addExamAction = payload => dispatch =>{
    const {
        btnRef,
        setAddExamModelShow,
        setAllExamReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addExamController, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAddExamModelShow(false)
            //all Exam table reload
            setAllExamReload(prev => !prev)
            // toast
            CommonToast.success("Exam Create Successfully!")
        } else {
            CommonToast.error("Exam Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// Update Exam Action
export const UpdateExamAction = payload => dispatch =>{
    const {
        btnRef,
        setEditExamModelShow,
        setAllExamReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateExamController, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEditExamModelShow(false)
            //all Agent table reload
            setAllExamReload(prev => !prev)
            // toast
            CommonToast.success("Exam updated Successfully!")
        } else {
            CommonToast.warning("Exam updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Delete Exam Action
export const DeleteExamAction = payload => dispatch => {
    const {
        btnRef,
        setDeleteExamModelShow,
        setAllExamReload
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(deleteExam, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setDeleteExamModelShow(false)
            //all Agent table reload
            setAllExamReload(prev => !prev)
            // toast
            CommonToast.success("Exam Delete Successfully!")
        } else {
            CommonToast.warning("Exam Delete failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}



// Add Sessional Exam Action
export const addSessionalExamAction = payload => dispatch =>{
    const {
        reset,
        btnRef,
        setSessionalExamModelShow,
        setAllExamReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addSessionalExam, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setSessionalExamModelShow(false)
            //all Exam table reload
            setAllExamReload(prev => !prev)
            // toast
            CommonToast.success("Sessional Exam Add Successfully!")
        } else {
            CommonToast.error("Sessional Exam add failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// get Sessional Exams  Action
export const getSessionalExamsAction = payload => dispatch => {
    const {
        sessionalExamFilterBtnRef,
        setFilterData,
        setAllExamReload
    } = payload

    // handle button loader
    CommonLoader.btn(sessionalExamFilterBtnRef)


    CommonAxios.post(getSessionalExams, payload).then(res => {
        // handle button loader
        CommonLoader.btn(sessionalExamFilterBtnRef)
        // modal close
        setFilterData(res?.data?.data || [])
        //all Agent table reload
        setAllExamReload(prev => !prev)

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(sessionalExamFilterBtnRef)
    })
}

// Delete Sessional Exam Action
export const DeleteSessionalExamAction = payload => dispatch => {
    const {
        deleteBtnRef,
        setDeleteSessionalExamModelShow,
        setAllExamReload
    } = payload

    // handle button loader
    CommonLoader.btn(deleteBtnRef)


    CommonAxios.post(deleteSessionalExam, payload).then(res => {
        // handle button loader
        CommonLoader.btn(deleteBtnRef)

        if (res.data.status == true) {
            // modal close
            setDeleteSessionalExamModelShow(false)
            //all Agent table reload
            setAllExamReload(prev => !prev)

            // toast


            CommonToast.success("Sessional Exam Delete Successfully!")
        } else {
            CommonToast.warning("Sessional Exam Delete failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(deleteBtnRef)
    })
}
