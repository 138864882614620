import React, {useContext} from 'react';
import {Card, Table} from "react-bootstrap";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";

import StudentGuardianInformationEditForm from "./StudentGuardianInformationEditForm";

function GuardianInformationTable({data = {}}) {
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        birthCertificateEditModel,
        setBirthCertificateEditModel,
        aadhaarCardEditModel,
        setAadhaarCardEditModel,
        guardianInformationEditModel,
        setGuardianInformationEditModel
    }=useContext(StudentSingleDateContext)

    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Guardian information -</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setGuardianInformationEditModel(true)

                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <ul>
                                <li><strong>Father Name:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.father_name}</span></li>
                                <li><strong>Mother Name:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.mother_name}</span></li>
                                <li><strong>Father Education:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.father_edu_qualification || "N/A"}</span></li>
                                <li><strong>Mother Education:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.mother_edu_qualification || "N/A"}</span></li>
                                <li><strong>Legal Guardian:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.guardian_name || "N/A"}</span></li>
                                <li><strong>Relation:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.relation_with_guardian || "N/A"}</span></li>
                                <li><strong>Guardian Occupation:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.guardian_occupation || "N/A"}</span></li>
                                <li><strong>Yearly Family Income:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.yearly_family_income || "N/A"}</span></li>
                                <li><strong>Guardian mobile:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.guardian_mobile || "N/A"}</span></li>
                                <li><strong>Email:</strong><span>{data.stu_a_student_info?.stu_b_student_guardian_info?.email || "N/A"}</span></li>
                            </ul>

                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/*Guardian Information Edit modal show*/}
            <CommonModal
                show={guardianInformationEditModel}
                setShow={setGuardianInformationEditModel}
                title={"Edit Guardian Information"}
                size="lg"
            >
               <StudentGuardianInformationEditForm infoData={data.stu_a_student_info}/>

            </CommonModal>


        </>
    );
}

export default GuardianInformationTable;