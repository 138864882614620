import {createSlice} from "@reduxjs/toolkit";

export const NoticeSlice = createSlice({
    name:"noticeSlice",
    initialState:{
        allNotice:[]
    },
    reducers:{
        getAllNoticeReducers:(state, action)=>{
            state.allNotice = action.payload
        }
    }
})