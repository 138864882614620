import {configureStore} from "@reduxjs/toolkit";
import {AuthSlice} from "./Slice/AuthSlice";
import {UserSlice} from "./Slice/UserSlice";
import {ConfigurationSlice} from "./Slice/ConfigurationSlice";
import {StudentSlice} from "./Slice/studentSlice";
import {TeacherSlice} from "./Slice/TeacherSlice";
import {StaffSlice} from "./Slice/StaffSlice";
import {NoticeSlice} from "./Slice/noticeSlice";
import {EventSlice} from "./Slice/EventSlice";
import {AboutSlice} from "./Slice/AboutSlice";
import {ExamSlice} from "./Slice/ExamSlice";


export const Store = configureStore({
    reducer: {
        authSlice: AuthSlice.reducer,
        userSlice: UserSlice.reducer,
        configurationSlice: ConfigurationSlice.reducer,
        studentSlice: StudentSlice.reducer,
        teacherSlice: TeacherSlice.reducer,
        staffSlice: StaffSlice.reducer,
        noticeSlice: NoticeSlice.reducer,
        eventSlice: EventSlice.reducer,
        aboutSlice: AboutSlice.reducer,
        examSlice: ExamSlice.reducer,
    }
})