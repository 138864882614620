import React, {useState} from 'react';
import {Tabs, Tab} from "react-bootstrap";

import AddBookForm from "./AddBookForm";
import RemoveBookForm from "./RemoveBookForm";

function ClassWiseBookForm(props) {
    const [key, setKey] = useState('home');
    return (
        <>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="home" title="Abb Book">
                    <AddBookForm/>
                </Tab>
                <Tab eventKey="profile" title="Remove Book">
                    <RemoveBookForm/>
                </Tab>
            </Tabs>
        </>
    );
}

export default ClassWiseBookForm;