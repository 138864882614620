import React, {useContext} from 'react';
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {useSelector} from "react-redux";
import {ClassContext} from "../../ClassComponent/ClassComponent";
import {ServiceChargeContext} from "./ServicesChargeComponent";

function ServiceTable(props) {
    // data getting from store
    const allClass = useSelector(state => state.configurationSlice.allServiceCharge)

    //Context
    const {
        allServiceChargeReload,
        setAllServiceChargeReload,
        serviceChargeAddModelShow,
        setServiceChargeAddModelShow,
        serviceChargeEditModelShow,
        setServiceChargeEditModelShow,
        getServiceChargeSingleClassData,
        setGetServiceChargeSingleData
    } = useContext(ServiceChargeContext)

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },  
        {
            dataField: 'service_name',
            text: 'Service Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },  {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',textAlign:"center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Edit Button*/}
                    <button title="Edit Service"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetServiceChargeSingleData(row)
                                // update modal show
                                setServiceChargeEditModelShow(true)
                            }
                            }>
                        <FiEdit/>
                    </button>
                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={allClass}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default ServiceTable;