import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import CommonDatePicker from "../../CommonComponent/DatePickerComponent/CommonDatePicker";
import Regex from "../../../Utilities/Regex";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {updateStudentGuardianInfoAction, updateStudentInfoAction} from "../../../Redux/Action/StudentAction";
import CommonToast from "../../../Utilities/CommonToast";

function StudentGuardianInformationEditForm({infoData = {}}) {

    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        guardianInformationEditModel,
        setGuardianInformationEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()
    // states
    const [dob, setDob] = useState(null)

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData.stu_b_student_guardian_info).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData.stu_b_student_guardian_info[fieldProp])
            })
        }
    }, [infoData])

    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                student_info_id: singleStudentDetail.student_info_id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setGuardianInformationEditModel

        }
        dispatch(updateStudentGuardianInfoAction(payload))

    }



    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Father Name <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("father_name", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.father_name && <span className="text-danger">
                        {errors.father_name.type === "required" && "Please Give Father Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Mother Name <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("mother_name", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.mother_name && <span className="text-danger">
                        {errors.mother_name.type === "required" && "Please Give Mother Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Father Edu Qualification </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("father_edu_qualification", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Mother Edu Qualification </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("mother_edu_qualification", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Guardian Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("guardian_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Relation With Guardian </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("relation_with_guardian", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Guardian Occupation </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("guardian_occupation", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Yearly Family Income </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("yearly_family_income", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Guardian Mobile No. </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("guardian_mobile", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("email", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default StudentGuardianInformationEditForm;