import React, {useContext, useRef} from 'react';
import {MarksheetContext} from "../../MarkSheetComponent/MarkSheetComponent";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function ExamWiseMarkSheetFilter(props) {
    //Hook
    const btnRef = useRef()
    //Context
    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examAndSubjectWiseStudent,
        setExamAndSubjectWiseStudent,
        studentSubjectWiseMarks,
        setStudentSubjectWiseMarks,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId,
        studentMarkSheet,
        setStudentMarkSheet,
        examId,
        setExamId,
    } = useContext(MarksheetContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();


    // form submit
    const onSubmit = async data => {

        // modifying data
        let filterData = {
            ...data,
            sessional_exam_id: JSON.parse(data.sessional_exam_id || "{}").id,
        }
        setExamId(filterData)


        //  console.log(classWiseStudent)

        // filtering subject form student
        let filteredSubjectWithStudentArr = []
        let students_for_generate_mark_sheet = []


        // get single student from all class wise student map
        classWiseStudent.map(singleStudent => {
            singleStudent.mark_sheets_for_print = []
            // get a single book from all single student's sesional books
            singleStudent?.stu_h_student_sessional_books?.map(singleBook => {

                singleBook.stu_i_student_sessional_marksheets.map(singleExamMarks => {

                    if (filterData.sessional_exam_id == singleExamMarks.sessional_exam_id) {

                        singleStudent.mark_sheets_for_print.push({
                            subject_name: singleBook?.subject_name,
                            exam_detail: singleExamMarks
                        })
                    }
                })
            })

            students_for_generate_mark_sheet.push(singleStudent)

        })
        console.log(students_for_generate_mark_sheet)
        setStudentMarkSheet(students_for_generate_mark_sheet)

    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Select
                                {...register("sessional_exam_id", {
                                    required: true,

                                })}
                            >
                                <option value="">Select Exam</option>
                                {examListForMarksheet.map((singleExam, key) => (
                                    <option value={JSON.stringify(singleExam)}>{singleExam.exam_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={3} sm={12}>
                        <Button ref={btnRef}
                                type="submit"
                                className="submit btn-sm">
                            Submit
                            <ButtonLoader/>
                        </Button>
                    </Col>
                </Row>

            </form>
        </>
    );
}

export default ExamWiseMarkSheetFilter;