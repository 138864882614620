import React, {useContext, useEffect, useRef} from 'react';
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {updateBankInfoAction, updatePreviousSchoolInfoAction} from "../../../Redux/Action/StudentAction";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function BankInfoEditForm({infoData={}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        bankInfoEditModel,
        setBankInfoEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData.stu_g_student_bank_info).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData.stu_g_student_bank_info[fieldProp])
            })
        }
    }, [infoData])

    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                bank_info_id: singleStudentDetail.stu_a_student_info.stu_g_student_bank_info.id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setBankInfoEditModel
        }
        dispatch(updateBankInfoAction(payload))

    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Bank Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("bank_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Account Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("account_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Account Number </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("account_number", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Branch Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("branch_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>IFSC code </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("ifsc_code", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Account Type</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("account_type", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default BankInfoEditForm;