import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch} from "react-redux";
import {StudentContext} from "../OurStudentSection";
import {useForm} from "react-hook-form";
import {addSessionalBookAction} from "../../../Redux/Action/SessionalBook";
import Regex from "../../../Utilities/Regex";

function AddBookForm(props) {
    // hook
    const bookSubmitRef = useRef()
    const dispatch = useDispatch()


    const {
        classWiseStudent,
        setClassWiseStudent,
        sessionalBookModel,
        setSessionalBookModel,
        allSecionalBook,
        setAllSecionalBook,
        filterData,
        setFilterData
    }=useContext(StudentContext)

    const [departmentFilteredBook, setDepartmentFilteredBook] = useState([])

    useEffect(()=>{
        allSecionalBook.map((value)=>{
            if (value.department_id == filterData.department_id && value.class_segment_id == filterData.class_segment_id ){
                setDepartmentFilteredBook(prev=>{
                    let updatedData = [...prev]
                    updatedData.push(value)
                    return updatedData
                })
            }
        })
    },[allSecionalBook])


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states
    const [recommendedBook, setRecommendedBook] = useState("")
    const [subjectCode, setSubjectCode] = useState("")





    // class on change
    const subjectOnChange = () => {
        const selectedSubject = JSON.parse(getValues("subject_name") || "{}")

        console.log(selectedSubject)

        // set Department and Segment wise Subject and Book
        setRecommendedBook( selectedSubject.recommended_book )
        setSubjectCode(selectedSubject.subject_code)

    }


    // form submit
    const onSubmit = async data => {

        // modifying data
        let updatedData = {
            ...data,
            subject_id: JSON.parse(data.subject_name ||"{}").id,
            subject_name: JSON.parse(data.subject_name ||"{}").subject_name,
            class_id: JSON.parse(data.subject_name ||"{}").class_id,
            department_id: JSON.parse(data.subject_name ||"{}").department_id,
            class_segment_id: JSON.parse(data.subject_name ||"{}").class_segment_id,
            academic_year: filterData.academic_year,
        }
        const payload = {
            reset,
            bookSubmitRef,
            setSessionalBookModel
        }
        payload.data = updatedData

        dispatch(addSessionalBookAction(payload))
    }
    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Subject <span>*</span></Form.Label>
                            <Form.Select
                                {...register("subject_name", {
                                    required: true,
                                    onChange: event => subjectOnChange()
                                })}
                            >
                                <option value="">Select...</option>
                                {departmentFilteredBook.map((value, key) => (
                                    <option value={JSON.stringify(value)}>{value.subject_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Exam Full Mark</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter Full Mark"

                                {...register("full_mark", {
                                    required: true,
                                    pattern: Regex.numeric(),
                                    maxLength:3


                                })}
                            />
                            {/*validation message*/}
                            {errors.full_mark && <span className="text-danger">
                                {errors.full_mark.type == "required" && "Please add marks"}
                                {errors.full_mark.type === "pattern" && "Please Give valid marks"}
                                {errors.full_mark.type === "maxLength" && "Please Give valid marks"}
                            </span>}


                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Recommended Booke</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Recommended Book"
                                defaultValue={recommendedBook}
                                {...register("recommended_book", {
                                    required: false
                                })}
                            />


                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Subject Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Subjec code"
                                defaultValue={subjectCode}
                                {...register("subject_code", {
                                    required: false
                                })}
                            />


                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Main Subject? <span>*</span></Form.Label>
                            <Form.Select
                                {...register("is_main_subject", {
                                    required: true,

                                })}
                            >
                                <option selected=" " defaultValue="1" value='1' >Yes</option>
                                <option value='0'>No</option>
                            </Form.Select>

                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Exam Subject? <span>*</span></Form.Label>
                            <Form.Select
                                {...register("is_exam_book", {
                                    required: true,

                                })}
                            >
                                <option selected=" " defaultValue="1" value='1' >Yes</option>
                                <option value='0'>No</option>
                            </Form.Select>

                        </Form.Group>
                    </Col>


                </Row>


                <div>
                    <Button ref={bookSubmitRef}
                            type="submit"
                            className="submit btn-sm">
                        Submit
                        <ButtonLoader/>
                    </Button>


                </div>

            </form>
        </>
    );
}

export default AddBookForm;