import CommonAxios from "../../Utilities/CommonAxios";
import {
    addUser,
    getAllRole,
    getAllUser,
    getAllUserForGetTransSummery,
    login, resetUserPasswordFromAdmin, updateUserFromAdmin,
    updateUserGeneralSetting,
    userLogOutFromAllDevice,
    userPasswordChange
} from "../../AllRoutes/ApiRoute";
import {Store} from "../Store";
import {AuthSlice} from "../Slice/AuthSlice";
import CommonToast from "../../Utilities/CommonToast";
import CommonLoader from "../../Utilities/CommonLoader";
import Auth from "../../Utilities/Auth";
import {UserSlice} from "../Slice/UserSlice";

export const loginAction = (payload) => dispatch => {
    // getting data from payload
    const {
        setIsLogged,
        loginBtnRef
    } = payload

    // login button loader handle
    CommonLoader.btn(loginBtnRef)

    // db operation
    CommonAxios.post(login, payload).then(res => {
        // login button loader handle
        CommonLoader.btn(loginBtnRef)

        if (res.data.status == true) {
            // set this state true from redux store for maintain after login auth
            Store.dispatch(AuthSlice.actions.setIsLoggedReducer(true))
            // set this state true for redirect to the dashboard after login
            setIsLogged(true)
        } else {  // if user not found
            CommonToast.error("user name or password dose not matched!")
        }
    }).catch(err => {
        // login button loader handle
        CommonLoader.btn(loginBtnRef)
    })
}

// update user action
export const updateUserAction = (payload) => dispatch => {
    // getting data from payload
    const {submitRef} = payload
    // handle button loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(updateUserGeneralSetting, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            CommonToast.success("user updated successfully")
        } else {
            CommonToast.error("user name already exist!")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })
}
// user password change action
export const userPasswordChangeAction = (payload) => dispatch => {
    // getting data from payload
    const {submitRef, reset} = payload
    // handle button loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(userPasswordChange, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            CommonToast.success("password successfully changed")
            reset()
        } else {
            CommonToast.error("old password didn't match")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })
}

// logout from all device action
export const logoutFromAllDeviceAction = (payload) => dispatch => {
    // getting data from payload
    const {submitRef} = payload
    // handle button loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(userLogOutFromAllDevice, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)
        if (res.data.status == false) {
            CommonToast.error("password didn't match.")
        } else {
            // redirect page by cleare session
            Auth.clear()
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })
}

// get all user
export const getAllUserAction = payload => dispatch => {
    // getting data form context
    const {userSectionLoaderRef} = payload
    // handle loader
    CommonLoader.section(userSectionLoaderRef)
    // db operation
    CommonAxios.get(getAllUser).then(res => {
        // handle loader
        CommonLoader.section(userSectionLoaderRef)
        if (res.data.status == true) {
            dispatch(UserSlice.actions.getAllUserForTransactionReducer(res.data.data))
        }
    }).catch(err => {
        // handle loader
        CommonLoader.section(userSectionLoaderRef)
    })
}

// get all role action
export const getAllRoleAction = payload => dispatch => {
    // getting data form context
    const {setAllRole} = payload
    // db operation
    CommonAxios.get(getAllRole).then(res => {

        if (res.data.status == true) {
            setAllRole(res.data.data || [])
        }
    }).catch(err => {

    })
}

// add user action
export const addUserAction = payload => dispatch => {
    // getting data form context
    const {
        submitRef,
        setRefreshUser,
        setAddUserModal
    } = payload
    // handle loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(addUser, payload).then(res => {
        // handle loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            // refresh user
            setRefreshUser(prev => !prev)
            // modal close
            setAddUserModal(false)
            // toast
            CommonToast.success("User added successfully!")
        } else {
            // toast
            CommonToast.error("User added failed! try again.")
        }
    }).catch(err => {
        // handle loader
        CommonLoader.btn(submitRef)
    })
}

// update user from admin action
export const updateUserFromAdminAction = payload => dispatch => {
    // getting data form context
    const {
        submitRef,
        setRefreshUser,
        setUpdateUserModal
    } = payload
    // handle loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(updateUserFromAdmin, payload).then(res => {
        // handle loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            // refresh user
            setRefreshUser(prev => !prev)
            // modal close
            setUpdateUserModal(false)
            // toast
            CommonToast.success("User updated successfully!")
        } else {
            // toast
            CommonToast.error("User updated failed! try again.")
        }
    }).catch(err => {
        // handle loader
        CommonLoader.btn(submitRef)
    })
}

// reset user password from admin action
export const resetUserPasswordFromAdminAction = payload => dispatch => {
    // getting data form context
    const {
        submitRef,
        setPasswordResetModal
    } = payload
    // handle loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(resetUserPasswordFromAdmin, payload).then(res => {
        // handle loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            // modal close
            setPasswordResetModal(false)
            // toast
            CommonToast.success("password reset successfully.")
        } else {
            // toast
            CommonToast.error("password reset failed.")
        }
    }).catch(err => {
        // handle loader
        CommonLoader.btn(submitRef)
    })
}