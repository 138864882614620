import React, {useContext} from 'react';
import {NoticeContext} from "../NoticeComponent";
import {useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiDeleteBin6Line} from "@react-icons/all-files/ri/RiDeleteBin6Line";

function NoticeArea(props) {
    // data getting from store
    const allNotice = useSelector(state => state.noticeSlice.allNotice)


    //Context
    const {
        allNoticeReload,
        setAllNoticeReload,
        noticeAddModelShow,
        setNoticeAddModelShow,
        noticeEditModelShow,
        setNoticeEditModelShow,
        noticeSingleData,
        setNoticeSingleData,
        noticeDeleteModelShow,
        setNoticeDeleteModelShow
    } = useContext(NoticeContext)
    return (
        <>
            <Row>
            {allNotice.map((data,index)=>(
                <Col lg={4} md={4} sm={12} className="notice mt-3">
                    <Card>
                        <Card.Header className="align-items-center d-flex">
                            <h5 className="mb-0 flex-grow-1">{data.title}</h5>
                            <div className="flex-shrink-0">
                                <button title="Edit"
                                        className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                        onClick={e => {
                                            // data for update
                                            setNoticeSingleData(data)
                                            // update modal show
                                            setNoticeEditModelShow(true)
                                        }
                                        }>
                                    <FiEdit/>
                                </button><button title="Del"
                                        className="ms-2 bg-transparent iconActionButton border-0 text-danger btn btn-primary"
                                        onClick={e => {
                                            // data for update
                                            setNoticeSingleData(data)

                                            // Delete Modal Show
                                            setNoticeDeleteModelShow(true)

                                        }
                                        }>
                                    <RiDeleteBin6Line/>
                                </button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <strong>Short Description</strong>
                           <div className="mb-2">{data.short_description}</div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
            </Row>
        </>
    );
}

export default NoticeArea;