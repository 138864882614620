import React, {useContext, useEffect, useRef, useState} from 'react';
import CommonFrontendPaginationTableComponent
    from "../../CommonComponent/CommonFrontendPaginationTable/CommonFrontendPaginationTableComponent";
import FrontendPaginationHandler
    from "../../CommonComponent/CommonFrontendPaginationTable/method/FrontendPaginationHandler";
import {addMarksForSubjectAction} from "../../../Redux/Action/MarksheetAction";
import { GrView } from "react-icons/gr";
import { BsFilePdf } from "react-icons/bs";
import {MarksheetContext} from "../../MarkSheetComponent/MarkSheetComponent";
import Parse from "../../../Utilities/parse";
import GradeIdentifier from "../../../Utilities/GradeIdentifier";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'


function StudentMarkSheetTable({table_data = []}) {

    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId,
        classWiseSteteReloder,
        setClassWiseSteteReloder,
        markSheetViewModel,
        setMarkSheetViewModel,
        singleStudentMarkSheet,
        setSingleStudentMarkSheet,
        studentInfoRef
    } = useContext(MarksheetContext)



    // states
    const [loadingTable, setLoadingTable] = useState(false)
    const [forceToFetchData, setForceToFetchData] = useState(false)
    const [searchKey, setSearchKey] = useState("")
    const [offset, setOffset] = useState(0)
    const [data, setData] = useState([

    ])
    const [filteredData, setFilteredData] = useState([])
    const [itemsPerPage, setItemsPerPage] = useState(10)
    let totalMarks = 0
    let fullMarks = 0
    let gradePoint = Parse.toNumber(0)


    //hook


    // Student Info table setup
    const studentInfoTAbleSetup = (doc) => {
        autoTable(doc, {
            html: studentInfoRef.current,

            theme: "grid",
            headStyles: {fontSize: 18},
            columnStyles: {
                0: {
                    cellWidth: 10
                },
                1: {
                    cellWidth: 40,
                }
            }
        })
    }


    const pdfGenerator= ()=>{
        const doc = jsPDF({
            orientation: "p",
            format: "a4"
        })
        // user detail tble invoke
        studentInfoTAbleSetup(doc)
        // userAddSetup(doc)
        // userBillSetUp(doc)
        doc.output('dataurlnewwindow')


    }



    return (
        <>
            <CommonFrontendPaginationTableComponent
                table_name={"table_name"}
                offset={offset}
                setOffset={setOffset}
                total_data={data.length}
                itemsPerPage={itemsPerPage}
                loadingTable={loadingTable}
                setForceToFetchData={setForceToFetchData}
                searchConfig={{

                    placeholder: "Search key name",
                    searchKey: searchKey,
                    setSearchKey: setSearchKey,
                    propertyForMatch: ["name"],
                    preFilterData: table_data,
                    setFilteredDataState: setFilteredData
                }}
            >
                <thead>
                <tr className="bg-blue">
                    <th>SL</th>
                    <th>Name</th>
                    <th className="text-end">Full Marks</th>
                    <th className="text-end">Total Marks</th>
                    <th className="text-end">Grade Point</th>
                    <th className="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                {FrontendPaginationHandler.getDataForEachPage(filteredData, offset, itemsPerPage).map((d, index) => {
                    totalMarks = 0
                    fullMarks = 0
                    gradePoint = Parse.toNumber(0)

                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{d.stu_a_student_info.student_full_name}</td>
                        <td className="text-end">{d.mark_sheets_for_print.map((subjectDetails, index)=>{
                            fullMarks+= subjectDetails.exam_detail.exam_full_mark
                        })}
                            <p>{fullMarks}</p>
                        </td>
                        <td className="text-end">
                            {d.mark_sheets_for_print.map((SubjectFullMarks, index)=>{
                                totalMarks+=SubjectFullMarks.exam_detail.total_marks
                            })}
                            <p>{totalMarks}</p>
                        </td>
                        <td className="text-end">{d.mark_sheets_for_print.map((subjectDetails, index)=>{
                            gradePoint += Parse.toNumber(subjectDetails.exam_detail.grade_point)

                        })}


                            <p>{GradeIdentifier.getGrade((gradePoint / d.mark_sheets_for_print.length).toFixed(2))}</p>
                        </td>
                        <td className="text-center">
                            <button title="Mark Sheet View"
                                    className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        setMarkSheetViewModel(true)
                                        setSingleStudentMarkSheet(d)


                                    }
                                    }>
                                <GrView />
                            </button>

                            <button title="Mark Sheet PDF"
                                    className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        setSingleStudentMarkSheet(d)


                                    }
                                    }>
                                <BsFilePdf />
                            </button>
                        </td>
                    </tr>
                })}
                </tbody>
            </CommonFrontendPaginationTableComponent>
        </>
    );
}

export default StudentMarkSheetTable;