import React, {useContext} from 'react';
import {Card, Table} from "react-bootstrap";
import DateFormat from "../../../Utilities/DateFormat";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import PreviousSchoolInformationEditForm from "./PreviousSchoolInformationEditForm";
import BankInfoEditForm from "./BankInfoEditForm";

function StudentBankInformationTable({data = {}}) {
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        bankInfoEditModel,
        setBankInfoEditModel
    }=useContext(StudentSingleDateContext)
    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Bank information</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setBankInfoEditModel(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <ul>
                                <li><strong>Bank Name :</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.bank_name || "N/A"}</span></li>
                                <li><strong>Bank Name:</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.account_name || "N/A"}</span></li>
                                <li><strong>Account Number:</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.account_number || "N/A"}</span></li>
                                <li><strong>Branch name:</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.branch_name || "N/A"}</span></li>
                                <li><strong>IFSC code:</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.ifsc_code || "N/A"}</span></li>
                                <li><strong>Account type:</strong><span>{data.stu_a_student_info?.stu_g_student_bank_info?.account_type || "N/A"}</span></li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/*Previous School Information Edit modal show*/}
            <CommonModal
                show={bankInfoEditModel}
                setShow={setBankInfoEditModel}
                title={"Edit Bank Information"}
                size="lg"
            >

                <BankInfoEditForm infoData={data.stu_a_student_info} />
            </CommonModal>

        </>
    );
}

export default StudentBankInformationTable;