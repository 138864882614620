import React, {useContext, useRef} from 'react';
import {Button} from "react-bootstrap";
import {TiTick} from "@react-icons/all-files/ti/TiTick";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {TiDelete} from "@react-icons/all-files/ti/TiDelete";
import {EventContext} from "../EventComponent";
import {useDispatch} from "react-redux";
import {DeleteEventAction} from "../../../Redux/Action/EventAction";

function DeleteEvent(props) {
    const btnRef = useRef()
    const dispatch = useDispatch()

    const {
        allEventReload,
        setAllEventReload,
        eventAddModelShow,
        setEventAddModelShow,
        eventEditModelShow,
        setEventEditModelShow,
        eventDeleteModelShow,
        setEventDeleteModelShow,
        eventSingleData,
        setEventSingleData
    }= useContext(EventContext)

    // delete on click function
    const deleteClick = () =>{
        const eventId = eventSingleData.id

        const Payload ={
            data:{
                event_id :eventId
            },
            btnRef,
            setEventDeleteModelShow,
            setEventSingleData,
            setAllEventReload

        }
        dispatch(DeleteEventAction(Payload))


    }
    return (
        <>
            <div className="text-center">
                <h3 className="mt-3 mb-3">Are You Sure Delete This Event</h3>
                <Button className="mx-2" ref={btnRef} variant="success" onClick={deleteClick}>YES <TiTick/>
                    <ButtonLoader/>
                </Button>
                <Button
                    variant="danger"
                    onClick={e =>{
                        setEventDeleteModelShow(false)
                    }}>NO <TiDelete/></Button>
            </div>
        </>
    );
}

export default DeleteEvent;