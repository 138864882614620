import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import {useDispatch} from "react-redux";
import EventArea from "./EventSubComponent/EventArea";
import {getAllEventAction} from "../../Redux/Action/EventAction";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import NoticeForm from "../NoticeComponent/NoticeSubComponent/NoticeForm";
import {NoticeContext} from "../NoticeComponent/NoticeComponent";
import EventForm from "./EventSubComponent/EventForm";
import DeleteEvent from "./EventSubComponent/DeleteEvent";
import EventImageEditForm from "./EventSubComponent/EventImageEditForm";

export const EventContext = createContext()

function EventComponent(props) {
    //ref
    const allEventLoaderRef = useRef()
    const dispatch = useDispatch()
    //state
    const [allEventReload, setAllEventReload] = useState(false)
    const [eventAddModelShow, setEventAddModelShow] = useState(false)
    const [eventEditModelShow, setEventEditModelShow] = useState(false)
    const [eventDeleteModelShow, setEventDeleteModelShow] = useState(false)
    const [eventImageEditModelShow, setEventImageEditModelShow] = useState(false)
    const [eventSingleData, setEventSingleData] = useState({})

    // get all Notice lifecycle
    useEffect(() => {
        const Payload = {
            allEventLoaderRef,
            data: {
                is_cultural_event:"0"
            }

        }
        dispatch(getAllEventAction(Payload))
    }, [allEventReload])

    return (
        <>
            <EventContext.Provider value={{
                allEventReload,
                setAllEventReload,
                eventAddModelShow,
                setEventAddModelShow,
                eventEditModelShow,
                setEventEditModelShow,
                eventDeleteModelShow,
                setEventDeleteModelShow,
                eventSingleData,
                setEventSingleData,
                eventImageEditModelShow,
                setEventImageEditModelShow
            }}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h3>All Festival</h3>
                    {HeaderComponent.refresh([setAllEventReload])}
                    {HeaderComponent.add(setEventAddModelShow) }
                </div>

                <EventArea/>

                {/*section Loader Component*/}
                <SectionLoader loading_handle_ref={allEventLoaderRef}/>

                {/*Event Add Model Show*/}
                <CommonModal
                    show={eventAddModelShow}
                    setShow={setEventAddModelShow}
                    title={"Add Event"}
                    size="xl"
                >
                    <EventForm/>
                </CommonModal>


                {/*Event Edit Model Show*/}
                <CommonModal
                    show={eventEditModelShow}
                    setShow={setEventEditModelShow}
                    title={"Edit Event"}
                    size="xl"
                >
                    <EventForm formType={'Update'} default_data={eventSingleData}/>
                </CommonModal>

                {/*Event Delete Model Show*/}
                <CommonModal
                    show={eventDeleteModelShow}
                    setShow={setEventDeleteModelShow}
                    title={"Delete Event"}
                    size="md"
                >
                    <DeleteEvent/>
                </CommonModal>
                {/*Event Image Edit Model Show*/}
                <CommonModal
                    show={eventImageEditModelShow}
                    setShow={setEventImageEditModelShow}
                    title={"Image Update"}
                    size="md"
                >
                    <EventImageEditForm/>
                </CommonModal>


            </EventContext.Provider>
        </>
    );
}

export default EventComponent;