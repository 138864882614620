import {createSlice} from "@reduxjs/toolkit";

export const ConfigurationSlice = createSlice({
    name: "classSlice",
    initialState: {
        allClass: [],
        allServiceCharge: [],
        AllSection: [],
        allShift: [],
        allStudentType: []
    },
    reducers: {
        getAllClassReducers: (state, action) => {
            state.allClass = action.payload
        },
        getAllServiceChargeReducers: (state, action) => {
            state.allServiceCharge = action.payload
        },
        getAllSectionReducers: (state, action) => {
            state.AllSection = action.payload
        },
        getAllShiftReducers: (state, action) => {
            state.allShift = action.payload
        },
        getAllStudentTypeReducers: (state, action) => {
            state.allStudentType = action.payload
        }
    }
})