import React from 'react';
import EventComponent from "../Component/EventComponent/EventComponent";

function CulturalEventPage(props) {
    return (
        <>
            <EventComponent/>
        </>
    );
}

export default CulturalEventPage;