import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {updatePreviousSchoolInfoAction} from "../../../Redux/Action/StudentAction";

function PreviousSchoolInformationEditForm({infoData={}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        guardianInformationEditModel,
        setGuardianInformationEditModel,
        previousSchoolInformationEditModel,
        setPreviousSchoolInformationEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData.stu_d_student_previous_school_info).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData.stu_d_student_previous_school_info[fieldProp])
            })
        }
    }, [infoData])

    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                student_info_id: singleStudentDetail.student_info_id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setPreviousSchoolInformationEditModel

        }
        dispatch(updatePreviousSchoolInfoAction(payload))

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Prev school Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("prev_school_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Medium </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("medium", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Academic Year </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_academic_year", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Class </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_class", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Department </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_department", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>


                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Class Segment </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_class_segment", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Section </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_section", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Roll </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_roll", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Reg </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_reg", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Board </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_board", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passed Grade Point </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("passed_grade_point", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>


                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default PreviousSchoolInformationEditForm;