import React, {useContext, useState} from 'react';
import {Card, Table} from "react-bootstrap";
import DateFormat from "../../../Utilities/DateFormat";
import {Link} from "react-router-dom";
import {BiEdit} from "@react-icons/all-files/bi/BiEdit";
import DirectorForm from "../../DirectorComponent/DirectorSubComponent/DirectorForm";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiDeleteBin6Line} from "@react-icons/all-files/ri/RiDeleteBin6Line";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import ClassForm from "../../ClassComponent/ClassSubComponent/ClassForm";
import StudentBasicInformationUpdateForm from "./StudentBasicInformationUpdateForm";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import imageBlank from '../../../assets/images/image-blank.jpg'
import BirthCertificateImageChange from "./birthCertificateImageChange";
import AdhaarCardImageChange from "./AdhaarCardImageChange";

function StudentBasicInformationTable({data = {}}) {
    // context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        birthCertificateEditModel,
        setBirthCertificateEditModel,
        aadhaarCardEditModel,
        setAadhaarCardEditModel
    }= useContext(StudentSingleDateContext)
    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Student's basic information -</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setEditModelShow(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <ul>
                                <li><strong>Full name:</strong><span>{data.stu_a_student_info?.student_full_name}</span></li>
                                <li><strong>Gender:</strong><span>{data.stu_a_student_info?.gender}</span></li>
                                <li><strong>DOB:</strong><span>{DateFormat.format(data.stu_a_student_info?.DOB)}</span></li>
                                <li><strong>Nationality:</strong><span>{data.stu_a_student_info?.nationality || "N/A"}</span></li>
                                <li><strong>Religion:</strong><span>{data.stu_a_student_info?.religion || "N/A"}</span></li>
                                <li><strong>Social category:</strong><span>{data.stu_a_student_info?.social_category || "N/A"}</span></li>
                                <li><strong>Mother tongue:</strong><span>{data.stu_a_student_info?.mother_tongue || "N/A"}</span></li>
                                <li><strong>Blood group:</strong><span>{data.stu_a_student_info?.blood_group || "N/A"}</span></li>
                                <li><strong>Contact number:</strong><span>{data.stu_a_student_info?.contact_number || "N/A"}</span></li>
                                <li><strong>Student id:</strong><span>{data?.stu_a_student_info?.id}</span></li>
                                <li><strong>Security key:</strong><span>{data.stu_a_student_info?.security_key || "N/A"}</span></li>
                                <li><strong>Birth Reg. no. </strong>{data.stu_a_student_info?.birth_reg_no || "N/A"} </li>
                                <li><strong>Adhaar card no. </strong>{data.stu_a_student_info?.adhaar_card_no || "N/A"} </li>
                                <li><strong style={{width: "100%"}}>Birth Reg. image & Adhaar card image :</strong> <br/> <div className="documentImg">{data.stu_a_student_info?.birth_certificate_image != "" ?
                                    <a target={"_blank"} href={data.stu_a_student_info?.birth_certificate_image || "/"}>
                                        <img className="img-fluid" src={data.stu_a_student_info?.birth_certificate_image} />
                                    </a> : <img className="img-fluid" src={imageBlank} style={{width: "150px"}}/>}
                                    <button title="Edit"
                                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                            onClick={e => {
                                                // update modal show
                                                setBirthCertificateEditModel(true)
                                            }
                                            }>
                                        <FiEdit/>
                                    </button>
                                </div>
                                    <div className="documentImg">{data.stu_a_student_info?.adhaar_card_image != "" ?
                                    <a target={"_blank"} href={data.stu_a_student_info?.adhaar_card_image || "/"}>
                                        <img className="img-fluid" src={data.stu_a_student_info?.adhaar_card_image} style={{width: "150px"}}/>
                                    </a>
                                        : <img className="img-fluid" src={imageBlank} style={{width: "150px"}}/>}
                                        <button title="Edit"
                                                className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                                onClick={e => {
                                                    // update modal show
                                                    setAadhaarCardEditModel(true)
                                                }
                                                }>
                                            <FiEdit/>
                                        </button>
                                    </div>   </li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            {/*Edit modal show*/}
            <CommonModal
                show={editModelShow}
                setShow={setEditModelShow}
                title={"Edit Student's basic information"}
                size="lg"
            >
                <StudentBasicInformationUpdateForm infoData={data.stu_a_student_info}/>
            </CommonModal>
            {/*Birth Certificate Edit modal show*/}
            <CommonModal
                show={birthCertificateEditModel}
                setShow={setBirthCertificateEditModel}
                title={"Edit Birth Certificate"}
                size="md"
            >
                <BirthCertificateImageChange infoData={data.stu_a_student_info}/>
            </CommonModal>

            {/*Aadher Card Edit modal show*/}
            <CommonModal
                show={aadhaarCardEditModel}
                setShow={setAadhaarCardEditModel}
                title={"Edit Aadhaar Card"}
                size="md"
            >
                <AdhaarCardImageChange infoData={data.stu_a_student_info}/>
            </CommonModal>

        </>
    );
}

export default StudentBasicInformationTable;