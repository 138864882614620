import React, {createContext, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import {useDispatch} from "react-redux";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import TeacherTableSection from "./teacherSubComponent/TeacherTableSection";
import {getAllTeacherAction} from "../../Redux/Action/TeacherAction";
import TeacherForm from "./teacherSubComponent/TeacherForm";
import SectionForm from "../UtilitiesComponent/UtilitiesSubComponent/SectionForm";
import ImageForm from "./teacherSubComponent/ImageForm";

export const TeacherContext = createContext()

function TeacherComponent(props) {
    //Ref
    const allTeacherTableLoaderRef = useRef()
    const dispatch = useDispatch()

    //state
    const [allTeacherReload, setAllTeacherReload] =useState(false)
    const [teacherAddModelShow, setTeacherAddModelShow] =useState(false)
    const [teacherEditModelShow, setTeacherEditModelShow] =useState(false)
    const [getTeacherSingleData, setGetTeacherSingleData] =useState({})
    const [addImageModelShow, setAddImageModelShow] =useState(false)



    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allTeacherTableLoaderRef,
        }
        dispatch(getAllTeacherAction(Payload))
    }, [allTeacherReload])
    return (
        <>
            <TeacherContext.Provider value={{
                allTeacherReload,
                setAllTeacherReload,
                teacherAddModelShow,
                setTeacherAddModelShow,
                teacherEditModelShow,
                setTeacherEditModelShow,
                getTeacherSingleData,
                setGetTeacherSingleData,
                addImageModelShow,
                setAddImageModelShow
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3>All Teacher</h3>
                            {HeaderComponent.refresh([setAllTeacherReload])}
                            {HeaderComponent.add(setTeacherAddModelShow) }
                        </div>

                        {/*Teacher Table*/}
                        <TeacherTableSection/>

                        {/*section Loader Component*/}
                        <SectionLoader loading_handle_ref={allTeacherTableLoaderRef}/>

                        {/*Teacher Add Model Show*/}
                        <CommonModal
                            show={teacherAddModelShow}
                            setShow={setTeacherAddModelShow}
                            title={"Add Teacher"}
                            size="md"
                        >
                           <TeacherForm/>
                        </CommonModal>

                        {/*Teacher Edit Model Show*/}
                        <CommonModal
                            show={teacherEditModelShow}
                            setShow={setTeacherEditModelShow}
                            title={"Edit Teacher"}
                            size="md"
                        >
                            <TeacherForm formType={'Update'} default_data={getTeacherSingleData}/>
                        </CommonModal>

                        {/*Teacher image Model*/}
                        <CommonModal
                            show={addImageModelShow}
                            setShow={setAddImageModelShow}
                            title={"Teacher Image"}
                            size="md"
                        >
                            <ImageForm default_data={getTeacherSingleData}/>
                        </CommonModal>

                    </Card.Body>
                </Card>
            </TeacherContext.Provider>
        </>
    );
}

export default TeacherComponent;