import React, {useContext, useEffect, useRef} from 'react';
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {updateBankInfoAction, updateGovInfoAction} from "../../../Redux/Action/StudentAction";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";

function GovRegInfoEditForm({infoData={}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        govRegInfoEditModel,
        setGovRegInfoEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData[fieldProp])
            })
        }
    }, [infoData])


    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                sessional_gov_reg_id: infoData.id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setGovRegInfoEditModel
        }
       dispatch(updateGovInfoAction(payload))

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>School Name </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("school_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("class_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Department</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("department_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Segment </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("segment_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Shift </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("shift_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Section </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("section_name", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Student Id (Reg. school) : </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("student_id_from_reg_school", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Kannyashree Id </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("kannyashree_id", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Roll No. </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("roll_no", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Registration No.</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("reg_no", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Board</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("board", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>


                </Row>


                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default GovRegInfoEditForm;