import React, {useContext} from 'react';
import {Badge, Card, Table} from "react-bootstrap";
import DateFormat from "../../../Utilities/DateFormat";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import SessionalInfoEditForm from "./SessionalInfoEditForm";
import {GrFormView} from "@react-icons/all-files/gr/GrFormView";
import StudentImageEditForm from "./StudentImageEditForm";


function SessionalInformationTable({data = {}}) {
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        sessionalInfoEditModel,
        setSessionalInfoEditModel,
        studentImageEditModel,
        setStudentImageEditModel
    }=useContext(StudentSingleDateContext)
    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Sessional Information </h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setSessionalInfoEditModel(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <div>{data.student_image != "" ? <div className="studentImage" style={{width: "200px"}}>
                                <a target={"_blank"} href={data.student_image || "/"}>

                                </a>
                                <img className="img-fluid" src={data.student_image}/>
                                <div>


                                    <button title="Edit"
                                            className="bg-transparent iconActionButton border-0 btn btn-primary"
                                            >
                                         <FiEdit onClick={e => {
                                        // update modal show
                                        setStudentImageEditModel(true)
                                    }
                                    }/>
                                    </button>
                                </div>
                            </div> : "N/A"}</div>
                            <ul>
                                <li><strong>Admission date</strong><span>{DateFormat.format(data.admission_date)} </span></li>
                                <li><strong>Academic year</strong><span>{data.academic_year || "N/A"}</span></li>
                                <li><strong>Class</strong><span>{data.cnfg_class_name?.class_name || "N/A"}</span></li>
                                <li><strong>Department</strong><span>{data.cnfg_class_wise_department?.department_name || "N/A"}</span></li>
                                <li><strong>Segment</strong><span>{data.cnfg_class_segment?.segment_name || "N/A"}</span></li>
                                <li><strong>Shift</strong><span>{data.cnfg_shift?.shift_name || "N/A"}</span></li>
                                <li><strong>Section</strong><span>{data.cnfg_student_section?.section_name || "N/A"}</span></li>
                                <li><strong>Roll</strong><span>{data.roll || "N/A"}</span></li>
                                <li><strong>Student type</strong><span>{data.cnfg_student_type?.type_name || "N/A"}</span></li>
                                <li><strong>Session status</strong><span>{data.session_status == 0 ? <Badge bg="primary">
                                    Session running
                                </Badge> : data.session_status == 1 ? <Badge bg="success">
                                    Session over by passed
                                </Badge> : <Badge bg="danger">
                                    Sesson over by failed
                                </Badge>}</span></li>
                                <li><strong>Session over date</strong><span>{data.session_over_date == null ? "N/A" : DateFormat.format(data.session_over_date)}</span></li>

                            </ul>
                        </div>
                        <div className="clearfix"></div>

                    </Card.Body>
                </Card>
            </div>
            <CommonModal
                show={sessionalInfoEditModel}
                setShow={setSessionalInfoEditModel}
                title={"Edit Sessional Information "}
                size="lg"
            >
              <SessionalInfoEditForm infoData={data}/>
            </CommonModal>
            {/* student image edit model*/}
            <CommonModal
                show={studentImageEditModel}
                setShow={setStudentImageEditModel}
                title={"Edit Student Image "}
                size="md"
            >
                <StudentImageEditForm infoData={data}/>
            </CommonModal>


        </>
    );
}

export default SessionalInformationTable;