import CommonAxios from "../../Utilities/CommonAxios";
import {
    addClass, classSegmentDelete,
    configureClassSegment,
    configureDepartment,
    departmentDelete,
    getAllClass,
    updateClass
} from "../../AllRoutes/ApiRoute";
import {ConfigurationSlice} from "../Slice/ConfigurationSlice";
import CommonLoader from "../../Utilities/CommonLoader";
import CommonToast from "../../Utilities/CommonToast";


// Get All Class
export const getAllClassAction = payload => dispatch => {
    // getting data from payload
    const {allClassTableLoaderRef} = payload
    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allClassTableLoaderRef)
        } catch (e) {

        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.get(getAllClass).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(ConfigurationSlice.actions.getAllClassReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })
}


// add Class action
export const addClassAction = payload => dispatch => {
    const {
        btnRef,
        setClassAddModelShow,
        setAllClassReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addClass, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setClassAddModelShow(false)
            //all Agent table reload
            setAllClassReload(prev => !prev)
            // toast
            CommonToast.success("Agent Create Successfully!")
        } else {
            CommonToast.error("Agent Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Update Class action
export const updateClassAction = payload => dispatch => {
    const {
        btnRef,
        setClassEditModelShow,
        setAllClassReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateClass, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setClassEditModelShow(false)
            //all Agent table reload
            setAllClassReload(prev => !prev)
            // toast
            CommonToast.success("Agent updated Successfully!")
        } else {
            CommonToast.warning("Agent updated failed! " + res.data?.error_message)
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}
// configure department action
export const configureDepartmentAction = payload => dispatch => {
    // getting data form payload
    const {
        action_type,
        submitRef,
        reset,
        setAllClassReload
    } = payload
    // button loader handle
    CommonLoader.btn(submitRef)
    // db operaiton
    CommonAxios.post(configureDepartment, payload).then(res => {
        // button loader handle
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            // refresh all class
            setAllClassReload(prev => !prev)
            // form reset when action type add
            action_type == "add" && reset()
            // toast
            CommonToast.success("Department configured.")
        } else {
            // toast
            CommonToast.error("Department configured failed.")
        }
    }).catch(err => {
        // button loader handle
        CommonLoader.btn(submitRef)
    })
}

// delete department action
export const deleteDepartmentAction = payload => dispatch => {
    // getting data form payload
    const {
        deleteButtonRef,
        setAllClassReload,
    } = payload
    // delete loader handle
    CommonLoader.btn(deleteButtonRef)
    // db operation
    CommonAxios.post(departmentDelete, payload).then(res => {
        // delete loader handle
        CommonLoader.btn(deleteButtonRef)
        if (res.data.status == true) {
            // reload all class
            setAllClassReload(prev => !prev)
            // toast
            CommonToast.success("Department deleted.")
        } else {
            // toast
            CommonToast.error("Department deleted failed.")
        }
    }).catch(err => {
        // delete loader handle
        CommonLoader.btn(deleteButtonRef)
    })
}

// configure segment action
export const configureClassSegmentAction = payload => dispatch => {
    // getting data form payload
    const {
        action_type,
        submitRef,
        reset,
        setAllClassReload
    } = payload
    // handle button loader
    CommonLoader.btn(submitRef)
    // db operation
    CommonAxios.post(configureClassSegment, payload).then(res => {
        // handle button loader
        CommonLoader.btn(submitRef)
        if (res.data.status == true) {
            // reload all class
            setAllClassReload(prev => !prev)
            // reset form when action type add
            action_type == "add" && reset()
            // toast
            CommonToast.success("Segment configured.")
        } else {
            // toast
            CommonToast.error("Segment configured failed.")
        }
    }).catch(err => {
        // handle button loader
        CommonLoader.btn(submitRef)
    })
}

// delete segment action
export const deleteSegmentAction = payload => dispatch => {
    // getting data form payload
    const {
        deleteButtonRef,
        setAllClassReload,
    } = payload
    // delete loader handle
    CommonLoader.btn(deleteButtonRef)
    // db operation
    CommonAxios.post(classSegmentDelete, payload).then(res => {
        // delete loader handle
        CommonLoader.btn(deleteButtonRef)
        if (res.data.status == true) {
            // reload all class
            setAllClassReload(prev => !prev)
            // toast
            CommonToast.success("Segment deleted.")
        } else {
            // toast
            CommonToast.error("Segment deleted failed.")
        }
    }).catch(err => {
        // delete loader handle
        CommonLoader.btn(deleteButtonRef)
    })
}