import CommonAxios from "../../Utilities/CommonAxios";
import CommonToast from "../../Utilities/CommonToast";
import {addSection, addSessionalBook, bookRemoveFromSessionalAllStudent} from "../../AllRoutes/ApiRoute";

// add Sessional Book

export const addSessionalBookAction = payload => dispatch =>{
    const {
        bookSubmitRef,
        data,
        reset,
        setSessionalBookModel
    } = payload


    CommonAxios.post(addSessionalBook, payload).then(res => {

        if(res.data.status == true){
            // modal close
            setSessionalBookModel(false)
            // toast
            CommonToast.success("Sessional Book add Successfully!")

        }else {
            // toast
            CommonToast.warning(res?.data?.message || "Sessional Book add failed!")
        }


    }).catch(err=>{
        console.log(err)
    })

}


// Delete Sessional Book

export const deleteSessionalBookAction = payload => dispatch =>{
    const {
        bookSubmitRef,
        data,
        reset,
        setSessionalBookModel
    } = payload


    CommonAxios.post(bookRemoveFromSessionalAllStudent, payload).then(res => {

        if(res.data.status == true){
            // modal close
            setSessionalBookModel(false)
            // toast
            CommonToast.success("Sessional Book Delete Successfully!")

        }else {
            // toast
            CommonToast.error("Sessional Book add failed!")
        }


    }).catch(err=>{
        console.log(err)
    })

}