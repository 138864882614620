import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {addSection, getAllSection, updateSection} from "../../AllRoutes/ApiRoute";
import {ConfigurationSlice} from "../Slice/ConfigurationSlice";
import CommonToast from "../../Utilities/CommonToast";

// Get All Class
export const getAllSectionAction = payload => dispatch => {
    // getting data from payload
    const {allSectionTableLoaderRef} = payload
    // loader handle method
    let loaderHandleMethod = () => {
        try {
            //loader handle
            CommonLoader.section(allSectionTableLoaderRef)
        } catch (e) {

        }
    }

    // invoke loader
    loaderHandleMethod()

    CommonAxios.get(getAllSection).then(res => {
        // invoke loader
        loaderHandleMethod()
        if (res.data.status === true) {
            dispatch(ConfigurationSlice.actions.getAllSectionReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader
        loaderHandleMethod()
    })
}

// add Section action
export const addSectionAction = payload => dispatch => {
    const {
        btnRef,
        setAllSectionReload,
        setSectionAddModelShow
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addSection, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setSectionAddModelShow(false)
            //all Agent table reload
            setAllSectionReload(prev => !prev)
            // toast
            CommonToast.success("Section Create Successfully!")
        } else {
            CommonToast.error("Section Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Update Section action
export const updateSectionAction = payload => dispatch => {
    const {
        btnRef,
        setAllSectionReload,
        setSectionEditModelShow,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateSection, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setSectionEditModelShow(false)
            //all Agent table reload
            setAllSectionReload(prev => !prev)
            // toast
            CommonToast.success("Section updated Successfully!")
        } else {
            CommonToast.warning("Section updated failed! " + res.data?.error_message)
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

