import React, {useContext, useEffect, useRef, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {CommonTableContext} from "../CommonFrontendPaginationTableComponent";
import Parse from "../../../../Utilities/parse";



function CommonPagination(props) {
    // getting data from context
    const {
        setForceToFetchData,
        setOffset,
        offset,
        itemsPerPage,
        total_data,
        loadingTable,
        currentPage,
        setCurrentPage
    } = useContext(CommonTableContext)

    // calculate total pages
    const totalPages = Math.ceil(total_data / itemsPerPage);

    // set active page depends on offset change
    useEffect(() => {
        setCurrentPage(Math.ceil(Parse.toNumber(offset) / (Parse.toNumber(itemsPerPage))) || 0)
    }, [offset])

    // page change on click eg: click page 1, page 2, previous page or next page
    const handlePageClick = (selectedPage) => {
        try {
            // set off set
            setOffset(selectedPage.selected * itemsPerPage)
        } catch (e) {

        }
        // re render the data fetch lifecycle
        setForceToFetchData(prev => !prev)
    };


    return (
        <>
            <div className="d-flex mt-2 justify-content-end">
                <ReactPaginate
                    previousLabel="< Previous"
                    nextLabel="Next >"
                    pageClassName={`page-item ${loadingTable ? "disabled" : ""}`}
                    pageLinkClassName="page-link"
                    previousClassName={`page-item ${loadingTable ? "disabled" : ""}`}
                    previousLinkClassName="page-link"
                    nextClassName={`page-item ${loadingTable ? "disabled" : ""}`}
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}

                    forcePage={currentPage}
                    pageCount={totalPages}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    disabledClassName="disabled"
                    activeClassName="active"
                />
            </div>
        </>

    );
}

export default CommonPagination;