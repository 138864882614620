import React, {useContext, useRef} from 'react';
import {Button} from "react-bootstrap";
import {TiTick} from "@react-icons/all-files/ti/TiTick";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {TiDelete} from "@react-icons/all-files/ti/TiDelete";
import {SessionalExamContext} from "./SessionalExam";
import {DeleteSessionalExamAction} from "../../../Redux/Action/ExamAction";
import {useDispatch} from "react-redux";

function DeleteSessionalExam(props) {
    // hook
    const deleteBtnRef = useRef()
    const dispatch = useDispatch()
    //Context
    const {
        allExamReload,
        setAllExamReload,
        filterData,
        setFilterData,
        filteredExams,
        setFilteredExams,
        sessionalExamSingleData,
        setSessionalExamSingleData,
        deleteSessionalExamModelShow,
        setDeleteSessionalExamModelShow,
        setUpdateFilterData,
    }=useContext(SessionalExamContext)

    // on Click delete function
    const deleteClick = () =>{

        const examId = sessionalExamSingleData.id

        const Payload ={
            data:{
                sessional_exam_id :examId
            },
            deleteBtnRef,
            setDeleteSessionalExamModelShow,
            setAllExamReload,
            setUpdateFilterData


        }
        dispatch(DeleteSessionalExamAction(Payload))


    }


    return (
        <>
            <div className="text-center">
                <h3 className="mt-3 mb-3">Are You Sure Delete This Exam</h3>
                <Button className="mx-2" ref={deleteBtnRef} variant="success" onClick={deleteClick}>YES <TiTick/>
                    <ButtonLoader/>
                </Button>
                <Button
                    variant="danger"
                    onClick={e =>{
                        setDeleteSessionalExamModelShow(false)
                    }}>NO <TiDelete/></Button>
            </div>
        </>
    );
}

export default DeleteSessionalExam;