import {createSlice} from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "userSlice",
    initialState: {
        allUserForTransaction: []
    },
    reducers: {
        getAllUserForTransactionReducer: (state, action) => {
            state.allUserForTransaction = action.payload
        }
    }
})