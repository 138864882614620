import React, {Suspense} from 'react';

const ClassComponent = React.lazy(() => import("../Component/ClassComponent/ClassComponent"))

function ClassPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ClassComponent/>
            </Suspense>
        </>
    );
}

export default ClassPage;