import {createSlice} from "@reduxjs/toolkit";

export const StaffSlice = createSlice({
    name:"staffSlice",
    initialState:{
        allStaff:[]
    },
    reducers:{
        getAllStaffReducers:(state, action)=>{
            state.allStaff = action.payload
        }
    }
})