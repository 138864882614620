import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import CommonDatePicker from "../../CommonComponent/DatePickerComponent/CommonDatePicker";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch, useSelector} from "react-redux";
import {getAllClassAction} from "../../../Redux/Action/ClassAction";
import {getAllShiftAction} from "../../../Redux/Action/ShiftAction";
import {getAllSectionAction} from "../../../Redux/Action/SectionAction";
import {getAllStudentTypeAction} from "../../../Redux/Action/StudentTypeAction";
import {getAllServiceChargeAction} from "../../../Redux/Action/ServiceChargeAction";
import {useForm} from "react-hook-form";
import {updateSessionalInfoAction} from "../../../Redux/Action/StudentAction";
import {ExamContext} from "../ExamComponent";
import {addExamAction, UpdateExamAction} from "../../../Redux/Action/ExamAction";

function ExamForm({default_data={}}) {
    const {
        allExamReload,
        setAllExamReload,
        addExamModelShow,
        setAddExamModelShow,
        editExamModelShow,
        setEditExamModelShow,
        deleteExamModelShow,
        setDeleteExamModelShow,
        examSingleData,
        setExamSingleData
    } = useContext(ExamContext)
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // states
    const [allSegment, setAllSegment] = useState([])



    // getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)
    let AllSection = useSelector(state => state.configurationSlice.AllSection)



    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))
        // get all shift
        dispatch(getAllShiftAction({}))
        // get all section
        dispatch(getAllSectionAction({}))


    }, [])


    // class on change
    const classOnChange = () => {
        let selectedClass = JSON.parse(getValues("class_id") || "{}")

        // set class wise department and segment
        setAllSegment(selectedClass.cnfg_class_segments || [])
        // reset hook form
           }

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();



    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, default_data[fieldProp])
            })
        }
    }, [default_data])



    // console.log(infoData)
    const onSubmit = async data => {

        const payload = {
            data: {
                // for edit and update
                ...data,
                class_id: JSON.parse(data.class_id || "{}")?.id,
                // for update
                exam_id: default_data.id,

            },
            reset,
            btnRef,
            setAllExamReload,
            setAddExamModelShow,
            setEditExamModelShow
        }
       Object.keys(default_data).length === 0 ? dispatch(addExamAction(payload)) : dispatch(UpdateExamAction(payload))


    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(default_data).length === 0 ? <><Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class <span>*</span></Form.Label>
                            <Form.Select
                                {...register("class_id", {
                                    required: true,
                                    onChange: event => classOnChange()
                                })}
                            >
                                <option value="">Select...</option>
                                {allClass.map((singleClass, key) => (
                                    <option
                                            value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Segment / Year <span>*</span></Form.Label>
                            <Form.Select
                                {...register("segment_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allSegment.map((singleSegment, key) => (
                                    <option

                                        value={singleSegment.id}>{singleSegment.segment_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                        </Form.Group>
                    </Col>
                </Row></> : <></>}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Exam Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Exam Name"
                                {...register("exam_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.exam_name && <span className="text-danger">
                        {errors.exam_name.type === "required" && "Please Give Exam Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                </Row>


                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default ExamForm;