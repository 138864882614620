import React, {Suspense} from 'react';

const UserSettingComponent = React.lazy(() => import('../Component/UserSetting/UserSettingComponent'));

function UserSettingPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <UserSettingComponent/>
            </Suspense>
        </>
    );
}

export default UserSettingPage;