import React from 'react';
import AllMarkSheetComponent from "../Component/AllMarkSheetComponent/AllMarkSheetComponent";

function AllMarkSheetPage(props) {
    return (
        <>
           <AllMarkSheetComponent/>
        </>
    );
}

export default AllMarkSheetPage;