import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import ReactQuill from "react-quill";
import {UpdateAboutAction} from "../../../Redux/Action/AboutAction";

function AisjForm(props) {

    const btnRef = useRef()
    const allEventLoaderRef = useRef()
    const dispatch = useDispatch()

    // data getting from store
    const aisjContent = useSelector(state => state.aboutSlice.aisj)

    const [editorData, setEditorData] = useState("")

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(aisjContent).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, aisjContent[fieldProp])
            })
        }

        // set editor's data'
        setEditorData(aisjContent?.content || "")
    }, [aisjContent])

    const onSubmit = async data => {

        const payload = {
            data: {...data,
                content: editorData,
                about_id:aisjContent.id},
                reset,
                btnRef
        }
         dispatch(UpdateAboutAction(payload))

    }

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>AISJ Title</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Event Title"
                                {...register("title", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.title && <span className=" text-danger">
                        {errors.title.type === "required" && "Please Give Event Title"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>AISJ Content</strong></Form.Label>

                            <ReactQuill
                                theme="snow"
                                value={editorData}
                                style={{height: "250px"}}
                                className="clearfix"
                                onChange={(content, delta, source, editor) => {
                                    setEditorData(content || '')

                                }}

                            />
                        </Form.Group>
                        <div style={{height: "50px"}}></div>
                    </Col>
                </Row>
                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Submit
                    <ButtonLoader/>
                </Button>
            </form>
        </>
    );
}
export default AisjForm;