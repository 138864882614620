import React, {useContext, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {SectionContext} from "./SectionComponent";
import {addSectionAction, updateSectionAction} from "../../../Redux/Action/SectionAction";

function SectionForm({default_data = {}}) {
    // ref
    const btnRef = useRef()

    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        allSectionReload,
        setAllSectionReload,
        sectionAddModelShow,
        setSectionAddModelShow,
        sectionEditModelShow,
        setSectionEditModelShow,
        getSectionSingleData,
        setGetSectionSingleData
    } = useContext(SectionContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    // default data set when update
    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, default_data[fieldProp])
            })
        }

    }, [default_data])

    const onSubmit = async data => {
        // making payload for action
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                section_id: default_data.id
            },
            reset,
            btnRef,
            setAllSectionReload,
            setSectionAddModelShow,
            setSectionEditModelShow
        }

        // action dispatch by checking condition. ex: add and update
        Object.keys(default_data).length == 0 ? dispatch(addSectionAction(payload)) : dispatch(updateSectionAction(payload))

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Section Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Section Name"
                                {...register("section_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.section_name && <span className="text-danger">
                        {errors.section_name.type === "required" && "Please Give Section Name"}
                    </span>}
                        </Form.Group>
                    </Col>

                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default SectionForm;