import React, {useContext, useEffect, useRef, useState} from 'react';
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {updateBankInfoAction, updateSessionalInfoAction} from "../../../Redux/Action/StudentAction";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import {getAllClassAction} from "../../../Redux/Action/ClassAction";
import {getAllShiftAction} from "../../../Redux/Action/ShiftAction";
import {getAllSectionAction} from "../../../Redux/Action/SectionAction";
import {getAllStudentTypeAction} from "../../../Redux/Action/StudentTypeAction";
import {getAllServiceChargeAction} from "../../../Redux/Action/ServiceChargeAction";
import CommonDatePicker from "../../CommonComponent/DatePickerComponent/CommonDatePicker";

function SessionalInfoEditForm({infoData = {}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        sessionalInfoEditModel,
        setSessionalInfoEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // states
    const [allDepartment, setAllDepartment] = useState([])
    const [allSegment, setAllSegment] = useState([])
    const [admissionDate, setAdmissionDate] = useState(null)


    // getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)
    let allShift = useSelector(state => state.configurationSlice.allShift)
    let AllSection = useSelector(state => state.configurationSlice.AllSection)
    let allStudentType = useSelector(state => state.configurationSlice.allStudentType)


    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))
        // get all shift
        dispatch(getAllShiftAction({}))
        // get all section
        dispatch(getAllSectionAction({}))
        // get all student type
        dispatch(getAllStudentTypeAction({}))
        // get all services
        dispatch(getAllServiceChargeAction({}))
    }, [])


    // class on change
    const classOnChange = () => {
        let selectedClass = JSON.parse(getValues("class_id") || "{}")

        // set class wise department and segment
        setAllDepartment(selectedClass.cnfg_class_wise_departments || [])
        setAllSegment(selectedClass.cnfg_class_segments || [])
        // reset hook form
        setValue("department_id", "")
        setValue("class_segment_id", "")
    }

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();


    // default dropdown setup
    useEffect(() => {
        if (Object.keys(infoData).length != 0) {
            let selectedClass = allClass.find(element => element?.id == infoData?.cnfg_class_name?.id)
            // console.log(selectedClass)
            setAllDepartment(selectedClass?.cnfg_class_wise_departments || [])
            setAllSegment(selectedClass?.cnfg_class_segments || [])

            setValue("class_id", JSON.stringify(selectedClass))
            setValue("department_id", infoData?.cnfg_class_wise_department?.id || "")
            setValue("class_segment_id", infoData?.cnfg_class_segment?.id || "")
            setValue("academic_year", infoData?.academic_year || "")
            setValue("shift_id", infoData?.cnfg_shift?.id)
            setValue("section_id", infoData?.cnfg_student_section?.id)
            setValue("student_type_id", infoData?.cnfg_student_type?.id)
            setValue("session_type", infoData?.cnfg_student_section?.id)


        }
    }, [allClass])

    // default text field value setup
    useEffect(() => {
        if (Object.keys(infoData).length != 0) {
            setAdmissionDate(new Date(infoData?.admission_date))
            setValue("roll", infoData?.roll)
        }
    }, [infoData])


    // console.log(infoData)
    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                sessional_info_id: singleStudentDetail.id,
                class_id: JSON.parse(data.class_id || "{}")?.id,
                admission_date: admissionDate
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setSessionalInfoEditModel
        }
         dispatch(updateSessionalInfoAction(payload))



    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {CommonDatePicker.dateField(admissionDate, setAdmissionDate, "Admission Date")}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Academic Year <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.academic_year ? "is-invalid" : ""}`}
                                {...register("academic_year", {
                                    required: true
                                })}
                            >
                                <option value="">Select Academic Year</option>
                                {ApplicationUtilities.years().map((value, key) => (
                                    <option selected={infoData?.academic_year == value ? true : false} value={value}
                                            key={key}>{value}</option>
                                ))}

                            </Form.Select>
                            {/*validation message*/}
                            {errors.academic_year && <span className="text-danger">
                                {errors.academic_year.type == "required" && "Please Give Academic Year."}
                                {errors.academic_year.type == "pattern" && "Please Provide A Valid Number."}
                                {errors.academic_year.type == "minLength" && "Please Provide Full Year."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class <span>*</span></Form.Label>
                            <Form.Select
                                {...register("class_id", {
                                    required: true,
                                    onChange: event => classOnChange()
                                })}
                            >
                                <option value="">Select...</option>
                                {allClass.map((singleClass, key) => (
                                    <option selected={singleClass.id == infoData?.cnfg_class_name?.id ? true : false}
                                            value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Department <span>*</span></Form.Label>
                            <Form.Select
                                {...register("department_id", {
                                    required: true
                                })}
                            >
                                <option value="">Select...</option>
                                {allDepartment.map((singleDepartment, key) => (
                                    <option
                                        selected={singleDepartment.id == infoData?.cnfg_class_wise_department?.id ? true : false}
                                        value={singleDepartment.id}>{singleDepartment.department_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.department_id && <span className="text-danger">
                                {errors.department_id.type == "required" && "Please Select A Department."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Segment / Year <span>*</span></Form.Label>
                            <Form.Select
                                {...register("class_segment_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allSegment.map((singleSegment, key) => (
                                    <option
                                        selected={singleSegment.id == infoData?.cnfg_class_segment?.id ? true : false}
                                        value={singleSegment.id}>{singleSegment.segment_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Shift <span>*</span></Form.Label>
                            <Form.Select
                                {...register("shift_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allShift.map((singleShift, key) => (
                                    <option selected={singleShift.id == infoData?.cnfg_shift?.id ? true : false}
                                            value={singleShift.id}>{singleShift.shift_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.shift_id && <span className="text-danger">
                                {errors.shift_id.type == "required" && "Please Select A Shift."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Section <span>*</span></Form.Label>
                            <Form.Select
                                {...register("section_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {AllSection.map((singleSection, key) => (
                                    <option selected={singleSection.id == infoData?.cnfg_student_section?.id ? true : false} value={singleSection.id}>{singleSection.section_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.section_id && <span className="text-danger">
                                {errors.section_id.type == "required" && "Please Select A Section."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Roll No. <span>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                {...register("roll", {
                                    required: true
                                })}
                            />
                            {/* validation message*/}
                            {errors.student_full_name && <span className="text-danger">
                        {errors.student_full_name.type === "required" && "Please Give Student Roll No."}
                    </span>}
                        </Form.Group>
                    </Col>


                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Student Type <span>*</span></Form.Label>
                            <Form.Select
                                {...register("student_type_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allStudentType.map((singleType, key) => (
                                    <option selected={singleType.id == infoData?.cnfg_student_type?.id ? true : false} value={singleType.id}>{singleType.type_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.student_type_id && <span className="text-danger">
                                {errors.student_type_id.type == "required" && "Please Select A Student Type."}
                            </span>}
                        </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Session Type <span>*</span></Form.Label>
                            <Form.Select
                                {...register("session_type", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {ApplicationUtilities.sessionTypes().map((singleSessionType, key) => (
                                    <option selected={singleSessionType.id == infoData?.cnfg_student_section?.id ? true : false} value={singleSessionType.id}>{singleSessionType.value}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.session_type && <span className="text-danger">
                                {errors.session_type.type == "required" && "Please Select A Session Type."}
                            </span>}
                        </Form.Group>
                    </Col>
                </Row>


                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default SessionalInfoEditForm;