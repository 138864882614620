import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import AisjForm from "../AisjComponent/AisjSubComponent/AisjForm";
import FounderForm from "./FounderSubComponent/FounderForm";
import {useDispatch} from "react-redux";
import {getFounderAboutAction} from "../../Redux/Action/AboutAction";

function FounderComponent(props) {
    const dispatch = useDispatch()
    // get all Notice lifecycle
    useEffect(() => {
        dispatch(getFounderAboutAction())
    }, [])
    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <h3>Founder</h3>
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            <Card>
                                <Card.Body>
                                    <FounderForm/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default FounderComponent;