import Parse from "../../../Utilities/parse";
import GradeIdentifier from "../../../Utilities/GradeIdentifier";

class MarkSheetUtility {
    static calcGpa(studentPreFilterDataSetState, written_marks = 0, viva_marks = 0, row_data = {}) {
        studentPreFilterDataSetState(prevState => {
            let modifiedData = [...prevState]
            let index = 0
            for (let singleData of modifiedData) {
                if (singleData?.id == row_data?.id) {
                    modifiedData[index].book_detail.this_exam_result.written_marks = Parse.toNumber(written_marks)
                    modifiedData[index].book_detail.this_exam_result.viva_marks = Parse.toNumber(viva_marks)
                    modifiedData[index].book_detail.this_exam_result.total_marks = Parse.toNumber(written_marks) + Parse.toNumber(viva_marks)
                    modifiedData[index].book_detail.this_exam_result.grade_point = GradeIdentifier.getGradePoint(Parse.toNumber(written_marks) + Parse.toNumber(viva_marks))
                    break;
                }
                index++
            }
            return modifiedData
        })
    }
}

export default MarkSheetUtility