import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {addMarks, getClassWiseStudentsWithMarkSheet, getSessionalExams, getSubjects} from "../../AllRoutes/ApiRoute";

// get Class Wise Students With MarkSheet Action

export const getClassWiseStudentsWithMarkSheetAction = payload => dispatch => {
    const {
        data,
        reset,
        btnRef,
        setClassWiseStudent,

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    CommonAxios.post(getClassWiseStudentsWithMarkSheet, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            //Student data set
            setClassWiseStudent(res.data.data)
        } else {

        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })


}


// get Sessional Exams List for Markseet  Action

export const getSessionalExamsForMarksheetAction = payload => dispatch => {
    const {
        data,
        reset,
        btnRef,
        setClassWiseStudent,
        setExamListForMarksheet
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(getSessionalExams, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)
        // modal close
        setExamListForMarksheet(res?.data?.data || [])


    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// get Sessional Subject List for Markseet  Action

export const getSessionalSubjectForMarksheetAction = payload => dispatch => {
    // getting data form payload
    const {
        data,
        reset,
        btnRef,
        setSubjectListForMarksheet
    } = payload

    // db operation
    CommonAxios.post(getSubjects, payload).then(res => {
       
        // modal close
        setSubjectListForMarksheet(res?.data?.data || [])


    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })



}

// Add Marks for Subject Action

export const addMarksForSubjectAction = payload => dispatch => {
    // getting data form payload
    const {
        data,
        setClassWiseSteteReloder

    } = payload


    // db operation
    CommonAxios.post(addMarks, payload).then(res => {
       if( res.data.status = true){
           setClassWiseSteteReloder(true)
       }

    }).catch(err => {
        console.log(err)

    })

}