import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {addServiceForCharge, getAllServiceForCharge, updateService} from "../../AllRoutes/ApiRoute";
import {ConfigurationSlice} from "../Slice/ConfigurationSlice";
import CommonToast from "../../Utilities/CommonToast";


// Get All Class
export const getAllServiceChargeAction = payload => dispatch => {
    // getting data from payload
    const {allServiceChargeTableLoaderRef} = payload
    // loader handle method
    const loaderHandleMethod = () => {
        try {
            //loader handle
            CommonLoader.section(allServiceChargeTableLoaderRef)
        } catch (e) {

        }
    }

    // invoke loader handle method
    loaderHandleMethod()

    CommonAxios.get(getAllServiceForCharge).then(res => {
        // invoke loader handle method
        loaderHandleMethod()
        if (res.data.status === true) {
            dispatch(ConfigurationSlice.actions.getAllServiceChargeReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandleMethod()
    })
}


// add Service Charge action
export const addServiceChargeAction = payload => dispatch => {
    const {
        btnRef,
        setAllServiceChargeReload,
        setServiceChargeAddModelShow


    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addServiceForCharge, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setServiceChargeAddModelShow(false)
            //all Agent table reload
            setAllServiceChargeReload(prev => !prev)
            // toast
            CommonToast.success("Service Charge Create Successfully!")
        } else {
            CommonToast.error("Service Charge Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Update Service Charge action
export const updateServiceChargeAction = payload => dispatch => {
    const {
        btnRef,
        setAllServiceChargeReload,
        setServiceChargeEditModelShow,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateService, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setServiceChargeEditModelShow(false)
            //all Agent table reload
            setAllServiceChargeReload(prev => !prev)
            // toast
            CommonToast.success("Service updated Successfully!")
        } else {
            CommonToast.warning("Service updated failed! " + res.data?.error_message)
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}