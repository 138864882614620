import {createSlice} from "@reduxjs/toolkit";

export const ExamSlice = createSlice({
    name:"examSlice",
    initialState : {
        allExam:[]
    },
    reducers:{
        getAllExamReducers :(state,action)=>{
            state.allExam = action.payload
        }
    }
})