import React, {useContext, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {ExamContext} from "../ExamComponent";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import {SessionalExamContext} from "./SessionalExam";
import {classWiseStudentAction} from "../../../Redux/Action/StudentAction";
import {getSessionalExamsAction} from "../../../Redux/Action/ExamAction";

function SessionalExamFilter(props) {
    // hooks
    const sessionalExamFilterBtnRef = useRef()
    const sessionalBtnRef = useRef()
    const dispatch = useDispatch()
    const departmentRef = useRef()
    const segmentRef = useRef()

    const {
        allExamReload,
        setAllExamReload,
        filterData,
        setFilterData,
        filteredExams,
        setFilteredExams
    }=useContext(SessionalExamContext)





    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states

    const [allSegment, setAllSegment] = useState([])

    // getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)

    // const {
    //
    // } = useContext(ExamContext)
    // getting data from store
    // const allExamList = useSelector(state => state.examSlice.allExam)
    //

    // class on change
    const classOnChange = () => {
        const selectedClass = JSON.parse(getValues("class_id") || "{}")
        // // set class wise segment
         setAllSegment(selectedClass.cnfg_class_segments || [])


    }
    // const sessionalExamFilter = ()=>{
    //    let data = {...getValues}
    //     // modifying data
    //     let updatedData = {
    //         ...data,
    //         class_id: JSON.parse(data.class_id || "{}").id
    //
    //     }
    //     const payload = {
    //         data,
    //         reset,
    //         sessionalExamFilterBtnRef,
    //         setFilterData,
    //         setAllExamReload
    //     }
    //     payload.data = updatedData
    //     dispatch(getSessionalExamsAction(payload))
    // }

    // form submit
    const onSubmit = async data => {
       
        // modifying data
        let updatedData = {
            ...data,
            class_id: JSON.parse(data.class_id || "{}").id

        }
        const payload = {
            data,
            reset,
            sessionalExamFilterBtnRef,
            setFilterData,
            setAllExamReload
        }
        payload.data = updatedData
        dispatch(getSessionalExamsAction(payload))

    }


    return (
        <>
            <div className="filterArea">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={3} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Academic Year <span>*</span></Form.Label>
                                <Form.Select
                                    className={`${errors.academic_year ? "is-invalid" : ""}`}
                                    {...register("academic_year", {
                                        required: true
                                    })}
                                >
                                    <option value="">Select Academic Year</option>
                                    {ApplicationUtilities.years().map((value, key) => (
                                        <option value={value} key={key}>{value}</option>
                                    ))}
                                </Form.Select>
                                {/*validation message*/}
                                {errors.academic_year && <span className="text-danger">
                                {errors.academic_year.type == "required" && "Please Give Academic Year."}
                                    {errors.academic_year.type == "pattern" && "Please Provide A Valid Number."}
                                    {errors.academic_year.type == "minLength" && "Please Provide Full Year."}
                            </span>}
                            </Form.Group>
                        </Col>

                        <Col md={3} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Class <span>*</span></Form.Label>
                                <Form.Select
                                    {...register("class_id", {
                                        required: true,
                                        onChange: event => classOnChange()
                                    })}
                                >
                                    <option value="">Select...</option>
                                    {allClass.map((singleClass, key) => (
                                        <option value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                    ))}
                                </Form.Select>
                                {/*validation message*/}
                                {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                            </Form.Group>
                        </Col>

                        <Col md={3} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Segment / Year <span>*</span></Form.Label>
                                <Form.Select
                                    id="segmentForClassWiseStudent"
                                    {...register("segment_id", {
                                        required: true,
                                    })}
                                >
                                    <option value="">Select...</option>
                                    {allSegment.map((singleSegment, key) => (
                                        <option value={singleSegment.id}>{singleSegment.segment_name}</option>
                                    ))}
                                </Form.Select>
                                {/*validation message*/}
                                {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                            </Form.Group>
                        </Col>
                        <Col md={3} sm={12}>
                            <Button ref={sessionalExamFilterBtnRef}
                                    type="submit"
                                    className="submit btn-sm cBtn">
                                Submit
                                <ButtonLoader/>
                            </Button>
                        </Col>
                    </Row>

                </form>
            </div>
        </>
    );
}

export default SessionalExamFilter;