import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap";
import HeaderComponent from "../../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import ServiceTable from "./ServiceTable";
import {getAllClassAction} from "../../../Redux/Action/ClassAction";
import {useDispatch} from "react-redux";
import {getAllServiceChargeAction} from "../../../Redux/Action/ServiceChargeAction";
import ServiceChargeForm from "./ServiceChargeForm";
import SectionForm from "./SectionForm";
export const ServiceChargeContext = createContext();
function ServicesChargeComponent(props) {
    //Ref
    const allServiceChargeTableLoaderRef = useRef()
    const dispatch = useDispatch()

    //state
    const [allServiceChargeReload, setAllServiceChargeReload] = useState(false)
    const [serviceChargeAddModelShow, setServiceChargeAddModelShow] = useState(false)
    const [serviceChargeEditModelShow, setServiceChargeEditModelShow] = useState(false)
    const [getServiceChargeSingleData, setGetServiceChargeSingleData] = useState({})


    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allServiceChargeTableLoaderRef,
        }
        dispatch(getAllServiceChargeAction(Payload))
    }, [allServiceChargeReload])


    return (
        <>
            <ServiceChargeContext.Provider value={{
                allServiceChargeReload,
                setAllServiceChargeReload,
                serviceChargeAddModelShow,
                setServiceChargeAddModelShow,
                serviceChargeEditModelShow,
                setServiceChargeEditModelShow,
                getServiceChargeSingleData,
                setGetServiceChargeSingleData
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3 >All Services</h3>
                            {HeaderComponent.refresh([setAllServiceChargeReload])}
                            {HeaderComponent.add(setServiceChargeAddModelShow) }
                        </div>
                        <ServiceTable/>
                        <SectionLoader loading_handle_ref={allServiceChargeTableLoaderRef}/>
                        {/*add modal show*/}
                        <CommonModal
                            show={serviceChargeAddModelShow}
                            setShow={setServiceChargeAddModelShow}
                            title={"Add Service Charge"}
                            size="md"

                        >
                            <ServiceChargeForm/>
                        </CommonModal>
                        {/*Edit modal show*/}
                        <CommonModal
                            show={serviceChargeEditModelShow}
                            setShow={setServiceChargeEditModelShow}
                            title={"Edit Class"}
                            size="md"
                        >
                            <ServiceChargeForm formType={'Update'} default_data={getServiceChargeSingleData}/>
                        </CommonModal>


                    </Card.Body>
                </Card>
            </ServiceChargeContext.Provider>

        </>
    );
}

export default ServicesChargeComponent;