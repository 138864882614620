import React from 'react';
import StaffComponent from "../Component/StaffComponent/StaffComponent";

function OurStaffPage(props) {
    return (
        <>
            <StaffComponent/>
        </>
    );
}

export default OurStaffPage;