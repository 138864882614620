import React, {useEffect, useRef} from 'react';
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import StudentInfoDetails from "./UtillitesComponent/StudentInfoDetails";

function PdfMarkSheetReport({print_data = {}}) {
    //hook
    const studentInfoRef = useRef()


    // Student Info table setup
    const studentInfoTAbleSetup = (doc) => {
        autoTable(doc, {
            html: studentInfoRef.current,

            theme: "grid",
            headStyles: {fontSize: 18},
            columnStyles: {
                0: {
                    cellWidth: 10
                },
                1: {
                    cellWidth: 40,
                }
            }
        })
    }


    const pdfGenerator = () => {
        const doc = jsPDF({
            orientation: "p",
            format: "a4"
        })
        // user detail tble invoke
        studentInfoTAbleSetup(doc)
        // userAddSetup(doc)
        // userBillSetUp(doc)
        doc.output('dataurlnewwindow')
    }
    useEffect(() => {
        Object.keys(print_data).length != 0 && pdfGenerator()
    }, [print_data])
    return (
        <>
            <StudentInfoDetails table_ref={studentInfoRef}/>
        </>
    );
}

export default PdfMarkSheetReport;