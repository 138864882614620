import {GrRefresh} from "@react-icons/all-files/gr/GrRefresh";
import {Button} from "react-bootstrap";
import React from "react";
import SideNavUtilities from "../../NavBar/Utilities/SideNavUtilities";

class HeaderComponent {
    constructor() {
    }

    // refresh
    static refresh(refresh_set_states = []) {
        // refresh on click
        const refreshOnClick = () => {
            try {
                for (let setState of refresh_set_states) {
                    setState(prev => !prev)
                }
            } catch (e) {
                return false
            }
        }

        // view return
        return <Button
            onClick={e => refreshOnClick()}
            title={"reload"}
            className="text-success btn-sm bg-transparent reload-btn border-0"
        >
            <GrRefresh className="reload"/>

        </Button>
    }

    // refresh
    static new(resetOnChangeStates = [], btnName = "New +") {
        // refresh on click
        const newOnClick = () => {
            try {
                for (let setState of resetOnChangeStates) {
                    setState(prev => !prev)
                }
            } catch (e) {
                return false
            }
        }

        // view return
        return <Button
            onClick={e => newOnClick()}
            title={"reload"}
            className="btn-success btn-md"
        >
            {btnName}
        </Button>
    }

    // add
    static add(set_add_modal_show, button_name = "Add +", permissions = ["*"]) {
        // add button click
        const addOnClick = () => {
            try {
                set_add_modal_show(true)
            } catch (e) {
                return false
            }
        }

        return new SideNavUtilities().isPermitted([...permissions]) == true &&
            <Button onClick={e => addOnClick()} className="btn-primary btn-md">{button_name}</Button>
    }

}

export default HeaderComponent