import CommonAxios from "../../Utilities/CommonAxios";
import {getAisjAbout, getDirectorAbout, getFounderAbout, updatedAbout} from "../../AllRoutes/ApiRoute";
import {AboutSlice} from "../Slice/AboutSlice";
import CommonLoader from "../../Utilities/CommonLoader";
import CommonToast from "../../Utilities/CommonToast";

// get Aisj Action
export const AisjAction = payload => dispatch =>{

    CommonAxios.get(getAisjAbout).then(res => {
        if (res.data.status === true) {
            dispatch(AboutSlice.actions.getAisjReducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)

    })
}

// get FounderAbout Action
export const getFounderAboutAction = payload => dispatch =>{

    CommonAxios.get(getFounderAbout).then(res => {
        if (res.data.status === true) {
            dispatch(AboutSlice.actions.getFounderReducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)

    })
}

// get FounderAbout Action
export const getDirectorAboutAction = payload => dispatch =>{

    CommonAxios.get(getDirectorAbout).then(res => {
        if (res.data.status === true) {
            dispatch(AboutSlice.actions.getDirectorReducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)

    })
}


// Update About Action
export const UpdateAboutAction = payload => dispatch =>{
    const {
        reset,
        btnRef
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updatedAbout, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {

            // toast
            CommonToast.success("Aisj updated Successfully!")
        } else {
            CommonToast.warning("Aisj updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}
