import React, {useContext, useState} from 'react';
import CommonFrontendPaginationTableComponent
    from "../../CommonComponent/CommonFrontendPaginationTable/CommonFrontendPaginationTableComponent";
import FrontendPaginationHandler
    from "../../CommonComponent/CommonFrontendPaginationTable/method/FrontendPaginationHandler";
import {MarksheetContext} from "../MarkSheetComponent";
import MarkSheetUtility from "../utility/MarkSheetUtility";
import GradeIdentifier from "../../../Utilities/GradeIdentifier";
import {addMarksForSubjectAction} from "../../../Redux/Action/MarksheetAction";
import {IoSend} from "react-icons/io5";
import {useDispatch} from "react-redux";

function AddMarksTable({table_data = []}) {
    const dispatch = useDispatch()

    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examAndSubjectWiseStudent,
        setExamAndSubjectWiseStudent,
        studentSubjectWiseMarks,
        setStudentSubjectWiseMarks,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId,
        classWiseSteteReloder,
        setClassWiseSteteReloder
    } = useContext(MarksheetContext)


    // states
    const [loadingTable, setLoadingTable] = useState(false)
    const [forceToFetchData, setForceToFetchData] = useState(false)
    const [searchKey, setSearchKey] = useState("")
    const [offset, setOffset] = useState(0)
    const [data, setData] = useState(table_data)
    const [filteredData, setFilteredData] = useState([])
    const [itemsPerPage, setItemsPerPage] = useState(10)

    return (
        <>
            <CommonFrontendPaginationTableComponent
                table_name={"table_name"}
                offset={offset}
                setOffset={setOffset}
                total_data={examMarksTable.length}
                itemsPerPage={itemsPerPage}
                loadingTable={loadingTable}
                setForceToFetchData={setForceToFetchData}
                searchConfig={{
                    title: "",
                    placeholder: "Search Student name",
                    searchKey: searchKey,
                    setSearchKey: setSearchKey,
                    propertyForMatch: ["stu_a_student_info.student_full_name"],
                    preFilterData: examMarksTable,
                    setFilteredDataState: setFilteredData
                }}
            >

                <thead>
                <tr className="bg-blue">
                    <th>SL</th>
                    <th>Image</th>
                    <th>Student Name</th>
                    <th className="text-center">Full Marks</th>
                    <th className="text-center">Written</th>
                    <th className="text-center">Viva</th>
                    <th className="text-center">Total Marks</th>
                    <th className="text-center">Grade</th>
                    <th className="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                {FrontendPaginationHandler.getDataForEachPage(filteredData, offset, itemsPerPage).map((d, index) => (
                    <tr key={index}>
                        <td>{index + 1 }</td>
                        <td><img src={d.student_image} alt="" width="60px"/></td>
                        <td>{d.stu_a_student_info.student_full_name} ({d.id}) </td>
                        <td className="text-center">{d.book_detail.full_mark}</td>
                        <td >
                            <>
                                <input
                                    type="text"
                                    onChange={async e => {
                                        console.log(e)
                                        MarkSheetUtility.calcGpa(setExamMarksTable, e.target.value, d.book_detail.this_exam_result.viva_marks, d)
                                    }}
                                    className="tableInput" value={d.book_detail.this_exam_result.written_marks || 0}/>
                            </>
                        </td>
                        <td className="text-center">
                            <>
                                <input

                                    type="text"
                                    onChange={async e => {
                                        console.log(e)
                                        MarkSheetUtility.calcGpa(setExamMarksTable, d.book_detail.this_exam_result.written_marks, e.target.value, d)
                                    }}
                                    className="tableInput" value={d.book_detail.this_exam_result.viva_marks || 0}/>
                            </>
                        </td>
                        <td className="text-center">
                            <>
                                <input
                                    id={`total-marks-field`}
                                    className="tableInput"
                                    type={'text'}
                                    value={d.book_detail.this_exam_result.total_marks || 0}
                                    disabled={true}
                                />
                            </>
                        </td>
                        <td className="text-center"><span id={`grade-${index}`}>
                        {GradeIdentifier.getGrade(d.book_detail.this_exam_result.grade_point)}
                    </span></td>
                        <td className="text-center">
                            <button title="Send"
                                    className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        //console.log(row)

                                        let student_detail_for_add_mark = examMarksTable[index]

                                        // making payload for action
                                        const payload = {
                                            data: {
                                                data_for_add_mark: [{
                                                    sessional_info_id: student_detail_for_add_mark?.id || 0,
                                                    sessional_exam_id: examAndSubjectId.sessional_exam_id,
                                                    sessional_book_id: student_detail_for_add_mark.book_detail.id,
                                                    exam_full_mark: student_detail_for_add_mark.book_detail.full_mark,
                                                    written_marks: student_detail_for_add_mark.book_detail.this_exam_result.written_marks,
                                                    viva_marks: student_detail_for_add_mark.book_detail.this_exam_result.viva_marks,
                                                    total_marks: student_detail_for_add_mark.book_detail.this_exam_result.total_marks,
                                                    grade_point: student_detail_for_add_mark.book_detail.this_exam_result.grade_point,
                                                }]
                                            },
                                            setClassWiseSteteReloder

                                        }

                                        dispatch(addMarksForSubjectAction(payload))

                                    }
                                    }>
                                <IoSend/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </CommonFrontendPaginationTableComponent>
        </>
    );
}

export default AddMarksTable;