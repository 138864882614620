import React, {useContext} from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {IoIosSettings} from "react-icons/io";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiDeleteBin6Line} from "@react-icons/all-files/ri/RiDeleteBin6Line";
import {SessionalExamContext} from "./SessionalExam";

function SessionalExamList(props) {
    const {
        allExamReload,
        setAllExamReload,
        filterData,
        setFilterData,
        filteredExams,
        setFilteredExams,
        sessionalExamSingleData,
        setSessionalExamSingleData,
        deleteSessionalExamModelShow,
        setDeleteSessionalExamModelShow,
    }=useContext(SessionalExamContext)

    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'exam_name',
            text: 'Exam Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'designation',
            text: 'Designation',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },{
            dataField: 'education',
            text: 'Education',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },  {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',textAlign:"center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Delete Button*/}
                    <button title="Del"
                            className="ms-2 bg-transparent iconActionButton border-0 text-danger btn btn-primary"
                            onClick={e => {
                                // // data for update
                                setSessionalExamSingleData(row)
                                //
                                // // Delete Modal Show
                                setDeleteSessionalExamModelShow(true)

                            }
                            }>
                        <RiDeleteBin6Line/>
                    </button>
                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={filterData}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default SessionalExamList;