
import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {
    addGeneralStaff,
    addTeacher,
    getGeneralStaff,
    updateGeneralStaff,
    updateTeacher
} from "../../AllRoutes/ApiRoute";
import {TeacherSlice} from "../Slice/TeacherSlice";
import {StaffSlice} from "../Slice/StaffSlice";
import CommonToast from "../../Utilities/CommonToast";

// get Staff Action
export const getAllStaffAction = payload => dispatch =>{
    // getting data from payload
    const {allTeacherTableLoaderRef} = payload
    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allTeacherTableLoaderRef)
        } catch (e) {

        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.get(getGeneralStaff).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(StaffSlice.actions.getAllStaffReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })
}

// Add Staff Action
export const addStaffAction = payload => dispatch =>{
    const {
        btnRef,
        setStaffAddModelShow,
        setAllStaffReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addGeneralStaff, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setStaffAddModelShow(false)
            //all Teacher table reload
            setAllStaffReload(prev => !prev)
            // toast
            CommonToast.success("Staff Create Successfully!")
        } else {
            CommonToast.error("Staff Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}



// Update Staff Action
export const UpdateStaffAction = payload => dispatch =>{
    const {
        btnRef,
        setStaffEditModelShow,
        setAllStaffReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateGeneralStaff, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setStaffEditModelShow(false)
            //all Agent table reload
            setAllStaffReload(prev => !prev)
            // toast
            CommonToast.success("Staff updated Successfully!")
        } else {
            CommonToast.warning("Staff updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}