import React, {createContext, useEffect, useRef, useState} from 'react';
import {Button, Card, Tabs, Tab} from "react-bootstrap";
import {getAllClassAction} from "../../Redux/Action/ClassAction";
import {useDispatch, useSelector} from "react-redux";
import ClassTable from "./ClassSubComponent/ClassTable";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import ClassForm from "./ClassSubComponent/ClassForm";
import {ToastContainer} from "react-toastify";
import DepartmentConfigureForm from "./ClassSubComponent/DepartmentConfigureForm";
import DepartmentSubComponent from "./ClassSubComponent/DepartmentSubComponent";
import SegmentConfigureForm from "./ClassSubComponent/SegmentConfigureForm";
import ClassSegmentSubComponent from "./ClassSubComponent/ClassSegmentSubComponent";

export const ClassContext = createContext()

function ClassComponent(props) {
    // Hook
    const dispatch = useDispatch()
    const allClassTableLoaderRef = useRef()

    // data getting from store
    const allClass = useSelector(state => state.configurationSlice.allClass)

    //state
    const [allClassReload, setAllClassReload] = useState(false)
    const [classAddModelShow, setClassAddModelShow] = useState(false)
    const [classEditModelShow, setClassEditModelShow] = useState(false)
    const [getSingleClassData, setGetSingleClassData] = useState({})
    const [configureModal, setConfigureModal] = useState(false)
    const [singleClassDetail, setSingleClassDetail] = useState({})

    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allClassTableLoaderRef,
        }
        dispatch(getAllClassAction(Payload))
    }, [allClassReload])

    // update single class detail, when department or class segment add or update for modal
    useEffect(() => {
        let updatedSingleClass = {}
        if (Object.keys(singleClassDetail)?.length != 0) {
            for (let singleClass of allClass) {
                if (singleClass.id == singleClassDetail.id) {
                    updatedSingleClass = singleClass
                    break
                }
            }
        }
        setSingleClassDetail(updatedSingleClass)
    }, [allClass])


    return (
        <>
            <ClassContext.Provider value={{
                allClassReload,
                setAllClassReload,
                classAddModelShow,
                setClassAddModelShow,
                classEditModelShow,
                setClassEditModelShow,
                getSingleClassData,
                setGetSingleClassData,
                configureModal,
                setConfigureModal,
                setSingleClassDetail,
                singleClassDetail
            }}>
                <div className="d-flex align-items-center justify-content-end mb-3">
                    {HeaderComponent.add(setClassAddModelShow)}</div>

                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <h3>All Class</h3>
                            {HeaderComponent.refresh([setAllClassReload])}

                        </div>
                        {/*class table*/}
                        <ClassTable allClass={allClass}/>
                        {/*section loader*/}
                        <SectionLoader loading_handle_ref={allClassTableLoaderRef}/>

                        {/*add modal show*/}
                        <CommonModal
                            show={classAddModelShow}
                            setShow={setClassAddModelShow}
                            title={"Add Class"}
                            size="md"

                        >
                            <ClassForm/>
                        </CommonModal>
                        {/*Edit modal show*/}
                        <CommonModal
                            show={classEditModelShow}
                            setShow={setClassEditModelShow}
                            title={"Edit Class"}
                            size="md"
                        >
                            <ClassForm formType={'Update'} default_data={getSingleClassData}/>
                        </CommonModal>

                        {/*confugure modal*/}
                        <CommonModal
                            show={configureModal}
                            setShow={setConfigureModal}
                            title={"Class config"}
                            size="md"
                        >
                            <Tabs
                                defaultActiveKey="department"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                {/*department configure tab*/}
                                <Tab eventKey="department" title="Department">
                                    {/*department add form*/}
                                    <DepartmentConfigureForm/>
                                    {/*department update handler*/}
                                    <DepartmentSubComponent
                                        class_wise_departments={singleClassDetail.cnfg_class_wise_departments}
                                    />
                                </Tab>
                                {/*class segment configure tab*/}
                                <Tab eventKey="segment" title="Segment">
                                    {/*segment add form*/}
                                    <SegmentConfigureForm/>
                                    {/*class segment update handler*/}
                                    <ClassSegmentSubComponent
                                        class_segments={singleClassDetail.cnfg_class_segments || []}
                                    />
                                </Tab>
                            </Tabs>
                        </CommonModal>

                    </Card.Body>
                </Card>
                {/*toast container*/}
                <ToastContainer/>
            </ClassContext.Provider>
        </>
    );
}

export default ClassComponent;