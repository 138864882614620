import CommonAxios from "../../Utilities/CommonAxios";
import {addShift, addStaffAvatar, getAllShift, updateShift} from "../../AllRoutes/ApiRoute";
import CommonLoader from "../../Utilities/CommonLoader";
import {ConfigurationSlice} from "../Slice/ConfigurationSlice";
import CommonToast from "../../Utilities/CommonToast";

export const getAllShiftAction = payload => dispatch => {
    // getting data from payload
    const {allShiftTableLoaderRef} = payload

    // loader handle method
    const loaderHandleMethod = () => {
        try {

        } catch (e) {
            //loader handle
            CommonLoader.section(allShiftTableLoaderRef)
        }
    }

    // invoke loader handle method
    loaderHandleMethod()

    CommonAxios.get(getAllShift).then(res => {
        // invoke loader handle method
        loaderHandleMethod()
        if (res.data.status === true) {
            dispatch(ConfigurationSlice.actions.getAllShiftReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandleMethod()
    })
}

// add Shift action
export const addShiftAction = payload => dispatch => {
    const {
        btnRef,
        setShiftAddModelShow,
        setAllShiftReload
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    CommonAxios.post(addShift, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setShiftAddModelShow(false)
            //all Agent table reload
            setAllShiftReload(prev => !prev)
            // toast
            CommonToast.success("Shift Create Successfully!")
        } else {
            CommonToast.error("Shift Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Update Shift action
export const updateShiftAction = payload => dispatch => {
    const {
        btnRef,
        setShiftEditModelShow,
        setAllShiftReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateShift, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setShiftEditModelShow(false)
            //all Agent table reload
            setAllShiftReload(prev => !prev)
            // toast
            CommonToast.success("Shift updated Successfully!")
        } else {
            CommonToast.warning("Shift updated failed! " + res.data?.error_message)
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Add Teacher image Action

export const addStaffImageAction = payload => dispatch =>{
    const {
        btnRef,
        setAddImageModelShow,
        setAllStaffReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addStaffAvatar, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAddImageModelShow(false)
            //all Teacher table reload
            setAllStaffReload(prev => !prev)

            // toast
            CommonToast.success("Staff updated Successfully!")
        } else {
            CommonToast.warning("Staff updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}