import React, {createContext, useEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import StudentBackendFilterForm from "../OurStudentSection/OurStudentSubComponent/studentBackendFilterForm";
import ClassWiseStudentBackendFilterForm from "./MarksheetSubComponent/ClassWiseStudentBackendFilterForm";
import {getAllClassAction} from "../../Redux/Action/ClassAction";
import {useDispatch} from "react-redux";
import StudentTable from "../OurStudentSection/OurStudentSubComponent/StudentTable";
import ExamAndSubjectWiseFilter from "./MarksheetSubComponent/ExamAndSubjectWiseFilter";
import MarksAddTable from "./MarksheetSubComponent/MarksAddTable";
import {
    getClassWiseStudentsWithMarkSheetAction,
    getSessionalExamsForMarksheetAction, getSessionalSubjectForMarksheetAction
} from "../../Redux/Action/MarksheetAction";
import AddMarksTable from "./MarksheetSubComponent/AddMarksTable";

export const MarksheetContext =createContext()
function MarkSheetComponent(props) {
    //hook
    const dispatch = useDispatch()

    //State
    const [classWiseStudent, setClassWiseStudent] = useState([])
    const [singleStudentDetails, setSingleStudentDetails] = useState({})
    const [examListForMarksheet, setExamListForMarksheet] = useState([])
    const [subjectListForMarksheet, setSubjectListForMarksheet] = useState([])
    const [examAndSubjectWiseStudent, setExamAndSubjectWiseStudent] = useState([])
    const [studentSubjectWiseMarks, setStudentSubjectWiseMarks] = useState([])
    const [examMarksTable, setExamMarksTable] = useState([])
    const [examAndSubjectId,setExamAndSubjectId] = useState({})
    const [classWiseSteteReloder, setClassWiseSteteReloder] = useState(false)

    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))

    }, [])

    // after add marks action lifecycle
    useEffect(()=>{


    },[classWiseStudent])
    return (
        <>
            <MarksheetContext.Provider value={{
                classWiseStudent,
                setClassWiseStudent,
                singleStudentDetails,
                setSingleStudentDetails,
                examListForMarksheet,
                setExamListForMarksheet,
                subjectListForMarksheet,
                setSubjectListForMarksheet,
                studentSubjectWiseMarks,
                setStudentSubjectWiseMarks,
                examMarksTable,
                setExamMarksTable,
                examAndSubjectId,
                setExamAndSubjectId,
                classWiseSteteReloder,
                setClassWiseSteteReloder
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <h3>Class & Subject wise marks add</h3>
                        </div>
                        <ClassWiseStudentBackendFilterForm/>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <ExamAndSubjectWiseFilter/>
                        <AddMarksTable table_data={examMarksTable}/>
                    </Card.Body>
                </Card>

                <Card className="mt-3">

                </Card>


            </MarksheetContext.Provider>

        </>
    );
}

export default MarkSheetComponent;