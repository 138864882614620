import React from 'react';
import TeacherComponent from "../Component/TeacherComponent/TeacherComponent";

function OurTeacherPage(props) {
    return (
        <>
            <TeacherComponent/>
        </>
    );
}

export default OurTeacherPage;