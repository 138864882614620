import Parse from "./parse";

class GradeIdentifier {
    static getGrade = (grade_point = "0") => {
        if (Parse.toNumber(grade_point) >= 5) return "A+"
        else if (Parse.toNumber(grade_point) >= 4) return "A"
        else if (Parse.toNumber(grade_point) >= 3.5) return "A-"
        else if (Parse.toNumber(grade_point) >= 3) return "B"
        else if (Parse.toNumber(grade_point) >= 2) return "C"
        else if (Parse.toNumber(grade_point) >= 1) return "D"
        else return "F"

    }
    static getGradePoint = (total_marks = 0) => {
        if (Parse.toNumber(total_marks) >= 80) return 5.0
        else if (Parse.toNumber(total_marks) >= 70) return 4.0
        else if (Parse.toNumber(total_marks) >= 60) return 3.5
        else if (Parse.toNumber(total_marks) >= 50) return 3.0
        else if (Parse.toNumber(total_marks) >= 40) return 2.0
        else if (Parse.toNumber(total_marks) >= 33) return 1.0
        else return 0.0
    }
}

export default GradeIdentifier