'use client'
import React, {createContext, useEffect, useState} from 'react';
import CommonPagination from "./utility/CommonPagination";
import CommonTableBody from "./utility/CommonTableBody";
import CommonSearchField from "./utility/CommonSearchField";
import CommonTableLoader from "./utility/CommonTableLoader";
// create context
export const CommonTableContext = createContext()

function CommonFrontendPaginationTableComponent({
     table_name="table name",
     pagination_is_active=true,
     offset,
     setOffset,
     itemsPerPage,
     total_data,
     loadingTable,
     setForceToFetchData,
     searchConfig={
         title:"Title",
         placeHolder:"Place holder",
         searchKey:"",
         setSearchKey:()=>false,
         propertyForMatch:[],
         preFilterData:"",
         setFilteredDataState:"",
         search_field_column:6
    },
     children
}) {
    // states
    const [currentPage, setCurrentPage] = useState(0);


    return (
        <>
            <CommonTableContext.Provider value={{
                table_name,
                setOffset,
                offset,
                itemsPerPage,
                total_data,
                loadingTable,
                currentPage,
                setCurrentPage,
                setForceToFetchData,
                searchConfig
            }}>
                {/*search field*/}
                <CommonSearchField/>
                {/*table body*/}
                <CommonTableBody>
                    {!loadingTable ? children : ""}
                </CommonTableBody>
                {/*table loader*/}
                {loadingTable ? <CommonTableLoader/> : ""}
                {/*pagination*/}
                {pagination_is_active?<CommonPagination/>:""}
            </CommonTableContext.Provider>
        </>
    );
}

export default CommonFrontendPaginationTableComponent;