import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {FaInfoCircle} from "@react-icons/all-files/fa/FaInfoCircle";
import {FaBook} from "react-icons/fa";
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {MarksheetContext} from "../MarkSheetComponent";
import data from "bootstrap/js/src/dom/data";
import GradeIdentifier from "../../../Utilities/GradeIdentifier";
import {IoSend} from "react-icons/io5";
import Parse from "../../../Utilities/parse";
import MarkSheetUtility from "../utility/MarkSheetUtility";
import {addMarksForSubjectAction} from "../../../Redux/Action/MarksheetAction";
import {useDispatch} from "react-redux";

function MarksAddTable({table_data = []}) {
    const dispatch = useDispatch()

    const {
        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        examMarksTable,
        setExamMarksTable,
        examAndSubjectId,
        setExamAndSubjectId,
        classWiseSteteReloder,
        setClassWiseSteteReloder
    } = useContext(MarksheetContext)
    // stetes
    const [renderThisComponent, setRenderThisComponent] = useState(false)


    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '25px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'student_image',
            text: 'Image',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '80px',
                };
            },
            formatter: (cell, row) => {
                return <><img src={cell} alt="" width="60px"/></>
            }
        }
        , {
            dataField: 'stu_a_student_info.student_full_name',
            text: 'Student Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '170px',
                };
            }, formatter: ((cell, row) => {
                return <>
                    {cell + " (" + row.id + ")"}
                </>
            })
        }, {
            text: 'Full Marks',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {row.book_detail.full_mark}
                </>
            })
        }, {
            dataField: 'cnfg_class_wise_department.department_name',
            text: 'Written',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"},
            formatter: ((cell, row, rowIndex) => {
                // document.getElementById(`written_marks_field_${row.id}`).value = Math.random()
                return <>
                    <input
                        id={`written_marks_field_${row.id}`}
                        type="text"
                        onChange={async e => {
                            console.log(document.getElementById(`written_marks_field_${row.id}`))
                            document.getElementById(`written_marks_field_${row.id}`).value = e.target.value
                            console.log(e)
                            MarkSheetUtility.calcGpa(setExamMarksTable, e.target.value, row.book_detail.this_exam_result.viva_marks, rowIndex)
                        }}
                        className="tableInput" value={row.book_detail.this_exam_result.written_marks || 0}/>
                </>
            })
        }, {
            text: 'Viva',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '150px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"},
            formatter: ((cell, row, rowIndex) => {
                return <>
                    <input onChange={e => {
                        MarkSheetUtility.calcGpa(setExamMarksTable, row.book_detail.this_exam_result.written_marks, e.target.value, rowIndex)
                    }}
                           type="text" id={`viva-field-${rowIndex}`} className="tableInput"
                           value={examMarksTable[rowIndex].book_detail.this_exam_result.viva_marks || 0}/>
                </>
            })
        }, {

            text: 'Total Marks',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"},
            formatter: ((cell, row, rowIndex) => {

                return <>
                    <input
                        id={`total-marks-field-${rowIndex}`}
                        className="tableInput"
                        type={'text'}
                        value={row.book_detail.this_exam_result.total_marks || 0}
                        disabled={true}
                    />

                </>
            })
        }, {
            dataField: 'academic_year',
            text: 'Grade',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '100px',
                    textAlign: "center",
                };
            },
            style: {textAlign: "center"},
            formatter: ((cell, row, rowIndex) => {
                return <>
                    <span id={`grade-${rowIndex}`}>
                        {GradeIdentifier.getGrade(row.book_detail.this_exam_result.grade_point)}
                    </span>

                </>
            })
        }, {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px', textAlign: "center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row, rowIndex) => {
                return <>
                    <button title="Send"
                            className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                //console.log(row)

                                let student_detail_for_add_mark = examMarksTable[rowIndex]

                                // making payload for action
                                const payload = {
                                    data: {
                                        data_for_add_mark: [{
                                            sessional_info_id: student_detail_for_add_mark?.id || 0,
                                            sessional_exam_id: examAndSubjectId.sessional_exam_id,
                                            sessional_book_id: student_detail_for_add_mark.book_detail.id,
                                            exam_full_mark: student_detail_for_add_mark.book_detail.full_mark,
                                            written_marks: student_detail_for_add_mark.book_detail.this_exam_result.written_marks,
                                            viva_marks: student_detail_for_add_mark.book_detail.this_exam_result.viva_marks,
                                            total_marks: student_detail_for_add_mark.book_detail.this_exam_result.total_marks,
                                            grade_point: student_detail_for_add_mark.book_detail.this_exam_result.grade_point,
                                        }]
                                    },
                                    setClassWiseSteteReloder

                                }

                                dispatch(addMarksForSubjectAction(payload))

                            }
                            }>
                        <IoSend/>
                    </button>

                </>
            })

        }

    ];
    console.log(examMarksTable)
    return (
        <>
            <TableTwoBody
                products={table_data}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default MarksAddTable;