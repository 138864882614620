import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {StudentContext} from "../../OurStudentSection/OurStudentSection";
import {classWiseStudentAction, getSessionalSubjectAction} from "../../../Redux/Action/StudentAction";
import CommonToast from "../../../Utilities/CommonToast";
import {Button, Col, Form, Row} from "react-bootstrap";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {MarksheetContext} from "../MarkSheetComponent";
import {
    getClassWiseStudentsWithMarkSheetAction,
    getSessionalExamsForMarksheetAction, getSessionalSubjectForMarksheetAction
} from "../../../Redux/Action/MarksheetAction";

function ClassWiseStudentBackendFilterForm(props) {
    // hooks
    const btnRef = useRef()
    const sessionalBtnRef = useRef()
    const dispatch = useDispatch()
    const departmentRef = useRef()
    const segmentRef = useRef()


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states
    const [allDepartment, setAllDepartment] = useState([])
    const [allSegment, setAllSegment] = useState([])


    // getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)

    //Context
    const {

        classWiseStudent,
        setClassWiseStudent,
        singleStudentDetails,
        setSingleStudentDetails,
        examListForMarksheet,
        setExamListForMarksheet,
        subjectListForMarksheet,
        setSubjectListForMarksheet,
        studentSubjectWiseMarks,
        setStudentSubjectWiseMarks,
        classWiseSteteReloder,
        setClassWiseSteteReloder
    } = useContext(MarksheetContext)
    // state
    const [classWiseStuentFetchFilteredData, setClassWiseStuentFetchFilteredData] = useState({})


    // class on change
    const classOnChange = () => {
        const selectedClass = JSON.parse(getValues("class_id") || "{}")
        // set class wise department and segment
        setAllDepartment(selectedClass.cnfg_class_wise_departments || [])
        setAllSegment(selectedClass.cnfg_class_segments || [])
        document.getElementById("segmentForClassWiseStudent").selectedIndex = 0
        document.getElementById("departmentForClassWiseStudent").selectedIndex = 0
    }

    // get class wise student lifycycle
    useEffect(() => {
        if (Object.keys(classWiseStuentFetchFilteredData).length != 0) {
            const payload = {
                reset,
                btnRef,
                setClassWiseStudent,
                setExamListForMarksheet,
                setSubjectListForMarksheet
            }
            payload.data = classWiseStuentFetchFilteredData

            dispatch(getClassWiseStudentsWithMarkSheetAction(payload))
            dispatch(getSessionalExamsForMarksheetAction(payload))
            dispatch(getSessionalSubjectForMarksheetAction(payload))

        }
    }, [classWiseStuentFetchFilteredData, classWiseSteteReloder])

    // form submit
    const onSubmit = async data => {

        // modifying data
        let updatedData = {
            ...data,
            class_id: JSON.parse(data.class_id || "{}").id,
            segment_id: data.class_segment_id,
            triggerTime:new Date().getTime()
        }
        setClassWiseStuentFetchFilteredData(updatedData)


    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {}
                <Row>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Academic Year <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.academic_year ? "is-invalid" : ""}`}
                                {...register("academic_year", {
                                    required: true
                                })}
                            >
                                <option value="">Select Academic Year</option>
                                {ApplicationUtilities.years().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.academic_year && <span className="text-danger">
                                {errors.academic_year.type == "required" && "Please Give Academic Year."}
                                {errors.academic_year.type == "pattern" && "Please Provide A Valid Number."}
                                {errors.academic_year.type == "minLength" && "Please Provide Full Year."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Class <span>*</span></Form.Label>
                            <Form.Select
                                {...register("class_id", {
                                    required: true,
                                    onChange: event => classOnChange()
                                })}
                            >
                                <option value="">Select...</option>
                                {allClass.map((singleClass, key) => (
                                    <option value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Department <span>*</span></Form.Label>
                            <Form.Select
                                id="departmentForClassWiseStudent"
                                {...register("department_id", {
                                    required: true
                                })}
                            >
                                <option value="">Select...</option>
                                {allDepartment.map((singleDepartment, key) => (
                                    <option value={singleDepartment.id}>{singleDepartment.department_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.department_id && <span className="text-danger">
                                {errors.department_id.type == "required" && "Please Select A Department."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Segment / Year <span>*</span></Form.Label>
                            <Form.Select
                                id="segmentForClassWiseStudent"
                                {...register("class_segment_id", {
                                    required: true,
                                })}
                            >
                                <option value="">Select...</option>
                                {allSegment.map((singleSegment, key) => (
                                    <option value={singleSegment.id}>{singleSegment.segment_name}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                        </Form.Group>
                    </Col>
                </Row>


                <div>
                    <Button ref={btnRef}
                            type="submit"
                            className="submit btn-sm">
                        Submit
                        <ButtonLoader/>
                    </Button>


                </div>

            </form>

        </>
    );
}

export default ClassWiseStudentBackendFilterForm;