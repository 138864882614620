import React, {useContext} from 'react';
import {useSelector} from "react-redux";
import {FaUserAlt} from "@react-icons/all-files/fa/FaUserAlt";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiFileUserLine} from "@react-icons/all-files/ri/RiFileUserLine";
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {ExamContext} from "../ExamComponent";
import {RiDeleteBin6Line} from "@react-icons/all-files/ri/RiDeleteBin6Line";
import { IoIosSettings } from "react-icons/io";

function ExamList(props) {
    const {
        allExamReload,
        setAllExamReload,
        addExamModelShow,
        setAddExamModelShow,
        editExamModelShow,
        setEditExamModelShow,
        deleteExamModelShow,
        setDeleteExamModelShow,
        examSingleData,
        setExamSingleData,
        filterData,
        setFilterData,
        filteredExams,
        setFilteredExams,
        sessionalExamModelShow,
        setSessionalExamModelShow
    } = useContext(ExamContext)


    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'exam_name',
            text: 'Exam Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'designation',
            text: 'Designation',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },{
            dataField: 'education',
            text: 'Education',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },  {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',textAlign:"center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Delete Button*/}
                    <button title="Setting"
                            className="bg-transparent iconActionButton border-0 textBlue btn mr15"
                            onClick={e => {
                                // data for update
                                setExamSingleData(row)

                                // Delete Modal Show
                                setSessionalExamModelShow(true)

                            }
                            }>
                        <IoIosSettings />
                    </button>
                    {/*Edit Button*/}
                    <button title="Edit"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setExamSingleData(row)
                                // update modal show
                                setEditExamModelShow(true)
                            }
                            }>
                        <FiEdit/>
                    </button>
                    {/*Delete Button*/}
                    <button title="Del"
                            className="ms-2 bg-transparent iconActionButton border-0 text-danger btn btn-primary"
                            onClick={e => {
                                // data for update
                                setExamSingleData(row)

                                // Delete Modal Show
                                setDeleteExamModelShow(true)

                            }
                            }>
                        <RiDeleteBin6Line/>
                    </button>
                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={filteredExams}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default ExamList;