import React, {useContext} from 'react';
import {EventContext} from "../EventComponent";
import {Card, Col, Row} from "react-bootstrap";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiDeleteBin6Line} from "@react-icons/all-files/ri/RiDeleteBin6Line";
import {useSelector} from "react-redux";
import imageBlank from "../../../assets/images/image-blank.jpg"

function EventArea(props) {
    // data getting from store
    const allEvent = useSelector(state => state.eventSlice.allEvent)

    const {
        allEventReload,
        setAllEventReload,
        eventAddModelShow,
        setEventAddModelShow,
        eventEditModelShow,
        setEventEditModelShow,
        eventDeleteModelShow,
        setEventDeleteModelShow,
        eventSingleData,
        setEventSingleData,
        eventImageEditModelShow,
        setEventImageEditModelShow
    }=useContext(EventContext)
    return (
        <>
            <Row>
                {allEvent.map((data,index)=>(
                    <Col lg={12} md={12} sm={12} className="notice mt-3">
                        <Card>
                            <Card.Header className="align-items-center d-flex">
                                <h5 className="mb-0 flex-grow-1">{data.title}</h5>
                                <div className="flex-shrink-0">
                                    <button title="Edit"
                                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                            onClick={e => {
                                                // data for update
                                                setEventSingleData(data)
                                                // update modal show
                                                setEventEditModelShow(true)

                                            }
                                            }>
                                        <FiEdit/>
                                    </button><button title="Delete"
                                                     className="ms-2 bg-transparent iconActionButton border-0 text-danger btn btn-primary"
                                                     onClick={e => {
                                                         // data for update
                                                         setEventSingleData(data)
                                                         // update modal show
                                                         setEventDeleteModelShow(true)
                                                     }
                                                     }>
                                    <RiDeleteBin6Line/>
                                </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={5} md={5} sm={12} className="notice mt-3">
                                        <strong>Short Description</strong>
                                        <div className="mb-2">{data.short_description}</div>

                                        <strong>Youtube Code</strong>
                                        <div className="mb-2">{data.video_link}</div>
                                    </Col>
                                    <Col lg={7} md={7} sm={12} className="eventImage mt-3">
                                        <strong>Image</strong>
                                        <ul>
                                            <li><img src={data.image_1 || imageBlank} alt=""/> <div>
                                                <button title="Edit"
                                                        className="bg-transparent iconActionButton border-0 btn btn-primary"
                                                        onClick={e => {
                                                            // data for update
                                                            setEventSingleData({
                                                                ...data,
                                                                update_image_field_name:"image_1"
                                                            })
                                                            // update modal show
                                                            setEventImageEditModelShow(true)
                                                        }
                                                        }>
                                                    <FiEdit/>
                                                </button>
                                            </div></li>
                                            <li><img src={data.image_2 || imageBlank} alt=""/>
                                                <div>
                                                    <button title="Edit"
                                                            className="bg-transparent iconActionButton border-0 btn btn-primary"
                                                            onClick={e => {
                                                                // data for update
                                                                setEventSingleData({
                                                                    ...data,
                                                                    update_image_field_name:"image_2"
                                                                })
                                                                // update modal show
                                                                setEventImageEditModelShow(true)
                                                            }
                                                            }>
                                                        <FiEdit/>
                                                    </button>
                                                </div></li>
                                            <li><img src={data.image_3 || imageBlank} alt=""/>
                                                <div>
                                                    <button title="Edit"
                                                            className="bg-transparent iconActionButton border-0 btn btn-primary"
                                                            onClick={e => {
                                                                // data for update
                                                                setEventSingleData({
                                                                    ...data,
                                                                    update_image_field_name:"image_3"
                                                                })
                                                                // update modal show
                                                                setEventImageEditModelShow(true)
                                                            }
                                                            }>
                                                        <FiEdit/>
                                                    </button>
                                                </div></li>
                                            <li><img src={data.image_4 || imageBlank} alt=""/>
                                                <div>
                                                    <button title="Edit"
                                                            className="bg-transparent iconActionButton border-0 btn btn-primary"
                                                            onClick={e => {
                                                                // data for update
                                                                setEventSingleData({
                                                                    ...data,
                                                                    update_image_field_name:"image_4"
                                                                })
                                                                // update modal show
                                                                setEventImageEditModelShow(true)
                                                            }
                                                            }>
                                                        <FiEdit/>
                                                    </button>
                                                </div></li>
                                            <li><img src={data.image_5 || imageBlank} alt=""/>
                                                <div>
                                                    <button title="Edit"
                                                            className="bg-transparent iconActionButton border-0 btn btn-primary"
                                                            onClick={e => {
                                                                // data for update
                                                                setEventSingleData({
                                                                    ...data,
                                                                    update_image_field_name:"image_5"
                                                                })
                                                                // update modal show
                                                                setEventImageEditModelShow(true)
                                                            }
                                                            }>
                                                        <FiEdit/>
                                                    </button>
                                                </div></li>
                                        </ul>
                                    </Col>
                                </Row>


                            </Card.Body>


                        </Card>
                    </Col>
                ))}

            </Row>
        </>
    );
}

export default EventArea;