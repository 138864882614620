import React, {useContext, useRef, useState} from 'react';
import {ExamContext} from "../ExamComponent";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {addSessionalBookAction} from "../../../Redux/Action/SessionalBook";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import {addSessionalExamAction} from "../../../Redux/Action/ExamAction";

function SessionalExamAddForm(props) {
    // hook
    const btnRef = useRef()
    const dispatch = useDispatch()

    const {
        allExamReload,
        setAllExamReload,
        addExamModelShow,
        setAddExamModelShow,
        editExamModelShow,
        setEditExamModelShow,
        deleteExamModelShow,
        setDeleteExamModelShow,
        examSingleData,
        setExamSingleData,
        filterData,
        setFilterData,
        filteredExams,
        setFilteredExams,
        sessionalExamModelShow,
        setSessionalExamModelShow
    } = useContext(ExamContext)

// hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();


    // form submit
    const onSubmit = async data => {

        // modifying data
        let updatedData = {
            ...data,
            class_id: examSingleData.class_id || "{}",
            segment_id: examSingleData.segment_id ||"{}",
            exam_id: examSingleData.id || "{}"

        }
        const payload = {
            reset,
            btnRef,
            setSessionalExamModelShow,
            setAllExamReload


        }
        payload.data = updatedData


       dispatch(addSessionalExamAction(payload))
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Academic Year <span>*</span></Form.Label>
                            <Form.Select
                                className={`${errors.academic_year ? "is-invalid" : ""}`}
                                {...register("academic_year", {
                                    required: true
                                })}
                            >
                                <option value="">Select Academic Year</option>
                                {ApplicationUtilities.years().map((value, key) => (
                                    <option value={value} key={key}>{value}</option>
                                ))}
                            </Form.Select>
                            {/*validation message*/}
                            {errors.academic_year && <span className="text-danger">
                                {errors.academic_year.type == "required" && "Please Give Academic Year."}
                                {errors.academic_year.type == "pattern" && "Please Provide A Valid Number."}
                                {errors.academic_year.type == "minLength" && "Please Provide Full Year."}
                            </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Exam Name</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Exam Name"
                                defaultValue={examSingleData.exam_name}
                                {...register("exam_name", {
                                    required: false
                                })}
                            />


                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Show Mark Sheet</Form.Label>
                            <Form.Select
                                {...register("show_on_main_mark_sheet", {
                                    required: true,

                                })}
                            >
                                <option selected=" " defaultValue="0" value='0'>No</option>
                                <option value='1' >Yes</option>

                            </Form.Select>

                        </Form.Group>
                    </Col>
                </Row>
                <div>
                    <Button ref={btnRef}
                            type="submit"
                            className="submit btn-sm">
                        Submit
                        <ButtonLoader/>
                    </Button>
                </div>

            </form>
        </>
    );
}

export default SessionalExamAddForm;