// making private property
const _loading_state = Symbol()
const _setState = Symbol()

class CommonLoader {
    constructor() {
    }

    // button loader handle
    static btn(btnRef) {
        try {
            // button enable or disable
            btnRef.current.disabled = !btnRef.current.disabled
            // button loading image view or hide
            btnRef?.current?.querySelector("img")?.classList.toggle("d-none")
        } catch (e) {
            return false
        }
    }

    // section loader handle
    static section(btnRef) {
        try {
            let elements = btnRef?.current
            // button loading image view or hide
            elements.classList.toggle("d-none")
        } catch (e) {
            return false
        }
    }


}

export default CommonLoader