import React, {useContext} from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {useSelector} from "react-redux";
import {TeacherContext} from "../../TeacherComponent/TeacherComponent";
import {FaUserAlt} from "@react-icons/all-files/fa/FaUserAlt";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import {RiFileUserLine} from "@react-icons/all-files/ri/RiFileUserLine";
import {StaffContext} from "../StaffComponent";

function StaffTableSection(props) {
    // data getting from store
    const allStaff = useSelector(state => state.staffSlice.allStaff)

    //Context
    const {
        allStaffReload,
        setAllStaffReload,
        staffAddModelShow,
        setStaffAddModelShow,
        staffEditModelShow,
        setStaffEditModelShow,
        getStaffSingleData,
        setGetStaffSingleData,
        addImageModelShow,
        setAddImageModelShow
    } = useContext(StaffContext)



    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '60px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    },
        {
            dataField: 'image',
            text: 'Image',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }, formatter: ((cell, row) => {
                return <>
                    {cell != null ?
                        <img style={{width: "60px", height: "40px"}} src={cell} alt={"no file"} className="img-fluid"/> :
                        <div className="d-flex align-items-center justify-content-center">
                            <FaUserAlt className="m-auto text-muted"/>
                        </div>}
                </>
            })
        },{
            dataField: 'staff_name',
            text: 'Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        }, {
            dataField: 'designation',
            text: 'Designation',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },{
            dataField: 'education',
            text: 'Education',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }
        },  {
            dataField: 'id',
            text: 'Action',
            headerStyle: () => {
                return {
                    width: '120px',textAlign:"center",

                }
            },
            style: {textAlign: "center"},
            formatter: ((cell, row) => {
                return <>
                    {/*Edit Button*/}
                    <button title="Edit"
                            className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetStaffSingleData(row)
                                // update modal show
                                setStaffEditModelShow(true)
                            }
                            }>
                        <FiEdit/>
                    </button>
                    {/*Image Button*/}
                    <button title="Image"
                            className="ms-2 bg-transparent iconActionButton border-0 text-success btn btn-primary"
                            onClick={e => {
                                // data for update
                                setGetStaffSingleData(row)
                                // update modal show
                                setAddImageModelShow(true)
                            }
                            }>
                        <RiFileUserLine/>
                    </button>
                </>
            })

        }

    ];
    return (
        <>
            <TableTwoBody
                products={allStaff}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default StaffTableSection;