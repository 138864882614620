import React, {useContext, useEffect, useRef} from 'react';
import {EventContext} from "../EventComponent";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {UpdateEventImageAction} from "../../../Redux/Action/EventAction";
import {useDispatch} from "react-redux";

function EventImageEditForm(props) {
    const dispatch = useDispatch()
    const btnRef = useRef()
    const {
        allEventReload,
        setAllEventReload,
        eventAddModelShow,
        setEventAddModelShow,
        eventEditModelShow,
        setEventEditModelShow,
        eventDeleteModelShow,
        setEventDeleteModelShow,
        eventSingleData,
        setEventSingleData,
        eventImageEditModelShow,
        setEventImageEditModelShow
    }=useContext(EventContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue,clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {

    })


    //Submit
    const onSubmit = async data => {
        const formData = new FormData();
        formData.append(eventSingleData.update_image_field_name, data.image?.length !=0?data.image[0]:"");
        formData.append("update_image_field_name", eventSingleData.update_image_field_name);
        formData.append("event_id", eventSingleData.id);

        const payload = {
            data: formData,
                ...data,
                event_id: eventSingleData.id,
                update_image_field_name: eventSingleData.update_image_field_name
            ,
            reset,
            btnRef,
            setEventImageEditModelShow,
            setAllEventReload
        }
//console.log(data)

dispatch(UpdateEventImageAction(payload))
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image"
                                {...register("image", {
                                    required: false
                                })}

                            />

                        </Form.Group>
                    </Col>
                </Row>
                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Submit
                    <ButtonLoader/>
                </Button>

            </form>



        </>
    );
}

export default EventImageEditForm;