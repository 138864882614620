import React from 'react';
import {FaProductHunt} from "@react-icons/all-files/fa/FaProductHunt";
import SideNavUtilities from "./Utilities/SideNavUtilities";
import {MdSecurity} from "@react-icons/all-files/md/MdSecurity";
import {FaPencilRuler} from "@react-icons/all-files/fa/FaPencilRuler";
import {AiOutlineSetting} from "@react-icons/all-files/ai/AiOutlineSetting";
import { FaUserAlt } from "react-icons/fa";
import Auth from "../../Utilities/Auth";
import {HiOutlineNewspaper} from "@react-icons/all-files/hi/HiOutlineNewspaper";
import {HiOutlineClipboard} from "@react-icons/all-files/hi/HiOutlineClipboard"


function SideBar(props) {
    // const permissions = Auth.permissions()
    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    {/*side nav header*/}
                    {SideNavUtilities.header()}
                    <div className="sb-sidenav-menu">
                        <div className="nav bg-transparent">
                            {/*side nav toggle button*/}
                            {SideNavUtilities.toggleBar()}

                            {/*****************menu initialize here***************/}


                            {/*Configuration*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Configuration",
                                    icon: <AiOutlineSetting/>,
                                    permissions: ["config_c", "config_r", "config_u", "config_d"]
                                },
                                [{
                                    menuName: "All Class",
                                    link: "/all-class",
                                    permissions: ["config_c", "config_r", "config_u", "config_d"]
                                }, {
                                    menuName: "Section",
                                    link: "/Section",
                                    permissions: ["config_c", "config_r", "config_u", "config_d"]
                                },
                                    {
                                        menuName: "Shift",
                                        link: "/Shift",
                                        permissions: ["config_c", "config_r", "config_u", "config_d"]
                                    },
                                    {
                                        menuName: "Service Charge",
                                        link: "/ServiceCharge",
                                        permissions: ["config_c", "config_r", "config_u", "config_d"]
                                    }
                                ])}

                            {/*Configuration*/}

                            {/*Student*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Student",
                                    icon: <FaUserAlt/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "Admission Now",
                                    link: "/Admission",
                                    permissions: ["*"]
                                }, {
                                    menuName: "Our Student",
                                    link: "/OurStudent",
                                    permissions: ["*"]

                                }
                                ])}
                                {/*stdunt-end*/}
                            {/*stdunt-end*/}
                            {/*Exam*/}
                            {SideNavUtilities.menu(
                                "Exam",
                                "/Exam",
                                ["*"],
                                <HiOutlineClipboard/>
                            )}
                            {/*Exam-end*/}
                            {/*Add Marks*/}
                            {SideNavUtilities.menu(
                                "Add Marks",
                                "/add_Marks",
                                ["*"],
                                <HiOutlineClipboard/>
                            )}
                            {/*Add Marks-end*/}
                            {/*All MarkSheet*/}
                            {SideNavUtilities.menu(
                                "All MarkSheet",
                                "/All_MarkSheet",
                                ["*"],
                                <HiOutlineClipboard/>
                            )}










                            {/*Team Area*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Team",
                                    icon: <FaUserAlt/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "Our Teacher",
                                    link: "/teacher",
                                    permissions: ["*"]
                                }, {
                                    menuName: "Our Staff",
                                    link: "/staff",
                                    permissions: ["*"]

                                }
                                ])}
                            {/*stdunt-end*/}
                            {/*NoticeBoard*/}
                            {SideNavUtilities.menu(
                                "Notice Board",
                                "/noticeBoard",
                                ["*"],
                                <HiOutlineNewspaper/>
                            )}

                            {/*Event*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Event",
                                    icon: <FaUserAlt/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "Cultural Festival",
                                    link: "/culturalEvent",
                                    permissions: ["*"]
                                }, {
                                    menuName: "Annual Festival",
                                    link: "/annualEvent",
                                    permissions: ["*"]

                                }
                                ])}
                            {/*Event End*/}


                            {/*About*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "About",
                                    icon: <FaUserAlt/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "AISJ",
                                    link: "/AISJ",
                                    permissions: ["*"]
                                }, {
                                    menuName: "Founder",
                                    link: "/Founder",
                                    permissions: ["*"]

                                },{
                                    menuName: "Director",
                                    link: "/Director",
                                    permissions: ["*"]

                                }
                                ])}
                            {/*About End*/}


                            {SideNavUtilities.menu(
                                "Utilities",
                                "/Utilities",
                                ["*"],
                                <AiOutlineSetting/>
                            )}
                            {/*UtilitiesEnd*/}

                            {/*product link*/}
                            {SideNavUtilities.menu(
                                "Link",
                                "/",
                                ["*"],
                                <FaProductHunt/>
                            )}


                            {/********************  admin  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Admin", ["*"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Admin",
                                    icon: <MdSecurity/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "Setting",
                                    link: "/setting",
                                    permissions: ["*"]
                                },
                                    {
                                        menuName: "Users",
                                        link: "/user",
                                        permissions: ["admin_c"]
                                    }
                                ])}
                            {/********************  admin end  ***********************/}

                        </div>
                    </div>
                    {/*sidebar footer*/}
                    {SideNavUtilities.footer()}
                </nav>
            </div>
        </>
    );
}

export default SideBar;
