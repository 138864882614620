import React from 'react';
import MarkSheetComponent from "../Component/MarkSheetComponent/MarkSheetComponent";

function MarkSheetPage(props) {
    return (
        <>
           <MarkSheetComponent/>
        </>
    );
}

export default MarkSheetPage;