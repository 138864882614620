import React, {useContext} from 'react';
import {Card, Table} from "react-bootstrap";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import BankInfoEditForm from "./BankInfoEditForm";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import AddressEditForm from "./AddressEditForm";

function StudentPresentAddressTable({data = {}}) {
    // making address type dynamic
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        addressEditModel,
        setAddressEditModel
    }=useContext(StudentSingleDateContext)



    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Student Address</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setAddressEditModel(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <h5>Present address information -</h5>
                        <div className="sInfo">
                            <ul>
                                <li><strong>State :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_state || "N/A"}</span></li>
                                <li><strong>District:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_district || "N/A"}</span></li>
                                <li><strong>Block:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_block || "N/A"}</span></li>
                                <li><strong>Panchayat:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_panchayat || "N/A"}</span></li>
                                <li><strong>Police station:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_police_station || "N/A"}</span></li>
                                <li><strong>Post office:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_post_office || "N/A"}</span></li>
                                <li><strong>Village :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_village || "N/A"}</span></li>
                                <li><strong>Pincode :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_pin_code || "N/A"}</span></li>
                                <li><strong>Land mark :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.present_land_mark || "N/A"}</span></li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                        <h5 className="mt-3">Permanent address information -</h5>
                        <div className="sInfo">
                            <ul>
                                <li><strong>State :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_state || "N/A"}</span></li>
                                <li><strong>District:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_district || "N/A"}</span></li>
                                <li><strong>Block:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_block || "N/A"}</span></li>
                                <li><strong>Panchayat:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_panchayat || "N/A"}</span></li>
                                <li><strong>Police station:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_police_station || "N/A"}</span></li>
                                <li><strong>Post office:</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_post_office || "N/A"}</span></li>
                                <li><strong>Village :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_village || "N/A"}</span></li>
                                <li><strong>Pincode :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_pin_code || "N/A"}</span></li>
                                <li><strong>Land mark :</strong><span>{data.stu_a_student_info?.stu_c_student_address_info?.permanent_land_mark || "N/A"}</span></li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/* Student Address Edit modal show*/}
            <CommonModal
                show={addressEditModel}
                setShow={setAddressEditModel}
                title={"Edit Address Information"}
                size="lg"
            >

                <AddressEditForm infoData={data.stu_a_student_info} />
            </CommonModal>

        </>
    );
}

export default StudentPresentAddressTable;