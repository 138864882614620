import React, {useContext} from 'react';
import TableTwoBody from "../../CommonComponent/BootstrapTable2Body/TableTwoBody";
import {StudentContext} from "../OurStudentSection";


function IsStudentBookShowcase(props) {
    const {
        singleStudentDetails,
        setSingleStudentDetails

    } = useContext(StudentContext)
    const columns = [{
        dataField: 'id',
        text: 'SL',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return {
                width: '25px',
            };
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {rowIndex + 1}
            </>
        }
    }, {
            dataField: 'subject_name',
            text: 'Subject Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return {
                    width: '200px',
                };
            }, formatter: ((cell, row) => {
                return <>
                    {cell + " (" + row.id + ")"}
                </>
            })
        }, {
            dataField: 'recommended_book',
            text: 'Recommended Book',
            sort: false,
            headerStyle: (colum, colIndex) => {
                return {

                    textAlign: "center",
                };
            },formatter:((cell, row)=>{
                return <>
                    {cell || "NA"}
                </>
        }),
            style: {textAlign: "center"}
        }

    ];
    return (
        <>
            <TableTwoBody
                products={singleStudentDetails.stu_h_student_sessional_books}
                columns={columns}
                isBorderd={true}
            />
        </>
    );
}

export default IsStudentBookShowcase;