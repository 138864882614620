import {createSlice} from "@reduxjs/toolkit";

export const AboutSlice = createSlice({
    name:"aboutSlice",
    initialState:{
        aisj:[],
        founder:[],
        director:[]

    },
    reducers:{
        getAisjReducers:(state,action)=>{
            state.aisj = action.payload
        },
        getFounderReducers:(state,action)=>{
            state.founder = action.payload
        },
        getDirectorReducers:(state,action)=>{
            state.director = action.payload
        }
    }
})