import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ApplicationUtilities from "../../../Utilities/ApplicationUtilities";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {StudentContext} from "../../OurStudentSection/OurStudentSection";
import {classWiseStudentAction, getSessionalSubjectAction} from "../../../Redux/Action/StudentAction";
import CommonToast from "../../../Utilities/CommonToast";
import {ExamContext} from "../ExamComponent";

function ExamFilter(props) {
    // hooks
    const btnRef = useRef()
    const sessionalBtnRef = useRef()
    const dispatch = useDispatch()
    const departmentRef = useRef()
    const segmentRef = useRef()


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();
    //  states

    const [allSegment, setAllSegment] = useState([])

    // getting data form redux
    let allClass = useSelector(state => state.configurationSlice.allClass)

    const {
        allExamReload,
        setAllExamReload,
        addExamModelShow,
        setAddExamModelShow,
        editExamModelShow,
        setEditExamModelShow,
        deleteExamModelShow,
        setDeleteExamModelShow,
        examSingleData,
        setExamSingleData,
        filterData,
        setFilterData,
        filteredExams, setFilteredExams
    } = useContext(ExamContext)
    // getting data from store
    const allExamList = useSelector(state => state.examSlice.allExam)


    // sessional exam filter when data comee from db
    useEffect(() => {
        sessionalExamFilter()
    }, [allExamList])

    // class on change
    const classOnChange = () => {
        const selectedClass = JSON.parse(getValues("class_id") || "{}")
        // set class wise segment
        setAllSegment(selectedClass.cnfg_class_segments || [])


    }

    // sessional exam filter
    const sessionalExamFilter = () => {
        let data = {...getValues()}
        // modifying data
        let filter = {
            ...data,
            class_id: JSON.parse(data.class_id || "{}").id
        }

        // filtering exam form table
        let filteredExamArr = []
        allExamList.map(singleExam => {
            // console.log(singleExam)
            // console.log(filter)
            if (singleExam.class_id == filter.class_id && singleExam.segment_id == filter.class_segment_id) {
                filteredExamArr.push(singleExam)
                // console.log("filtered")
            }
        })
        // set state
        setFilteredExams(filteredExamArr)
    }

    // form submit
    const onSubmit = async data => {
        sessionalExamFilter()
        // console.log(filteredExamArr)
    }
    // console.log(filterData)

    return (
        <>
            <div className="filterArea">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>

                        <Col md={5} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Class <span>*</span></Form.Label>
                                <Form.Select
                                    {...register("class_id", {
                                        required: true,
                                        onChange: event => classOnChange()
                                    })}
                                >
                                    <option value="">Select...</option>
                                    {allClass.map((singleClass, key) => (
                                        <option value={JSON.stringify(singleClass)}>{singleClass.class_name}</option>
                                    ))}
                                </Form.Select>
                                {/*validation message*/}
                                {errors.class_id && <span className="text-danger">
                                {errors.class_id.type == "required" && "Please Select A Class."}
                            </span>}
                            </Form.Group>
                        </Col>

                        <Col md={5} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Segment / Year <span>*</span></Form.Label>
                                <Form.Select
                                    id="segmentForClassWiseStudent"
                                    {...register("class_segment_id", {
                                        required: true,
                                    })}
                                >
                                    <option value="">Select...</option>
                                    {allSegment.map((singleSegment, key) => (
                                        <option value={singleSegment.id}>{singleSegment.segment_name}</option>
                                    ))}
                                </Form.Select>
                                {/*validation message*/}
                                {errors.class_segment_id && <span className="text-danger">
                                {errors.class_segment_id.type == "required" && "Please Select A Segment."}
                            </span>}
                            </Form.Group>
                        </Col>
                        <Col md={2} sm={12}>
                            <Button ref={btnRef}
                                    type="submit"
                                    className="submit btn-sm cBtn">
                                Submit
                                <ButtonLoader/>
                            </Button>
                        </Col>
                    </Row>

                </form>
            </div>
        </>
    );
}

export default ExamFilter;