import React, {useEffect} from 'react';
import LoginComponent from "../Component/LoginComponent/LoginComponent";
import Auth from "../Utilities/Auth";
import {useNavigate} from "react-router-dom";

function LoginPage(props) {
    let auth = Auth.header()
    // hook
    const navigate = useNavigate()

    useEffect(() => {
        if (auth != false) {
            navigate("/dashboard")
        }
    }, [auth])

    if (auth == false) {
        return (
            <>
                <LoginComponent/>
            </>
        );
    } else {
        return <></>
    }

}

export default LoginPage;