import React, {createContext, useEffect, useState} from 'react';
import {Button, Card, Tab, Tabs} from "react-bootstrap";
import StudentBackendFilterForm from "./OurStudentSubComponent/studentBackendFilterForm";
import {getAllClassAction} from "../../Redux/Action/ClassAction"
import {getAllShiftAction} from "../../Redux/Action/ShiftAction";
import {getAllSectionAction} from "../../Redux/Action/SectionAction";
import {getAllStudentTypeAction} from "../../Redux/Action/StudentTypeAction";
import {getAllServiceChargeAction} from "../../Redux/Action/ServiceChargeAction";
import {useDispatch} from "react-redux";
import StudentTable from "./OurStudentSubComponent/StudentTable";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import ExamForm from "../ExamComponent/examSubComponent/ExamForm";
import ClassWiseBookForm from "./OurStudentSubComponent/ClassWiseBookForm";
import {ToastContainer} from "react-toastify";
import IsStudentBookShowcase from "./OurStudentSubComponent/IsStudentBookShowcase";


export const StudentContext = createContext()
function OurStudentSection(props) {

    //State
    const [classWiseStudent, setClassWiseStudent] = useState([])
    const [singleStudentDetails, setSingleStudentDetails] = useState({})
    const [sessionalBookModel, setSessionalBookModel] = useState(false)
    const [allSecionalBook, setAllSecionalBook] = useState({})
    const [filterData, setFilterData] = useState([])
    const [isStudentBookModelShow, setIsStudentBookModelShow] = useState(false)

    const dispatch = useDispatch()
    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))

    }, [])

    return (

        <>
           <StudentContext.Provider value={{
               classWiseStudent,
               setClassWiseStudent,
               singleStudentDetails,
               setSingleStudentDetails,
               sessionalBookModel,
               setSessionalBookModel,
               allSecionalBook,
               setAllSecionalBook,
               filterData,
               setFilterData,
               isStudentBookModelShow,
               setIsStudentBookModelShow
           }}>
               <Card>
                   <Card.Body>
                       <div className="d-flex align-items-center justify-content-between">
                           <h3>Our Student</h3>
                       </div>
                       <StudentBackendFilterForm/>
                   </Card.Body>
               </Card>
               <Card className="mt-3">
                   <Card.Body>
                       <StudentTable studentList={classWiseStudent}/>
                   </Card.Body>
               </Card>

               {/* Sessional Book Model Show */}
               <CommonModal
                   show={sessionalBookModel}
                   setShow={setSessionalBookModel}
                   title={"Book Manager"}
                   size="lg"
               >
                   <ClassWiseBookForm/>
               </CommonModal>

               {/* Is Student Book Model Show */}
               <CommonModal
                   show={isStudentBookModelShow}
                   setShow={setIsStudentBookModelShow}
                   title={"My Book"}
                   size="lg"
               >
                    <IsStudentBookShowcase/>
               </CommonModal>

               {/*toast container*/}
               <ToastContainer/>
           </StudentContext.Provider>

        </>
    );
}

export default OurStudentSection;