import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap";
import HeaderComponent from "../../CommonComponent/ComponentHeader/HeaderComponent";
import ExamFilter from "./ExamFilter";
import SessionalExamFilter from "./SessionalExamFilter";
import ExamList from "./ExamList";
import SectionLoader from "../../CommonComponent/SectionLoader/SectionLoader";
import {useDispatch} from "react-redux";
import SessionalExamList from "./SessionalExamList";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import DeleteExam from "./DeleteExam";
import DeleteSessionalExam from "./DeleteSessionalExam";
import {ToastContainer} from "react-toastify";
import {StudentContext} from "../../OurStudentSection/OurStudentSection";
import {getAllExamAction} from "../../../Redux/Action/ExamAction";

export const SessionalExamContext = createContext()
function SessionalExam(props) {
    //Ref
    const allExamLoaderRef = useRef()
    const dispatch = useDispatch()
    // state
    const [allExamReload, setAllExamReload] = useState(false)
    const [filterData, setFilterData] = useState([])
    const [updateFilterData, setUpdateFilterData] = useState([])
    const [filteredExams, setFilteredExams] = useState([])
    const [sessionalExamSingleData, setSessionalExamSingleData]= useState({})
    const [deleteSessionalExamModelShow, setDeleteSessionalExamModelShow] = useState(false)

    useEffect(() => {
        const payload = {
            allExamLoaderRef
        }

        dispatch(getAllExamAction(payload))
    }, [allExamReload])

    return (
        <>
            <SessionalExamContext.Provider value={{
                allExamReload,
                setAllExamReload,
                filterData,
                setFilterData,
                filteredExams,
                setFilteredExams,
                sessionalExamSingleData,
                setSessionalExamSingleData,
                deleteSessionalExamModelShow,
                setDeleteSessionalExamModelShow,
                updateFilterData, setUpdateFilterData
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3>Sessional Exam List</h3>
                            {HeaderComponent.refresh([setAllExamReload])}
                        </div>
                        {/*Exam Filter*/}
                        <SessionalExamFilter/>
                        {/*All Exam Show*/}
                        <SessionalExamList/>
                        {/*section Loader Component*/}
                        <SectionLoader loading_handle_ref={allExamLoaderRef}/>
                    </Card.Body>

                    {/*Sessional Exam Delete Model Show*/}
                    <CommonModal
                        show={deleteSessionalExamModelShow}
                        setShow={setDeleteSessionalExamModelShow}
                        title={"Delete Sessional Exam"}
                        size="md"
                    >
                      <DeleteSessionalExam/>
                    </CommonModal>
                </Card>
                {/*toast container*/}
                <ToastContainer/>
            </SessionalExamContext.Provider>

        </>
    );
}

export default SessionalExam;