import React, {useContext, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ReactQuill from "react-quill";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {useForm} from "react-hook-form";
import {EventContext} from "../EventComponent";
import {useDispatch} from "react-redux";
import 'react-quill/dist/quill.snow.css';
import {addEventAction, UpdateEventAction} from "../../../Redux/Action/EventAction";

function EventForm({default_data = {}}) {
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()
    //Context
    const {
        allEventReload,
        setAllEventReload,
        eventAddModelShow,
        setEventAddModelShow,
        eventEditModelShow,
        setEventEditModelShow,
        eventDeleteModelShow,
        setEventDeleteModelShow,
        eventSingleData,
        setEventSingleData
    } = useContext(EventContext)
    // hook form
    const {register, handleSubmit, getValues, reset, setValue,clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, default_data[fieldProp])
            })
        }
    }, [default_data])


    const onSubmit = async data => {
        const formData = new FormData();
        if (Object.keys(default_data).length == 0) {
            formData.append('image_1', data.image_1[0] || null);
            formData.append('image_2', data.image_2[0] || null);
            formData.append('image_3', data.image_3[0] || null);
            formData.append('image_4', data.image_4[0] || null);
            formData.append('image_5', data.image_5[0] || null);
            formData.append('title', data.title);
            formData.append('short_description', data.short_description);
            formData.append('content', data.content);
            formData.append('video_link', data.video_link);
            formData.append('is_cultural_event', "0");
        }
        const payload = {
            data: Object.keys(default_data).length == 0 ? formData : {
                ...data,
                event_id: default_data.id,
            },
            reset,
            btnRef,
            setEventAddModelShow,
            setEventEditModelShow,
            setAllEventReload
        }
       Object.keys(default_data).length == 0 ? dispatch(addEventAction(payload)) : dispatch(UpdateEventAction(payload))


    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>

                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Event Title</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Event Title"
                                {...register("title", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.title && <span className="text-danger">
                        {errors.title.type === "required" && "Please Give Event Title"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Short Description</strong></Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Short Description"
                                {...register("short_description", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.short_description && <span className="text-danger">
                        {errors.short_description.type === "required" && "Please Give Short Description."}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Event Content</strong></Form.Label>

                            <ReactQuill
                                theme="snow"
                                defaultValue={default_data.content ||""}
                                style={{height:"250px"}}
                                className="clearfix"
                                {...register("content", {
                                    required: true
                                })}
                                onChange={(content, delta, source, editor)=>{
                                    // handle hook form
                                    editor.getText().length == 1 ? setError("content"):clearErrors("content")
                                    setValue("content",editor.getText().length > 1 ?content:"")

                                }}
                            />

                            {/* validation message*/}
                            {errors.notice_content && <span className="text-danger">
                        {errors.notice_content.type === "required" && "Please Give Event Content"}
                    </span>}
                        </Form.Group>

                    </Col>
                    <div style={{height:"50px"}}></div>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Youtube Link</strong></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Youtube Link"
                                {...register("video_link", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>

                </Row>
                {Object.keys(default_data).length === 0 ? <><Row>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image 1</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image 1"
                                {...register("image_1", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image 2</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image 2"
                                {...register("image_2", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image 3</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image 3"
                                {...register("image_3", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image 4</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image 4"
                                {...register("image_4", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label><strong>Image 5</strong></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Enter Image 5"
                                {...register("image_5", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>
                </Row></> : <></>}

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default EventForm;