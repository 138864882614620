import React, {useContext, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {updateAddressInfoAction, updateBankInfoAction} from "../../../Redux/Action/StudentAction";

function AddressEditForm({infoData={}}) {
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        addressEditModel,
        setAddressEditModel
    } = useContext(StudentSingleDateContext)
    //Raf
    const btnRef = useRef()
    // Hook
    const dispatch = useDispatch()

    // hook form
    const {register, handleSubmit, getValues, reset, setValue, clearErrors, setError, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(infoData.stu_c_student_address_info).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                setValue(fieldProp, infoData.stu_c_student_address_info[fieldProp])
            })
        }
    }, [infoData])


    const onSubmit = async data => {
        const payload = {
            data: {
                // for edit and update
                ...data,
                // for update
                student_info_id: infoData.stu_c_student_address_info.student_info_id,
            },
            reset,
            btnRef,
            setReloadStudentDetail,
            setAddressEditModel
        }
        dispatch(updateAddressInfoAction(payload))

    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12} sm={12}>
                        <h5>Present address information -</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>State </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_state", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>District </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_district", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Block </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_block", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Panchayat </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_panchayat", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Police Station </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_police_station", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Post Office </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_post_office", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Village </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_village", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_pin_code", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Land mark</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("present_land_mark", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} sm={12}>
                        <h5 className="mt-3">Permanent address information -</h5>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>State </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_state", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>District </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_district", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Block </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_block", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Panchayat </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_panchayat", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Police Station </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_police_station", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Post Office </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_post_office", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Village </Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_village", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_pin_code", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Land mark</Form.Label>
                            <Form.Control
                                type="text"
                                {...register("permanent_land_mark", {
                                    required: false
                                })}
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    Update
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default AddressEditForm;