class ApplicationUtilities {
    constructor(props) {
    }

    // gender array
    static allGenders() {
        return ["Male", "Female", "Other"]
    }

    // blood group array
    static bloodGroups() {
        return ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]
    }

    // religions
    static religions() {
        return ["Islam", "Buddhist", "Christian", "Hinduism", "Sikh"]
    }

    // languages
    static languages() {
        return ["Bangla", "English", "Hindi", "Tamil"]
    }

    // years
    static years() {
        let endYear = new Date().getFullYear() + 1
        let yearsArr = []
        for (let i = 2020; i <= endYear; i++) {
            yearsArr.push(i)
        }
        return yearsArr.reverse()
    }

    // session types
    static sessionTypes() {
        return [
            {id: 1, value: "New Session"},
            {id: 0, value: "Repeat Session"},
        ]
    }

}

export default ApplicationUtilities