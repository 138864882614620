import Parse from "../../../../Utilities/parse";

class FrontendPaginationHandler {
    constructor(props) {
    }

    // get data for each page
    static getDataForEachPage = (data = [], offset, items_per_page) => {
        try {
            let modified_data = [...data]
            let data_for_page = modified_data.slice(Parse.toNumber(offset), (Parse.toNumber(offset) + Parse.toNumber(items_per_page)))
            return data_for_page
        } catch (e) {
            console.log(e)
            return []
        }
    }

}

export default FrontendPaginationHandler