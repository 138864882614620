import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {getAllClassAction} from "../../Redux/Action/ClassAction";
import {Card} from "react-bootstrap";
import ClassWiseStudentBackendFilterForm
    from "../MarkSheetComponent/MarksheetSubComponent/ClassWiseStudentBackendFilterForm";
import ExamAndSubjectWiseFilter from "../MarkSheetComponent/MarksheetSubComponent/ExamAndSubjectWiseFilter";
import MarksAddTable from "../MarkSheetComponent/MarksheetSubComponent/MarksAddTable";
import {MarksheetContext} from "../MarkSheetComponent/MarkSheetComponent";
import MarkSheetTable from "./MarksheetSubComponent/MarkSheetTable";
import ExamWiseMarkSheetFilter from "./MarksheetSubComponent/ExamWiseMarkSheetFilter";
import StudentMarkSheetTable from "./MarksheetSubComponent/StudentMarkSheetTable";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import EventForm from "../EventComponent/EventSubComponent/EventForm";
import {EventContext} from "../EventComponent/EventComponent";
import PopUpMarkSheetComponent from "./MarksheetSubComponent/PopUpMarkSheetComponent";
import PdfMarkSheetReport from "../PdfMarkSheetReport/PdfMarkSheetReport";

function AllMarkSheetComponent(props) {
    //hook
    const dispatch = useDispatch()
    const studentInfoRef = useRef()

    //State
    const [classWiseStudent, setClassWiseStudent] = useState([])
    const [singleStudentDetails, setSingleStudentDetails] = useState({})
    const [examListForMarksheet, setExamListForMarksheet] = useState([])
    const [subjectListForMarksheet, setSubjectListForMarksheet] = useState([])
    const [examAndSubjectWiseStudent, setExamAndSubjectWiseStudent] = useState([])
    const [studentSubjectWiseMarks, setStudentSubjectWiseMarks] = useState([])
    const [examMarksTable, setExamMarksTable] = useState([])
    const [examAndSubjectId,setExamAndSubjectId] = useState({})
    const [classWiseSteteReloder, setClassWiseSteteReloder] = useState(false)
    const [studentMarkSheet, setStudentMarkSheet] = useState([])
    const [examId, setExamId,] = useState({})
    const [markSheetViewModel, setMarkSheetViewModel] = useState(false)
    const [singleStudentMarkSheet, setSingleStudentMarkSheet] = useState({})

    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))

    }, [])

    // after add marks action lifecycle
    useEffect(()=>{


    },[classWiseStudent])
    return (
        <>
            <MarksheetContext.Provider value={{
                classWiseStudent,
                setClassWiseStudent,
                singleStudentDetails,
                setSingleStudentDetails,
                examListForMarksheet,
                setExamListForMarksheet,
                subjectListForMarksheet,
                setSubjectListForMarksheet,
                studentSubjectWiseMarks,
                setStudentSubjectWiseMarks,
                examMarksTable,
                setExamMarksTable,
                examAndSubjectId,
                setExamAndSubjectId,
                classWiseSteteReloder,
                setClassWiseSteteReloder,
                examId,
                setExamId,
                studentMarkSheet,
                setStudentMarkSheet,
                markSheetViewModel,
                setMarkSheetViewModel,
                singleStudentMarkSheet,
                setSingleStudentMarkSheet,
                studentInfoRef
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between">
                            <h3>Class & Subject wise marks add</h3>
                        </div>
                        <ClassWiseStudentBackendFilterForm/>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        <ExamWiseMarkSheetFilter/>
                    </Card.Body>
                </Card>
                <Card className="mt-3">
                    <Card.Body>
                        {/*<MarkSheetTable table_data={examMarksTable}/>*/}
                        <StudentMarkSheetTable table_data ={studentMarkSheet} />

                    </Card.Body>
                </Card>

                {/* Mark Sheet View */}
                <CommonModal
                    show={markSheetViewModel}
                    setShow={setMarkSheetViewModel}
                    title={singleStudentMarkSheet?.stu_a_student_info?.student_full_name}
                    size="xl"
                    // singleStudentMarkSheet.stu_a_student_info.student_full_name ||"0"
                >
                   <PopUpMarkSheetComponent/>
                </CommonModal>




                {/*pdf container*/}
                <PdfMarkSheetReport print_data={singleStudentMarkSheet}/>
            </MarksheetContext.Provider>


        </>
    );
}

export default AllMarkSheetComponent;