import React, {useContext} from 'react';
import {Card, Table} from "react-bootstrap";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import StudentGuardianInformationEditForm from "./StudentGuardianInformationEditForm";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import PreviousSchoolInformationEditForm from "./PreviousSchoolInformationEditForm";

function PreviousSchoolInformationTable({data = {}}) {
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        editModelShow,
        setEditModelShow,
        birthCertificateEditModel,
        setBirthCertificateEditModel,
        aadhaarCardEditModel,
        setAadhaarCardEditModel,
        guardianInformationEditModel,
        setGuardianInformationEditModel,
        previousSchoolInformationEditModel,
        setPreviousSchoolInformationEditModel
    }=useContext(StudentSingleDateContext)

    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Previous school information -</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setPreviousSchoolInformationEditModel(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <ul>
                                <li><strong>School Name :</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.prev_school_name || "N/A"}</span></li>
                                <li><strong>Medium:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.medium || "N/A"}</span></li>
                                <li><strong>Academic year:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.academic_year || "N/A"}</span></li>
                                <li><strong>Class:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_class || "N/A"}</span></li>
                                <li><strong>Department:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_department || "N/A"}</span></li>
                                <li><strong>Segment:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_class_segment || "N/A"}</span></li>
                                <li><strong>Section:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_section || "N/A"}</span></li>
                                <li><strong>Roll:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_roll || "N/A"}</span></li>
                                <li><strong>Registration:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_reg || "N/A"}</span></li>
                                <li><strong>Board:</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_board || "N/A"}</span></li>
                                <li><strong>Grade point</strong><span>{data.stu_a_student_info?.stu_d_student_previous_school_info?.passed_grade_point || "N/A"}</span></li>

                            </ul>

                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/*Previous School Information Edit modal show*/}
            <CommonModal
                show={previousSchoolInformationEditModel}
                setShow={setPreviousSchoolInformationEditModel}
                title={"Edit Previous School Information"}
                size="lg"
            >
                <PreviousSchoolInformationEditForm infoData={data.stu_a_student_info}/>
            </CommonModal>

        </>
    );
}

export default PreviousSchoolInformationTable;