import {createSlice} from "@reduxjs/toolkit";

export const EventSlice = createSlice({
    name:"eventSlice",
    initialState:{
        allEvent:[]
    },
    reducers:{
        getAllEventReducers:(state,action)=>{
            state.allEvent = action.payload
        }
    }
})