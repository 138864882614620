import React, {createContext, useEffect, useRef, useState} from 'react';
import {Card} from "react-bootstrap";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../CommonComponent/CommonModal/CommonModal";
import TeacherForm from "../TeacherComponent/teacherSubComponent/TeacherForm";

import {useDispatch} from "react-redux";

import {getAllStaffAction} from "../../Redux/Action/StaffActon";
import StaffTableSection from "./staffSubComponent/staffTableSection";
import StaffForm from "./staffSubComponent/StaffForm";
import ImageForm from "../TeacherComponent/teacherSubComponent/ImageForm";
import StaffImageForm from "./staffSubComponent/StaffImageForm";

export const StaffContext = createContext()

function StaffComponent(props) {
    //Ref
    const allStaffTableLoaderRef = useRef()
    const dispatch = useDispatch()

    //state
    const [allStaffReload, setAllStaffReload] =useState(false)
    const [staffAddModelShow, setStaffAddModelShow] =useState(false)
    const [staffEditModelShow, setStaffEditModelShow] =useState(false)
    const [getStaffSingleData, setGetStaffSingleData] =useState({})
    const [addImageModelShow, setAddImageModelShow] =useState(false)

    // get all  agent lifecycle
    useEffect(() => {
        const Payload = {
            allStaffTableLoaderRef,
        }
        dispatch(getAllStaffAction(Payload))
    }, [allStaffReload])


    return (
        <>
            <StaffContext.Provider value={{
                allStaffReload,
                setAllStaffReload,
                staffAddModelShow,
                setStaffAddModelShow,
                staffEditModelShow,
                setStaffEditModelShow,
                getStaffSingleData,
                setGetStaffSingleData,
                addImageModelShow,
                setAddImageModelShow

            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3>All Staff</h3>
                            {HeaderComponent.refresh([setAllStaffReload])}
                            {HeaderComponent.add(setStaffAddModelShow) }
                        </div>

                        {/*Teacher Table*/}
                        <StaffTableSection/>

                        {/*section Loader Component*/}
                        <SectionLoader loading_handle_ref={allStaffTableLoaderRef}/>

                        {/*Teacher Add Model Show*/}
                        <CommonModal
                            show={staffAddModelShow}
                            setShow={setStaffAddModelShow}
                            title={"Add Teacher"}
                            size="md"
                        >
                            <StaffForm/>
                        </CommonModal>

                        {/*Teacher Edit Model Show*/}
                        <CommonModal
                            show={staffEditModelShow}
                            setShow={setStaffEditModelShow}
                            title={"Edit Teacher"}
                            size="md"
                        >
                            <StaffForm formType={'Update'} default_data={getStaffSingleData}/>
                        </CommonModal>

                        {/*Teacher image Model*/}
                        <CommonModal
                            show={addImageModelShow}
                            setShow={setAddImageModelShow}
                            title={"Staff Image"}
                            size="md"
                        >
                            <StaffImageForm default_data={getStaffSingleData}/>
                        </CommonModal>

                    </Card.Body>
                </Card>

            </StaffContext.Provider>
        </>
    );
}

export default StaffComponent;