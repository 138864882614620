import CommonLoader from "../../Utilities/CommonLoader";
import CommonAxios from "../../Utilities/CommonAxios";
import {addStaffAvatar, addTeacher, getAllTeacher, updateTeacher} from "../../AllRoutes/ApiRoute";
import {TeacherSlice} from "../Slice/TeacherSlice";
import CommonToast from "../../Utilities/CommonToast";


// get Teacher Action
export const getAllTeacherAction = payload => dispatch =>{
    // getting data from payload
    const {allTeacherTableLoaderRef} = payload
    // loader handle method
    let loaderHandle = () => {
        try {
            //loader handle
            CommonLoader.section(allTeacherTableLoaderRef)
        } catch (e) {

        }
    }

    // invoke loader handle method
    loaderHandle()
    CommonAxios.get(getAllTeacher).then(res => {
        // invoke loader handle method
        loaderHandle()
        if (res.data.status === true) {
            dispatch(TeacherSlice.actions.getAllTeacherReducers(res.data.data || []))
        }
    }).catch(err => {
        // invoke loader handle method
        loaderHandle()
    })
}

// Add Teacher Action
export const addTeacherAction = payload => dispatch =>{
    const {
        btnRef,
        setTeacherAddModelShow,
        setAllTeacherReload

    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addTeacher, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setTeacherAddModelShow(false)
            //all Teacher table reload
            setAllTeacherReload(prev => !prev)
            // toast
            CommonToast.success("Teacher Create Successfully!")
        } else {
            CommonToast.error("Teacher Create failed!")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}



// Update Teacher Action
export const UpdateTeacherAction = payload => dispatch =>{
    const {
        btnRef,
        setTeacherEditModelShow,
        setAllTeacherReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateTeacher, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setTeacherEditModelShow(false)
            //all Agent table reload
            setAllTeacherReload(prev => !prev)
            // toast
            CommonToast.success("Teacher updated Successfully!")
        } else {
            CommonToast.warning("Teacher updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// Add Teacher image Action

export const addTeacherImageAction = payload => dispatch =>{
    const {
        btnRef,
        setAddImageModelShow,
        setAllTeacherReload,
        setAllStaffReload,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(addStaffAvatar, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAddImageModelShow(false)
            //all Teacher table reload
            setAllTeacherReload(prev => !prev)
            //
            setAllStaffReload(prev => !prev)
            // toast
            CommonToast.success("Teacher updated Successfully!")
        } else {
            CommonToast.warning("Teacher updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}