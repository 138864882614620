import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {StudentContext} from "../OurStudentSection/OurStudentSection";
import {Card} from "react-bootstrap";
import {useDispatch} from "react-redux";
import HeaderComponent from "../CommonComponent/ComponentHeader/HeaderComponent";
import SectionLoader from "../CommonComponent/SectionLoader/SectionLoader";
import CommonLoader from "../../Utilities/CommonLoader";
import {useParams} from "react-router-dom";
import {getSingleStudentDetailAction} from "../../Redux/Action/StudentAction";
import StudentBasicInformationTable from "./Utilities/StudentBasicInformationTable";
import './StudentDetailStyle.css'
import GuardianInformationTable from "./Utilities/GuardianInformationTable";
import StudentPresentAddressTable from "./Utilities/StudentPresentAddressTable";
import StudentPermanentAddressInformation from "./Utilities/StudentPermanentAddressInformation";
import PreviousSchoolInformationTable from "./Utilities/PreviousSchoolInformationTable";
import SessionalInformationTable from "./Utilities/SessionalInformationTable";
import GovRegistrationInformationTable from "./Utilities/GovRegistrationInformationTable";
import StudentBankInformationTable from "./Utilities/StudentBankInformationTable";
import {ToastContainer} from "react-toastify";

export const StudentSingleDateContext = createContext()

function StudentDetailsComponent(props) {
    // hooks
    const dispatch = useDispatch()
    const sectionLoaderRef = useRef()
    // states
    const [reloadStudentDetail, setReloadStudentDetail] = useState(false)
    const [singleStudentDetail, setSingleStudentDetail] = useState({})
    const [editModelShow, setEditModelShow] = useState(false)
    const [birthCertificateEditModel, setBirthCertificateEditModel] = useState(false)
    const [aadhaarCardEditModel, setAadhaarCardEditModel] = useState(false)
    const [guardianInformationEditModel, setGuardianInformationEditModel] = useState(false)
    const [previousSchoolInformationEditModel, setPreviousSchoolInformationEditModel] = useState(false)
    const [bankInfoEditModel, setBankInfoEditModel] = useState(false)
    const [govRegInfoEditModel, setGovRegInfoEditModel] = useState(false)
    const [addressEditModel, setAddressEditModel] = useState(false)
    const [sessionalInfoEditModel, setSessionalInfoEditModel] = useState(false)
    const [studentImageEditModel, setStudentImageEditModel] = useState(false)

    // getting parameter form route
    const {sessional_info_id} = useParams()


    // get single student detail lifecycle
    useEffect(() => {
        // making payload for getting single student detail
        const payload = {
            data: {sessional_info_id: sessional_info_id},
            setSingleStudentDetail,
            sectionLoaderRef
        }
        // action dispatch
        dispatch(getSingleStudentDetailAction(payload))
    }, [reloadStudentDetail])

    return (
        <>
            <StudentSingleDateContext.Provider value={{
                reloadStudentDetail,
                setReloadStudentDetail,
                singleStudentDetail,
                setSingleStudentDetail,
                editModelShow,
                setEditModelShow,
                birthCertificateEditModel,
                setBirthCertificateEditModel,
                aadhaarCardEditModel,
                setAadhaarCardEditModel,
                guardianInformationEditModel,
                setGuardianInformationEditModel,
                previousSchoolInformationEditModel,
                setPreviousSchoolInformationEditModel,
                bankInfoEditModel,
                setBankInfoEditModel,
                govRegInfoEditModel,
                setGovRegInfoEditModel,
                addressEditModel,
                setAddressEditModel,
                sessionalInfoEditModel,
                setSessionalInfoEditModel,
                studentImageEditModel,
                setStudentImageEditModel
            }}>
               <div className="align-items-center d-flex">
                   <div className="">
                       <h3>Student Detail -</h3>
                   </div>
                   <div className="flex-shrink-0">
                       {HeaderComponent.refresh([setReloadStudentDetail])}
                   </div>
               </div>


                        {/*student basic info table*/}
                        <StudentBasicInformationTable data={singleStudentDetail}/>
                        {/*sessional information*/}
                        <SessionalInformationTable data={singleStudentDetail}/>
                        {/*gov reg info*/}
                        <GovRegistrationInformationTable data={singleStudentDetail}/>
                        {/*guardian info*/}
                        <GuardianInformationTable data={singleStudentDetail}/>
                        {/*bank detail*/}
                        <StudentBankInformationTable data={singleStudentDetail}/>
                        {/*present address info*/}
                        <StudentPresentAddressTable data={singleStudentDetail}/>
                        {/*previous school information*/}
                        <PreviousSchoolInformationTable data={singleStudentDetail}/>


                        {/*section loader*/}
                        <SectionLoader loading_handle_ref={sectionLoaderRef}/>

                {/*toast container*/}
                <ToastContainer/>
            </StudentSingleDateContext.Provider>


        </>
    );
}

export default StudentDetailsComponent;