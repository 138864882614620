import React from 'react';
import Table from 'react-bootstrap/Table';

function CommonTableBody({children}) {
    return (
        <>
            <Table table-bordered responsive striped className="mb-0 table-bordered">
                {children}
            </Table>
        </>
    );
}

export default CommonTableBody;