import React, {useContext, useEffect, useRef, useState} from 'react';
import {Card, Tab, Tabs} from "react-bootstrap";
import HeaderComponent from "../../CommonComponent/ComponentHeader/HeaderComponent";
import ExamFilter from "./ExamFilter";
import ExamList from "./ExamList";
import SectionLoader from "../../CommonComponent/SectionLoader/SectionLoader";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import ExamForm from "./ExamForm";
import DeleteExam from "./DeleteExam";
import SessionalExamAddForm from "./SessionalExamAddForm";
import {ExamContext} from "../ExamComponent";
import {useDispatch} from "react-redux";
import {getAllExamAction} from "../../../Redux/Action/ExamAction";
import {getAllClassAction} from "../../../Redux/Action/ClassAction";
import {ToastContainer} from "react-toastify";
import {StudentContext} from "../../OurStudentSection/OurStudentSection";

function AllExam(props) {
    //Ref
    const allExamLoaderRef = useRef()
    const dispatch = useDispatch()

    //State
    const [allExamReload, setAllExamReload] = useState(false)
    const [addExamModelShow, setAddExamModelShow] = useState(false)
    const [editExamModelShow, setEditExamModelShow] = useState(false)
    const [deleteExamModelShow, setDeleteExamModelShow] = useState(false)
    const [examSingleData, setExamSingleData] = useState({})
    const [filterData, setFilterData] = useState({})
    const [filteredExams, setFilteredExams] = useState([])
    const [sessionalExamModelShow, setSessionalExamModelShow] = useState(false)



    useEffect(() => {
        const payload = {
            allExamLoaderRef
        }
        dispatch(getAllExamAction(payload))
    }, [allExamReload])

    // get necessary data lifecycle
    useEffect(() => {
        // get all class
        dispatch(getAllClassAction({}))

    }, [])

    return (
        <>
            <ExamContext.Provider value={{
                filteredExams,
                setFilteredExams,
                allExamReload,
                setAllExamReload,
                addExamModelShow,
                setAddExamModelShow,
                editExamModelShow,
                setEditExamModelShow,
                deleteExamModelShow,
                setDeleteExamModelShow,
                examSingleData,
                setExamSingleData,
                filterData,
                setFilterData,
                sessionalExamModelShow,
                setSessionalExamModelShow
            }}>
                <Card>
                    <Card.Body>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <h3>Exam List</h3>
                            {HeaderComponent.refresh([setAllExamReload])}
                            {HeaderComponent.add(setAddExamModelShow)}
                        </div>
                        {/*Exam Filter*/}
                        <ExamFilter/>
                        {/*All Exam Show*/}
                        <ExamList/>
                        {/*section Loader Component*/}
                        <SectionLoader loading_handle_ref={allExamLoaderRef}/>
                        {/* Exam Add Model Show */}
                        <CommonModal
                            show={addExamModelShow}
                            setShow={setAddExamModelShow}
                            title={"Add Exam"}
                            size="md"
                        >
                            <ExamForm/>
                        </CommonModal>

                        {/* Exam Edit Model Show */}
                        <CommonModal
                            show={editExamModelShow}
                            setShow={setEditExamModelShow}
                            title={"Edit Exam"}
                            size="md"
                        >
                            <ExamForm formType={'Update'} default_data={examSingleData}/>

                        </CommonModal>

                        {/*Notice Delete Model Show*/}
                        <CommonModal
                            show={deleteExamModelShow}
                            setShow={setDeleteExamModelShow}
                            title={"Delete Exam"}
                            size="md"
                        >
                            <DeleteExam/>
                        </CommonModal>
                        {/* SessionalExam Exam Model Show */}
                        <CommonModal
                            show={sessionalExamModelShow}
                            setShow={setSessionalExamModelShow}
                            title={"Sessional Exam"}
                            size="md"
                        >
                            <SessionalExamAddForm/>
                        </CommonModal>
                    </Card.Body>
                </Card>
                {/*toast container*/}
                <ToastContainer/>
            </ExamContext.Provider>


        </>
    );
}

export default AllExam;