import React, {Suspense} from 'react';

const StudentAdmissionComponent = React.lazy(() => import("../Component/StudentAdmissionComponent/StudentAdmissionComponent"))

function StudentAdmissionPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <StudentAdmissionComponent/>
            </Suspense>
        </>
    );
}

export default StudentAdmissionPage;