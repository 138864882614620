import React, {useContext} from 'react';
import {Card, Table} from "react-bootstrap";
import DateFormat from "../../../Utilities/DateFormat";
import {FiEdit} from "@react-icons/all-files/fi/FiEdit";
import CommonModal from "../../CommonComponent/CommonModal/CommonModal";
import BankInfoEditForm from "./BankInfoEditForm";
import {StudentSingleDateContext} from "../StudentDetailsComponent";
import GovRegInfoEditForm from "./GovRegInfoEditForm";

function GovRegistrationInformationTable({data = {}}) {
    //context
    const {
        reloadStudentDetail,
        setReloadStudentDetail,
        singleStudentDetail,
        setSingleStudentDetail,
        govRegInfoEditModel,
        setGovRegInfoEditModel
    }=useContext(StudentSingleDateContext)
    return (
        <>
            <div className="sd">
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h5 className="mb-0 flex-grow-1">Gov. registration information -</h5>
                        <div className="flex-shrink-0">
                            <button title="Edit"
                                    className="bg-transparent iconActionButton border-0 text-success btn btn-primary"
                                    onClick={e => {
                                        // update modal show
                                        setGovRegInfoEditModel(true)
                                    }
                                    }>
                                <FiEdit/>
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="sInfo">
                            <ul>
                                <li><strong>School Name :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.school_name || "N/A"}</span></li>
                                <li><strong>Class :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.class_name || "N/A"}</span></li>
                                <li><strong>Department :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.department_name || "N/A"}</span></li>
                                <li><strong>Segment :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.segment_name || "N/A"}</span></li>
                                <li><strong>Shift :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.shift_name || "N/A"}</span></li>
                                <li><strong>Section :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.section_name || "N/A"}</span></li>
                                <li><strong>Student Id (Reg. school) :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.student_id_from_reg_school || "N/A"}</span></li>
                                <li><strong>Kannyashree Id :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.kannyashree_id || "N/A"}</span></li>
                                <li><strong>Roll :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.roll_no || "N/A"}</span></li>
                                <li><strong>Registration no. :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.reg_no || "N/A"}</span></li>
                                <li><strong>Board :</strong><span>{data.stu_f_student_sessional_gov_reg_info?.board || "N/A"}</span></li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {/* Gov. registration information Edit modal show*/}
            <CommonModal
                show={govRegInfoEditModel}
                setShow={setGovRegInfoEditModel}
                title={"Edit Gov. registration information"}
                size="lg"
            >

                <GovRegInfoEditForm infoData={data.stu_f_student_sessional_gov_reg_info}/>
            </CommonModal>
        </>
    );
}

export default GovRegistrationInformationTable;