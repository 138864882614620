import CommonAxios from "../../Utilities/CommonAxios";
import CommonLoader from "../../Utilities/CommonLoader";
import {
    getClassWiseStudents,
    getSingleStudentDetail, getSubjects,
    studentAdmit,
    updateAdhaarCardImage,
    updateBirthRegImage,
    updateEvent,
    updateEventImage,
    updatePreviousSchoolInformation,
    updateSessionalGovRegInfo, updateSessionalInfo, updateSessionalInfoImage, updateStudentAddress,
    updateStudentBankInfo,
    updateStudentGuardianInfo,
    updateStudentInfo
} from "../../AllRoutes/ApiRoute";
import CommonToast from "../../Utilities/CommonToast";
import {useSelector} from "react-redux";

// student admit action
export const studentAdmitAction = payload => dispatch => {
    // getting data form payload
    const {btnRef, setResetComponent} = payload
    // handle btn loader
    CommonLoader.btn(btnRef)
    // db operation
    CommonAxios.post(studentAdmit, payload).then(res => {
        // handle btn loader
        CommonLoader.btn(btnRef)
        if (res.data.status == true) {
            // reset component
            setResetComponent(prev => !prev)
            // toast message
            CommonToast.success("Student admitted successfully.")
        } else {
            // toast
            CommonToast.error("Student admit failed.")
        }
    }).catch(err => {
        // handle btn loader
        CommonLoader.btn(btnRef)
    })
}


// Get Class Wise Student Action
export const classWiseStudentAction = payload => dispatch => {
    const {
        data,
        reset,
        btnRef,
        setClassWiseStudent
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    CommonAxios.post(getClassWiseStudents, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            //Student data set
            setClassWiseStudent(res.data.data)
        } else {

        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })

}

// get single student detail action
export const getSingleStudentDetailAction = payload => dispatch => {
    // getting data from payload
    const {sectionLoaderRef, setSingleStudentDetail} = payload
    // handle section loader
    CommonLoader.section(sectionLoaderRef)
    // db operation
    CommonAxios.silentPost(getSingleStudentDetail, payload).then(res => {
        // handle section loader
        CommonLoader.section(sectionLoaderRef)
        if (res.data.status == true) {
            setSingleStudentDetail(res.data?.data || {})
        } else {
            setSingleStudentDetail({})
        }
    }).catch(err => {
        // handle section loader
        CommonLoader.section(sectionLoaderRef)
    })
}


// get single student detail action
export const updateStudentInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        btnRef,
        setReloadStudentDetail,
        setEditModelShow,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateStudentInfo, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setEditModelShow(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Info updated Successfully!")
        } else {
            CommonToast.warning("Student Info updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}
// get sessional subject action
export const getSessionalSubjectAction = payload => dispatch => {
    // getting data form payload
    const {setSessionalBookModel, sessionalBtnRef, setAllSecionalBook} = payload

    // db operation
    CommonAxios.silentPost(getSubjects, payload).then(res => {
        // handle section loader

        if (res.data.status == true) {
            setAllSecionalBook(res.data.data)
            setSessionalBookModel(true)

        } else {
            setAllSecionalBook([])
        }
    }).catch(err => {
        // handle section loader

    })
}










// Update Student Image
export const updateSessionalInfoImageAction = payload => dispatch => {
    const {
        btnRef,
        setStudentImageEditModel,
        setReloadStudentDetail,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateSessionalInfoImage, payload).then(res => {
        /// handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setStudentImageEditModel(false)

            //all Agent table reload
            setReloadStudentDetail(prev => !prev)

            // toast
            CommonToast.success("Update Student Image Successfully!")
        } else {
            CommonToast.warning("Update Student Image failed!")
        }

    }).catch(err => {

        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// Update Birth Certificate Image
export const updateBirthCertificateAction = payload => dispatch => {
    const {
        btnRef,
        setBirthCertificateEditModel,
        setReloadStudentDetail,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateBirthRegImage, payload).then(res => {
        /// handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setBirthCertificateEditModel(false)

            //all Agent table reload
            setReloadStudentDetail(prev => !prev)

            // toast
            CommonToast.success("Update Birth Certificate Successfully!")
        } else {
            CommonToast.warning("Update Birth Certificate failed!")
        }

    }).catch(err => {

        // handle button loader
        CommonLoader.btn(btnRef)
    })
}


// Update Adhaar Card Image
export const updateAdhaarCardImageAction = payload => dispatch => {
    const {
        btnRef,
        setAadhaarCardEditModel,
        setReloadStudentDetail,
        reset
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)


    CommonAxios.post(updateAdhaarCardImage, payload).then(res => {
        /// handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAadhaarCardEditModel(false)

            //all Agent table reload
            setReloadStudentDetail(prev => !prev)

            // toast
            CommonToast.success("Update Birth Certificate Successfully!")
        } else {
            CommonToast.warning("Update Birth Certificate failed!")
        }

    }).catch(err => {

        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// Update Student Guardian Information
export const updateStudentGuardianInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setGuardianInformationEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateStudentGuardianInfo, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setGuardianInformationEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Guardian Info updated Successfully!")
        } else {
            CommonToast.warning("Student Guardian Info updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}



// Update Previous School Information
export const updatePreviousSchoolInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setPreviousSchoolInformationEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updatePreviousSchoolInformation, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setPreviousSchoolInformationEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Previous School Info updated Successfully!")
        } else {
            CommonToast.warning("Student Previous School Info updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}
// Update Sessional  Information
export const updateSessionalInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setSessionalInfoEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateSessionalInfo, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setSessionalInfoEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Sessional Info updated Successfully!")
        } else {

            CommonToast.warning("Student Sessional Info updated failed! ")
        }

    }).catch(err => {
        console.log(err)
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// Update Bank Information
export const updateBankInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setBankInfoEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateStudentBankInfo, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setBankInfoEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Bank Info updated Successfully!")
        } else {
            CommonToast.warning("Student Bank Info updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// Update Gov. registration information
export const updateGovInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setGovRegInfoEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateSessionalGovRegInfo, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setGovRegInfoEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Gov Info updated Successfully!")
        } else {
            CommonToast.warning("Student Gov Info updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}

// Update Address information
export const updateAddressInfoAction = payload => dispatch => {
    // getting data from payload
    const {
        reset,
        btnRef,
        setReloadStudentDetail,
        setAddressEditModel
    } = payload

    // handle button loader
    CommonLoader.btn(btnRef)

    // db operation
    CommonAxios.post(updateStudentAddress, payload).then(res => {
        // handle button loader
        CommonLoader.btn(btnRef)

        if (res.data.status == true) {
            // modal close
            setAddressEditModel(false)
            //all Agent table reload
            setReloadStudentDetail(prev => !prev)
            // toast
            CommonToast.success("Student Address updated Successfully!")
        } else {
            CommonToast.warning("Student Address updated failed! ")
        }

    }).catch(err => {
        // handle button loader
        CommonLoader.btn(btnRef)
    })
}