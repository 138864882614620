import React, {useContext, useEffect, useRef} from 'react';
import {Button} from "react-bootstrap";
import {NoticeContext} from "../NoticeComponent";
import {useDispatch} from "react-redux";
import {DeleteNoticeAction} from "../../../Redux/Action/NoticeAction";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {TiTick} from "@react-icons/all-files/ti/TiTick";
import {TiDelete} from "@react-icons/all-files/ti/TiDelete";


function DeleteNotice(props) {
    const btnRef = useRef()
    const dispatch = useDispatch()
    //Context
    const {
        allNoticeReload,
        setAllNoticeReload,
        noticeAddModelShow,
        setNoticeAddModelShow,
        noticeEditModelShow,
        setNoticeEditModelShow,
        noticeSingleData,
        setNoticeSingleData,
        noticeDeleteModelShow,
        setNoticeDeleteModelShow
    } = useContext(NoticeContext)

   const deleteClick = () =>{
      const noticeId = noticeSingleData.id

       const Payload ={
           data:{
               notice_id :noticeId
           },
           btnRef,
           setNoticeDeleteModelShow,
           setAllNoticeReload

       }
       dispatch(DeleteNoticeAction(Payload))


    }


    return (
        <>
            <div className="text-center">
                <h3 className="mt-3 mb-3">Are You Sure Delete This Notice</h3>
                <Button className="mx-2" ref={btnRef} variant="success" onClick={deleteClick}>YES <TiTick/>
                    <ButtonLoader/>
                </Button>
                <Button
                    variant="danger"
                    onClick={e =>{
                        setNoticeDeleteModelShow(false)
                    }}>NO <TiDelete/></Button>
            </div>
        </>
    );
}

export default DeleteNotice;