import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import logo from '../../assets/images/logo.png'
import LoginForm from "./SubComponent/LoginForm";
import LoginImg from "../../assets/images/login-img.jpg";
import {ToastContainer} from "react-toastify";

function LoginComponent(props) {
    return (
        <>
            <div className="loginContainer  mx-md-0">
                <Container>
                    <Row>
                        <Col>
                            <img className="loginImg" src={LoginImg}/>
                        </Col>
                        <Col>
                            <div className="formArea">
                                <img className="loginLogo" src={logo}/>
                                <LoginForm/>
                            </div>
                        </Col>
                    </Row>

                </Container>


                {/*toast container*/}
                <ToastContainer/>
            </div>
        </>
    );
}

export default LoginComponent;