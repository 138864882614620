import React from 'react';
import SegmentConfigureForm from "./SegmentConfigureForm";

function ClassSegmentSubComponent({class_segments = []}) {
    return (
        <>
            {class_segments.map((singleClassSegment, key) => (
                <SegmentConfigureForm exist_data={singleClassSegment}/>
            ))}
        </>
    );
}

export default ClassSegmentSubComponent;