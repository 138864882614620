import React, {useEffect} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import DirectorForm from "./DirectorSubComponent/DirectorForm";
import {useDispatch} from "react-redux";
import {getDirectorAboutAction} from "../../Redux/Action/AboutAction";

function DirectorComponent(props) {
    const dispatch = useDispatch()
    // get all Notice lifecycle
    useEffect(() => {
        dispatch(getDirectorAboutAction())
    }, [])
    return (
        <>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <h3>Director</h3>
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            <Card>
                                <Card.Body>
                                    <DirectorForm/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default DirectorComponent;