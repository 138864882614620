import React from 'react';
import DepartmentConfigureForm from "./DepartmentConfigureForm";

function DepartmentSubComponent({class_wise_departments = []}) {
    return (
        <>
            {class_wise_departments.map((singleDepartments, key) => (
                <DepartmentConfigureForm exist_data={singleDepartments}/>
            ))}
        </>
    );
}

export default DepartmentSubComponent;