import React, {useContext, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import ButtonLoader from "../../CommonComponent/ButtonLoader/ButtonLoader";
import {StaffContext} from "../StaffComponent";
import {addStaffAction, UpdateStaffAction} from "../../../Redux/Action/StaffActon";

function StaffForm({default_data = {}}) {
    // ref
    const btnRef = useRef()

    // Hook
    const dispatch = useDispatch()

    //Context
    const {
        allStaffReload,
        setAllStaffReload,
        staffAddModelShow,
        setStaffAddModelShow,
        staffEditModelShow,
        setStaffEditModelShow,
        getStaffSingleData,
        setGetStaffSingleData,
        addImageModelShow,
        setAddImageModelShow
    } = useContext(StaffContext)


    // hook form
    const {register, handleSubmit, getValues, reset, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if (Object.keys(default_data).length != 0) {
            Object.keys(getValues()).map(fieldProp => {
                fieldProp != "teacher_name" ? setValue(fieldProp, default_data[fieldProp]) : setValue("teacher_name", default_data["staff_name"])
            })
        }

    }, [default_data])

    const onSubmit = async data => {
        const formData = new FormData();
        if (Object.keys(default_data).length == 0) {
            formData.append('staff_avatar', data.staff_avatar[0] || null);
            formData.append('staff_name', data.staff_name);
            formData.append('designation', data.designation);
            formData.append('education', data.education);
            formData.append('staff_id', data.staff_id);
        }


        const payload = {
            data: Object.keys(default_data).length == 0 ? formData : {
                ...data,
                staff_id: default_data.id
            },
            reset,
            btnRef,
            setStaffAddModelShow,
            setStaffEditModelShow,
            setAllStaffReload

        }
// action dispatch by checking condition. ex: add and update
         Object.keys(default_data).length == 0 ? dispatch(addStaffAction(payload)) : dispatch(UpdateStaffAction(payload))
          console.log(data)
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/*Agent full_name field*/}
                <Row>
                    {Object.keys(default_data).length === 0 && <>
                        <Col md={12} sm={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Teacher Image </Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    {...register("staff_avatar", {
                                        required: false
                                    })}
                                />
                            </Form.Group>
                        </Col>
                    </>}


                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Section Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Staff Name"
                                {...register("staff_name", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.staff_name && <span className="text-danger">
                        {errors.staff_name.type === "required" && "Please Give Staff Name"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Designation</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Designation"
                                {...register("designation", {
                                    required: true
                                })}
                            />

                            {/* validation message*/}
                            {errors.designation && <span className="text-danger">
                        {errors.designation.type === "required" && "Please Give Designation"}
                    </span>}
                        </Form.Group>
                    </Col>
                    <Col md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Education Qualification</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Education"
                                {...register("education", {
                                    required: false
                                })}
                            />

                        </Form.Group>
                    </Col>

                </Row>

                <Button ref={btnRef} variant="primary" type="submit" className="submit">
                    {Object.keys(default_data).length === 0 ? "Submit" : "Update"}
                    <ButtonLoader/>
                </Button>

            </form>
        </>
    );
}

export default StaffForm;